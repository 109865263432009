import http from '../http'

export function get(series_id, id){
  if(id){
    return http.get(`/v1/journeys/series/${series_id}/assignments/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/series/${series_id}/assignments`, { withCredentials: true })
  }
}

export function del(series_id, id){
    return http.delete(`/v1/journeys/series/${series_id}/assignments/${id}`, { withCredentials: true })
}

export function save(series_id, assignment) {
  if(assignment.assignment_id){
    return http.put(`/v1/journeys/series/${series_id}/assignments/${assignment.assignment_id}`, {assignment: assignment}, { withCredentials: true })
  }else{
    return http.post(`/v1/journeys/series/${series_id}/assignments`, {assignment: assignment}, { withCredentials: true })
  }
}
