<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Member</span></v-card-title>
      <v-card-text>
        <MemberForm v-model="memberData" :fetchMembers="fetchMembers" :organization_roles="organization_roles" ref="memberForm" @add-role="add_role"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MemberForm from '@/components/teams/MemberForm'
export default {
  name: 'MemberDialog',
  components: {
    MemberForm
  },
  props: ['value', 'member', 'fetchMembers', 'organization_roles', 'saving'],
  data: function(){
    return {
      show: this.value,
      memberData: (this.member && {...this.member}) || {}
    }
  },
  methods: {
    close () {
      this.$refs.memberForm.reset()
      this.$emit('close')
      this.reset()
    },
    save() {
      let valid = this.$refs.memberForm.validate()

      if (valid) {
        this.$emit('save', this.memberData)
        this.$refs.memberForm.reset()
        this.reset()
      }
    },
    reset() {
      this.memberData = (this.member && {...this.member}) || {}
    },
    add_role: function(value){
      this.$emit("add-role", value)
    }
  },
  watch: {
    member: function(val, prev){
      if(val != prev){
        this.memberData = val || {}
      }
    },
    value: function(val, prev) {
      if(val != prev){
        this.show = val
      }
    }
  }
}
</script>

<style lang="scss">
</style>
