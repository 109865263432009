import Vue from 'vue';
// STATE

const state = {
  show: false,
  message: '',
  title: ''
}

// GETTERS

const getters = {
  show: state => state.show,
  message: state => state.message,
  title: state => state.title
}

// ACTIONS

const actions = {
  notify({commit}, message){
    commit('setMessage', message)
    commit('showMessage', true)
  },
  setTitle({commit}, title){
    commit('setTitle', title)
  }
}

// MUTATIONS

/* eslint no-param-reassign:
["error", { "props": false}] */

const mutations = {
  setMessage(s, message) {
    Vue.set(s, 'message', message)
  },
  setTitle(s, title) {
    Vue.set(s, 'title', title)
  },
  showMessage(s, show) {
    Vue.set(s, 'show', show)
  }
}

// EXPORT

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
