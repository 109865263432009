import http from '../../http'

export function get(checklist_id, id){
  if(id){
    return http.get(`/v1/journeys/checklists/${checklist_id}/tasks/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/checklists/${checklist_id}`, { withCredentials: true })
  }
}

export function mark_complete(checklist_id, id, assignment_id){
  return http.post(`/v1/journeys/checklists/${checklist_id}/tasks/${id}/complete`, {data: { assignment_id }}, { withCredentials: true })
}

export function mark_incomplete(checklist_id, id, assignment_id){
  return http.delete(`/v1/journeys/checklists/${checklist_id}/tasks/${id}/complete`, {data: {data: {assignment_id }}}, { withCredentials: true })
}

