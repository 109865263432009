<template>
  <v-card width="720px">
    <v-card-title>
      Series 
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        ></v-text-field>
        <v-btn color="primary" dark class="ml-5" @click="add" v-if='permissions.add'>New Series</v-btn>
    </v-card-title>
    <v-data-table :headers="headers" :items="series" :search="search" :loading="loading" loading-text="Loading...Please Wait" @click:row="view">
        <template v-slot:[`item.templates_count`]="{ item }">
          {{ item.templates_count | count }}
        </template>
        <template v-slot:[`item.assignments_count`]="{ item }">
          {{ item.assignments_count | count }}
        </template>
        <template v-slot:no-data>
          <v-container>
            <v-row align="center">
              <v-col cols="12">
                <span class="title">No Series</span>
              </v-col>
              <v-col cols="12" v-if="permissions.add">
                <div class="subtitle mb-5">The organization does not have any series. Create a new series.</div>
                <v-btn color="primary" dark class="mb-2" @click="add">Create Series</v-btn>
              </v-col>
              <v-col cols="12" v-else>
                <div class="subtitle">The organization does not have any series.</div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:no-results>
          <v-container>
            <v-row align="center">
              <v-col cols="12">
                <span class="title">No Series Found</span>
              </v-col>
              <v-col cols="12" v-if="permissions.add">
                <div class="subtitle mb-5">No series found for this '{{search}}' query. Try a different search term or create a new series.</div>
                <v-btn color="primary" dark class="mb-2" @click="add">Create Series</v-btn>
              </v-col>
              <v-col cols="12" v-else>
                <div class="subtitle">No series found for this '{{search}}' query. Try a different search term.</div>
              </v-col>
            </v-row>
          </v-container>
        </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  name: "SeriesList",
  props: {
    series: Array,
    permissions: Object,
    loading: Boolean
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    view: function(series) {
      this.$emit('view', series)
    }
  },
  data: function() {
    return {
      headers: [
        {
          text: 'Title',
          value: 'title',
          sortable: true,
          filterable: true
        },
        {
          text: '# of Templates',
          value: 'templates_count',
          sortable: false
        },
        {
          text: '# of Assignments',
          value: 'assignments_count',
          sortable: false
        }
      ],
      search: ''
    }
  },
  filters: {
    count: function (value) {
      if(value) return value
      else return 0
    }
  }
}
</script>
<style lang="scss" scoped>
.series-list {
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
  }
</style>
