<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Task</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="taskForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field label="Content" :rules="[v => !!v || 'Content is required']" required v-model="form.content"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field label="Action" prefix="https://" v-model="form.action"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-combobox
                  v-model="form.complete_by"
                  :items="assignments_values"
                  label="Complete By"
                  chips
                  >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="data.item"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                      >
                      <v-avatar
                        class="accent white--text"
                        left
                        v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <v-checkbox v-model="form.require_sign_off" label="Require Sign Off" :disabled="form.complete_by != 'member'"></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field label="Offset Days to Reminder" type="number" v-model="form.remind_offset_days"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <v-checkbox v-model="form.send_reminder_flag" label="Send Reminder"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ChecklistTaskDialog",
  props: {
    value: Boolean,
    task: Object,
    saving: Boolean
  },
  computed: {
    offset_days_unset: function() {
      return !this.form.remind_offset_days || this.form.remind_offset_days !== ''
    }
  },
  methods: {
    clear () {
      this.$refs.taskForm.reset()
      this.form.content = ''
      this.form.action = ''
      this.form.remind_offset_days = 0
      this.form.complete_by= ''
      this.form.send_reminder_flag = true
      this.form.require_sign_off = false
    },
    save: function() {
      this.$refs.taskForm.validate()
      if (this.valid) {
        let t = this.task || {}
        this.$emit("save", {...t, ...this.form})
        this.clear()
      }
    },
    close: function() {
      this.clear()
      this.$emit("cancel")
    }
  },
  data: function() {
    return {
      form: {
        content: '',
        action: '',
        remind_offset_days: 0,
        complete_by: '',
        send_reminder_flag: true,
        require_sign_off: false
      },
      valid: true,
      show: this.value,
      assignments_values: [
        "member",
        "manager",
        "mentor",
        "admin"
      ],
      rules: {
        url: v => {
          const pattern = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
          return !v || pattern.test(v) || "Invalid url format."
        }
      },
    }
  },
  watch: {
    value: function(val, prev) {
      if(val != prev){
        this.show = val
        if(val){
          this.form = {...this.form, ...this.task}
        }else{
          this.clear()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
