<template>
  <v-container class="title-page pa-0 fill-height" :style="headerStyle">
    <v-row align="stretch">
      <v-col cols="12">
        <v-row align="center" justify="center" class="title-page mx-0" :style="headerStyle" >
          <div class="title-page-content">
    <div class="display-3">
      <span class="title-page-title">{{ guide.name }}</span>
      </div>
    <img :src="guide.cover_img" v-if="guide.cover_img" class="guide-logo">
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default{
  name: "TitlePage",
  props: {
    guide: Object
  },
  computed: {
    headerStyle: function() {
      if(this.guide){
        return {
          '--bg_color': this.guide.cover_color_hex,
          '--font': this.guide.title_font,
          '--font_color':this.guide.cover_text_color_hex
        }
      }
      return {}
    }
  },
  data(){
    return {
    }
  }
}
</script>

<style>
.title-page{
  background-color: var(--bg_color) !important;
}
.title-page-title{ 
  color: var(--font_color) !important;
  font-family: var(--font) !important;
  margin-bottom:15px;
}
.title-page-content{
  text-align: center;
}
.guide-logo{
  width: 275px;
}
</style>
