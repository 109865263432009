import role from './role'
import billing from './billing'
import integration from './integration'
const state = {
}
const mutations = {
}
const actions = {
}
const getters = {
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    role,
    billing,
    integration
  }
}
