import Vue from 'vue'
import {team} from '../actions'



const state = {
    members: [],
    searchTerm: ''
  }
const mutations = {
  set(s, members){
    Vue.set(s, 'members', members)
  },
  setSearchTerm(s, term) {
    Vue.set(s, 'searchTerm', term)
  },
  add(s, member) {
    Vue.set(s, 'members', s.members.concat(member))
  },
  update(s, member) {
    Vue.set(s, 'members', s.members.map(function(m){
      if(m.user_id === member.user_id){
        return member
      }else{
        return m
      }
    }))
  }
}
  const actions = {
    async fetch({ commit }){
      return team.get().then(function(response){
        commit('set', response.data.data)
        return response
      })
    },
    filter({ commit }, term){
      commit('setSearchTerm', term)
    },
    save({ commit }, member){
      if(member.user_id){
        team.update(member).then(function(response){
          commit('update', response.data.data)
        })
      }else{
      team.create(member).then(function(response){
        commit('add', response.data.data)
      })
      }
    },
    async search(_, term){
      if(term && term.length >= 3){
        return team.search(term).then(function(response){
          return response.data.data
        })
      }else{
        return Promise.resolve([])
      }
    },
    getById(_, id){
        return team.getById(id).then(function(response){
          return response.data.data
        })
    }
  }
  const getters = {
    filtered: (s) => {
      if (s.searchTerm) {
        return s.members.filter(m => `${m.first_name} ${m.last_name}`.toLowerCase().includes(s.searchTerm.toString().toLowerCase()))
      }else {
        return s.members
      }
    }
  }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
