<template>
    <v-row class="my-0 mx-5">
      <v-col v-for="item in guides" :key="item.id" lg="2" md="3" cols="12">
        <v-card @click="view(item)">
          <div :style="item | swatch" class="cover">
            <v-img :src="item.cover_img" max-height="180" v-if="item.cover_img"></v-img>
          </div>
          <v-card-title>{{ item.name }}</v-card-title>
          <v-card-actions>
            <span>{{ item.number_of_sections }} sections</span><v-spacer/><v-progress-circular :value="item.percent_completed" :color="item.cover_color_hex"/>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col lg="2" md="3" cols="12" v-if="permissions.add">
        <v-card @click="add" height="292">
          <v-card-text style="height: 100%">
            <v-container style="height: 100%">
            <v-row class="text-center" style="height: 100%" align="center" justify="center">
              <v-col>
            <div class="text-h6">Add a New Manual</div>
            <v-icon x-large>add</v-icon>
              </v-col>
            </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'GuideList',
  props: {
    guides: Array,
    permissions: Object
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    view: function(guide) {
      this.$emit('view', guide)
    }
  },
  filters: {
    swatch: function(g) {
      return `background-color: ${g.cover_color_hex};`
    },
    font: function(g) {
      if(g.title_font)
        return `font-family: ${g.title_font} !important;`
      else return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.color-swatch{
  width: 32px !important;
  height: 32px;
  border: 1px solid rgba(#000, .12);
}
.guides-list {
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgba(#000, .12);
}
.cover{
  height: 180px;
}
</style>
