<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Section</span></v-card-title>
      <v-card-text>
        <SectionForm v-model="sectionData" :uploadImage="uploadImage" ref="sectionForm"></SectionForm>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SectionForm from '@/components/guides/sections/SectionForm'
export default {
  name: 'SectionDialog',
  components: {
    SectionForm
  },
  props: {
    value: Boolean,
    saving: Boolean,
    section: Object,
    uploadImage: Function
  },
  data: function(){
    return {
    sending: false,
    show: this.value,
    sectionData: (this.section && {...this.section}) || {}
    }},
  methods: {
    close () {
      this.$emit('close')
      this.reset()
    },
    save() {
      let valid = this.$refs.sectionForm.validate()
      if (valid) {
        this.$emit('save', this.sectionData)
        this.reset()
      }
    },
    reset() {
      this.sectionData = {}
      this.$refs.sectionForm.reset()
    }
  },
  watch:{
    section: function(val, prev) {
      if(val != prev){
        this.sectionData = val || {}
      }
    },
    value: function(val, prev){
      if(prev != val){
        this.show = val
      }
    }
  }
}
</script>

<style lang="scss">
.section-preview{
  padding: 10px;
}
.section-dialog-content {
  min-width: 480px;
  padding: 32px;
  overflow-y: auto;
}
</style>
