<template>
  <v-container class="full-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-progress-circular size="100" width="10" indeterminate="true"></v-progress-circular>
          <div class="body-1">Opening Application...</div>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import {mapActions} from 'vuex'
export default {
    name: "Mobile",
    data() {
        return {
        }
    },
    methods: {
      ...mapActions({
        mobileSignIn: 'login/startMobile'
        }),
        next(){
          if(this.$route.query.page && (this.$route.query.page.startsWith("circle-welcome://") || this.$route.query.page.startsWith("circle://"))){
            this.mobileSignIn(this.$route.query.page)
          }
        }
    },
    mounted () {
        this.next()
    }
}
</script>
