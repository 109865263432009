import http from '../../../http'

export function get(series_id, id){
  if(id){
    return http.get(`/v1/journeys/series/${series_id}/templates/notifications/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/series/${series_id}/templates/notifications`, { withCredentials: true })
  }
}

export function save(series_id, template) {
  if(template.template_id){
    return http.put(`/v1/journeys/series/${series_id}/templates/notifications/${template.template_id}`, {template: template}, { withCredentials: true })
  }else{
    return http.post(`/v1/journeys/series/${series_id}/templates/notifications`, {template: template}, { withCredentials: true })
  }
}

export function send(series_id, template_id, payload) {
  return http.post(`/v1/journeys/series/${series_id}/templates/notifications/${template_id}/send`, {data: payload}, {withCredentials: true})
}
