<template>
  <router-view :serie="serie"></router-view>
</template>

<script>

export default {
  name: 'NotificationTemplateDashboardView',
  props: {
    serie: Object
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
