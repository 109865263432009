<template>
  <v-row align="start" class="fill-height mx-4">
    <v-col cols="12" md="5" lg="4">
      <v-row>
        <v-col>
          <circle-checklist-task-details :task="task" @edit="edit" v-if="task"/>
            <circle-checklist-task-dialog :task="task" v-model="show" @save="handleSave" @cancel="cancel"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistTaskView',
  props: {
    serie: Object,
    checklist_id: String,
    task_id: String
  },
  data: () => ({
    checklist: undefined,
    task: undefined,
    saving: false,
    show:  false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/template/checklist/task/fetchById',
      fetchChecklist: 'journey/serie/template/checklist/fetchById',
      save: 'journey/serie/template/checklist/task/save'
    }),
    edit: function(){
      this.show = true
    },
    view: function(s){
      console.warn(`View: assignment/${s.assignment_id}`)
    },
    handleSave: function(s) {
      this.saving = true
      var that = this
      this.save({serie: this.serie, checklist: this.checklist, task: s}).then(function(response){
        that.saving = false
        that.show = false
        that.task = {...(that.task|| {}), ...response.data.data}
      })
    },
    cancel: function(){
      this.show = false
    }
  },
  watch: {
    serie: function(val) {
      var that = this
      if(val){
        this.fetchChecklist({series_id: val.series_id, checklist_id: this.checklist_id}).then(function(g) {
          that.checklist = g
          that.fetch({series_id: val.series_id, checklist_id: that.checklist_id, task_id: that.task_id}).then(function(t){
            that.task = t
          })
        })
      }
    }
  },
  created(){
    var that = this
    if(this.serie || this.series_id){
      this.fetchChecklist({series_id: (this.serie && this.serie.series_id) || this.series_id, checklist_id: this.checklist_id}).then(function(g) {
        that.checklist = g
        that.fetch({series_id: (that.serie && that.serie.series_id) || that.series_id, checklist_id: that.checklist_id, task_id: that.task_id}).then(function(t){
          that.task = t
        })
      })
    }
  }
}
</script>

<style lang="scss">
</style>
