<template>
  <v-form v-model="valid" ref="orgForm">
    <v-container class="ma-0 px-0">
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field label="Name" :rules="[v => !!v || 'Organization name is required']" required v-model="form.name"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field label="Short Name" v-model="form.short_name"></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <ColorInput v-model="theme" label="Theme" />
        </v-col>
        <v-col cols="12" sm="3">
          <v-file-input label="Logo" @change="upload('logo_url', $event)" accept="image/*"></v-file-input>
        </v-col>
        <v-col cols="12" sm="3">
          <v-file-input label="Mobile Icon" @change="(e) => upload('mobile_icon_url', e)" accept="image/*"></v-file-input>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>

import ColorInput from '@/components/inputs/ColorInput'

export default {
  name: 'OrganziationForm',
  components: {
    ColorInput
  },
  props: ['value', 'uploadImage'],
  data: function(){
    return {
      form: {
        name: this.value && this.value.name,
        short_name: this.value && this.value.short_name,
        branding: {
          primary_color_hex: (this.value && this.value.branding && this.value.branding.primary_color_hex) || "#3f51b5",
          secondary_color_hex: (this.value && this.value.branding && this.value.branding.secondary_color_hex) || "#f50057",
          theme: (this.value && this.value.branding && this.value.branding.theme) || "Default",
          logo_url: this.value && this.value.branding && this.value.branding.logo_url,
          mobile_url: this.value && this.value.branding && this.value.branding.mobile_icon_url
        }
      },
      valid: true,
    }
  },
  methods: {
    upload(property, event){
      if(event){
      var that = this
      this.uploadImage({property, 'img': event}).then(function(response){
        if(response && response.image_url) {
          that.form["branding"][response.property] = response.image_url
        }})
      }
    },
    validate() {
      this.$refs.orgForm.validate()
      return this.valid
    },
    reset() {
      this.$refs.orgForm.reset()
      this.form = {
        name: this.value && this.value.name,
        short_name: this.value && this.value.short_name,
        branding: {
          primary_color_hex: (this.value && this.value.branding && this.value.branding.primary_color_hex) || "#3f51b5",
          secondary_color_hex: (this.value && this.value.branding && this.value.branding.secondary_color_hex) || "#f50057",
          theme: (this.value && this.value.branding && this.value.branding.theme) || "Default",
          logo_url: this.value && this.value.branding && this.value.branding.logo_url,
          mobile_url: this.value && this.value.branding && this.value.branding.mobile_icon_url
        }
      }
    }
  },
  computed: {
    theme: {
      get: function() {
        return {
          "primary": (this.form.branding && this.form.branding.primary_color_hex) || "#3f51b5",
          "secondary":(this.form.branding && this.form.branding.secondary_color_hex) || "#f50057",
          "theme":(this.form.branding && this.form.branding.theme) || "Default"
        }
      },
      set: function(newTheme){
        this.form.branding.primary_color_hex = newTheme.primary
        this.form.branding.secondary_color_hex = newTheme.secondary
        this.form.branding.theme = newTheme.theme
      }
    }
  },
  watch: {
    value: function(val, prev) {
      if(val != prev){
        this.form = {
          name: this.value && this.value.name,
          short_name: this.value && this.value.short_name,
          branding: {
            primary_color_hex: (this.value && this.value.branding && this.value.branding.primary_color_hex) || "#3f51b5",
            secondary_color_hex: (this.value && this.value.branding && this.value.branding.secondary_color_hex) || "#f50057",
            theme: (this.value && this.value.branding && this.value.branding.theme) || "Default",
            logo_url: this.value && this.value.branding && this.value.branding.logo_url,
            mobile_url: this.value && this.value.branding && this.value.branding.mobile_icon_url
          }
        }
      }
    },
    form: {
      deep: true,
      handler(val) {
        if(this.validate()){
          let new_value = {...this.value, ...val}
          this.$emit('input', new_value)
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
