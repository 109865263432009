<template>
  <v-card>
<div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline"
                  >{{ task.content }}</v-card-title>
                <v-card-subtitle> 
                  Complete By: {{ task.complete_by }}, Require Signoff: {{ task.require_sign_off }}, Send Reminder: {{ task.send_reminder_flag | yes_no }}
              </v-card-subtitle>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
                color="green"
                v-if="task.remind_offset_days >= 0"
              >
              <span class="white--text headline">{{task.remind_offset_days}}</span>
              </v-avatar>
              <v-avatar
                class="ma-3"
                size="125"
                tile
                color="red"
                v-else
              >
              <span class="white--text headline">{{task.remind_offset_days}}</span>
              </v-avatar>
            </div>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col 
            v-for="stat in stats"
            :key="stat.title"
            cols="6">
            <v-card class="stats-card" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Stat</div>
                  <v-list-item-title class="headline mb-1">{{stat.value}}</v-list-item-title>
                  <v-list-item-subtitle>{{stat.title}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon><v-icon x-large>{{stat.icon}}</v-icon></v-list-item-icon>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="edit">Edit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default{
  name: "TaskDetails",
  props: {
    task: Object
  },
  computed: {
    stats: function(){
      return [] 
    }
  },
  data(){
    return {
    }
  },
  methods: {
    edit: function(){
      this.$emit('edit', {...this.task})
    }
  },
  filters: {
    yes_no: function(val) {
      if(val){
        return 'Yes'
      }else{
        return 'No'
      }
    }
  }

}
</script>

<style scoped>
</style>
