import http from '../../../http'

import * as task from './task'

export function get(series_id, id){
  if(id){
    return http.get(`/v1/journeys/series/${series_id}/templates/checklists/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/series/${series_id}/templates/checklists`, { withCredentials: true })
  }
}

export function save(series_id, checklist) {
  if(checklist.checklist_id){
    return http.put(`/v1/journeys/series/${series_id}/templates/checklists/${checklist.checklist_id}`, { checklist }, { withCredentials: true })
  }else{
    return http.post(`/v1/journeys/series/${series_id}/templates/checklists`, { checklist }, { withCredentials: true })
  }
}

export { task }
