<template>
  <circle-base-layout :tabs="tabs">
    <template v-slot:title>
      {{ serie && serie.title }}<v-btn icon @click="show['serie'] = true" small><v-icon small>edit</v-icon></v-btn>
      <circle-series-dialog v-model='show.serie' :serie='serie' @save='handleSaveSeries' @close="cancel('serie')" :saving='saving' />
    </template>
    <template v-slot:default>
      <router-view :serie="serie"></router-view>
      <!-- v-row>
        <v-col cols="12">
          <v-row justify="center" style="height: 100px">
            <v-card class="stats-card mr-5" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Stat</div>
                  <v-list-item-title class="headline mb-1">{{ (serie && serie.rate && serie.rate.open) || "0%" }}</v-list-item-title>
                  <v-list-item-subtitle>Open Rate</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon><v-icon x-large>drafts</v-icon></v-list-item-icon>
              </v-list-item>
            </v-card>
            <v-card class="stats-card" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Stat</div>
                  <v-list-item-title class="headline mb-1">{{ (serie && serie.rate && serie.rate.reply) || "0%" }}</v-list-item-title>
                  <v-list-item-subtitle>Reply Rate</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon><v-icon x-large>mdi-reply</v-icon></v-list-item-icon>
              </v-list-item>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col class="pl-5" cols="12" md="6" sm="12">
              <circle-template-list :templates="templates" :permissions="permissions" @add="add('template')" @edit="editTemplate" @view="viewTemplate"/>
              <circle-template-dialog :template="template" :show="show.template" @save="handleSaveTemplate" @cancel="cancel('template')" />
            </v-col>
            <v-col class="pr-5" cols="12" md="6" sm="12">
              <circle-assignment-list :assignments="assignments" :permissions="permissions" @add="add('assignment')" @edit="editAssignment" @view="viewAssignment" />
              <circle-assignment-dialog :search='search' :assignment="assignment" :fetchMembers="search" :show="show.assignment" @save="handleSaveAssignment" @cancel="cancel('assignment')"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row -->
    </template>
  </circle-base-layout>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'SeriesHome',
  props: {
    series_id: String,
    tabs: Array
  },
  data: () => ({
    show: {
      assignment: false,
      serie: false
    },
    serie: undefined,
    assignments: [],
    assignment: undefined,
    saving: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/fetchById',
      search: 'team/search',
      saveAssignment: 'journey/serie/assignment/save',
      saveSeries: 'journey/serie/save'
    }),
    add: function(property){
      this[property] = undefined
      this.show[property] = true
    },
    editAssignment: function(s){
      this.assignment = { ...s }
      this.show.assignment = true
    },
    viewAssignment: function(s){
      console.warn(`View: assignment/${s.assignment_id}`)
    },
    handleSaveAssignment: function(s) {
      this.saving = true
      var that = this
      this.saveAssignment({serie: this.serie, assignment: s}).then(function(response){
        that.saving = false
        that.show.assignment= false
        if(s.assignment_id){
          that.assignments = that.assignments.map(o => [response.data.data].find(o2 => o.assignment_id === o2.assignment_id) || o)
        }else{
          that.assignments = that.assignments.concat([response.data.data])
        }
        that.assignment = undefined
      })
    },
    handleSaveSeries: function(s){
      this.saving = true
      var that = this
      this.saveSeries(s).then(function(sr) {
        that.saving = false
        that.serie = sr.data.data
        that.show.serie = false
      })
    },
    cancel: function(property){
      this.show[property] = false
    }
  },
  created () {
    var that = this
    this.fetch(this.series_id).then(function(g) {
      that.serie = g
      that.assignments = g.assignments
    })
  }
}
</script>

<style lang="scss">
.stats-card{
  display: inline-block;
  margin-bottom: 15px;
}
</style>
