<template>
  <div id="app">
    <v-app id="circle">
    <router-view default="login"></router-view>
    <v-snackbar v-model="notify.show">
      <span>{{ this.notify.message }}</span>
    </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'app',
  data (){
    return {}
  },
  computed: {
    ...mapState({
      notify: s => s.app,
      user: s => s.login.user,
      organization: s => s.login.organization
    })
  },
  watch: {
    user(val, old){
      if(!old && val && this.$ma){
          this.$ma.setUserProperties({ "Organization": val.organization_id })
          this.$ma.identify({userId: val.user_id})
      }
    },
    organization(val, old){
      if(!old && val && this.$ma){
          this.$ma.setUserProperties({ "OrganizationName": val.name})
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
