<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving" >
      <v-card-title><span class="headline">Manual</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="guideForm">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field label="Name" :rules="[v => !!v || 'Guide name is required']" required v-model="form.name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input label="Cover Image" @change="upload('cover_img', $event)" accept="image/*"></v-file-input>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                    v-model="form.cover_color_hex"
                    label="Cover Color"
                    readonly
                    outlined
              >
                <template v-slot:append>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                offset-y>
                <template v-slot:activator="{ on }">
                  <div :style="swatchStyle(form.cover_color_hex)" v-on="on" />
                </template>
                <v-color-picker mode="hexa" :value="form.cover_color_hex" @input="(v) => form.cover_color_hex = v" hide-mode-switch>
                </v-color-picker>
              </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                    v-model="form.cover_text_color_hex"
                    label="Title Color"
                    readonly
                    outlined
              >
                <template v-slot:append>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                offset-y>
                <template v-slot:activator="{ on }">
                  <div :style="swatchStyle(form.cover_text_color_hex)" v-on="on" />
                </template>
                <v-color-picker mode="hex" :value="form.cover_text_color_hex" @input="(v) => form.cover_text_color_hex = v" hide-mode-switch>
                </v-color-picker>
              </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <CircleFontSelect label="Title Font" :value="form.title_font" @change="v => form.title_font = v" />
            </v-col>
            <v-col cols="12" sm="3">
              <CircleFontSelect label="Body Font" :value="form.body_font" @change="v => form.body_font = v"/>
            </v-col>
          </v-row>
        </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">Close</v-btn>
      <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import CircleFontSelect from '@/components/inputs/CircleFontSelection'

export default {
  name: 'GuideDialog',
  components: {
    CircleFontSelect
  },
  props: [
    'value',
    'saving',
    'guide',
    'uploadImage'
  ],
  data: function(){
    return {
    form: {
        name: undefined,
        cover_color_hex: '#2196F3',
        cover_text_color_hex: '#FFFFFF',
        title_font: undefined,
        body_font: undefined
    },
    sending: false,
    colorMask: '!#XXXXXXXX',
    valid: false,
    show: this.value
  }
  },
  methods: {
    swatchStyle(color){
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderColor: 'black',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px'
      }
    },
    upload(property, event){
      if(event){
      var that = this
      this.uploadImage({property, 'img': event}).then(function(response){
        if(response && response.image_url) {
          that.form[response.property] = response.image_url
        }})
      }
    },
    close () {
      this.$emit('close')
      this.reset()
    },
    save() {
      this.$refs.guideForm.validate()

      if (this.valid) {
        let g = this.guide || {}
        this.$emit('save', {...g, ...this.form})
        this.reset()
      }
    },
    reset() {
      this.$refs.guideForm.reset()
      this.form = {
        name: undefined,
        cover_color_hex: '#2196F3',
        cover_text_color_hex: '#FFFFFF',
        title_font: undefined,
        body_font: undefined
      }
    }
  },
  watch:{
    value: function(val, prev){
      if(prev != val){
        this.show = val
        if(val){
          this.form = {...this.form, ...this.guide}
        }else{
          this.reset()
        }
      }
    }
  },
  filters: {
  }
}
</script>

<style lang="scss">
</style>
