<template>
  <v-row align="start" class="fill-height mx-4">
    <v-col cols="12" md="5" lg="4">
      <v-row>
        <v-col>
          <circle-checklist-details :checklist="checklist" @edit="edit" v-if="checklist"/>
            <circle-checklist-dialog :checklist="checklist" v-model="show['checklist']" @save="handleSave" @cancel="cancel('checklist')"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="5" lg="4">
      <v-row>
        <v-col>
          <circle-checklist-template-task-list :tasks="tasks" :permissions="permissions" v-if="checklist" @add="show['task'] = true" @view="viewTask"/>
            <circle-checklist-task-dialog :task="task" v-model="show['task']" @save="handleSaveTask" @cancel="cancel('task')" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistView',
  props: {
    serie: Object,
    checklist_id: String,
    series_id: String
  },
  data: () => ({
    checklist: undefined,
    task: undefined,
    tasks: [],
    saving: false,
    show: {
      checklist: false,
      task: false
    }
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/template/checklist/fetchById',
      fetchSerie: 'journey/serie/fetchById',
      save: 'journey/serie/template/checklist/save',
      saveTask: 'journey/serie/template/checklist/task/save'
    }),
    edit: function(){
      this.show = true
    },
    view: function(s){
      console.warn(`View: assignment/${s.assignment_id}`)
    },
    handleSave: function(s) {
      this.saving = true
      var that = this
      this.save({serie: this.serie, checklist: s}).then(function(response){
        that.saving = false
        that.show['checklist'] = false
        that.checklist  = {...(that.checklist || {}), ...response.data.data}
      })
    },
    handleSaveTask: function(s) {
      this.saving = true
      var that = this
      this.saveTask({serie: this.serie, checklist: this.checklist, task: s}).then(function(response){
        that.saving = false
        that.show['task'] = false
        that.tasks = that.tasks.concat( [ response.data.data ] )
      })
    },
    cancel: function(property){
      this.show[property] = false
    },
    viewTask: function(t) {
      this.$router.push({name: 'admin-checklist-task-view', params: { 'checklist_id': this.checklist.checklist_id, 'task_id': t.task_id }})
    }
  },
  watch: {
    serie: function(val) {
      var that = this
      if(val){
        this.fetch({series_id: val.series_id, checklist_id: this.checklist_id}).then(function(g) {
          that.checklist = g
          that.tasks = that.checklist.tasks
        })
      }
    }
  },
  created(){
    var that = this
    if(this.serie || this.series_id){
      this.fetch({series_id: (this.serie && this.serie.series_id) || this.series_id, checklist_id: this.checklist_id}).then(function(g) {
        that.checklist = g
        that.tasks = that.checklist.tasks
      })
    }
  }

}
</script>

<style lang="scss">
</style>
