import http from '../http'
import * as template from './template'
import * as assignment from './assignment'


export function get(id) {
  if(id){
    return http.get(`/v1/journeys/series/${id}`, { withCredentials: true })
  }else{
    return http.get("/v1/journeys/series", { withCredentials: true })
  }
}

export function save(series) {
  if(series.series_id){
    return http.put(`/v1/journeys/series/${series.series_id}`, {series}, { withCredentials: true})
  }else{
    return http.post("/v1/journeys/series", {series}, { withCredentials: true})
  }
}
export { template, assignment }
