<template>
  <v-card :max-width="width">
    <v-list three-line subheader>
      <v-subheader>Inbox</v-subheader>
      <template v-for="(item, index) in messages">
      <v-divider
        v-if="index > 0"
          :key="index"
          inset
        ></v-divider>
      <v-list-item
        :key="item.message_id"
        @click="view(item)"
      >
        <v-list-item-icon>
          <v-icon color="primary" v-if="!item.is_read">mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title v-html="item.subject"></v-list-item-title>
            <v-list-item-subtitle v-html="item.body"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        </template>
      <v-list-item v-if="load_more">
        <v-btn text color="primary" large>Load More...</v-btn>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  name: "Inbox",
  components: {
  },
  props: {
    messages: Array,
    width: String,
    load_more: Boolean
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    view: function(message){
      this.$emit('view', message)
    }
  }
};
</script>
<style lang="scss">
</style>
