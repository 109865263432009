export function clickthroughrate(m){
      var total = m.metadata && m.metadata.notifications && m.metadata.notifications.summary && m.metadata.notifications.summary.total
      var hits = (m.metadata && m.metadata.notifications && m.metadata.notifications.summary && m.metadata.notifications.summary.engagement && m.metadata.notifications.summary.engagement.hits) || 0.0
      if(total && total != 0) return (hits/total) * 100.0
      else return 'N/A'
}

export function isMobileSafari() {
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
}

export function isMobile() {
  return navigator.userAgent.match(/(Android|iPod|iPhone|iPad)/) 
}

export function outerHeight(el) {
  var height = (el && el.offsetHeight) || 0;
  var style = getComputedStyle(el);

  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
}

export function outerWidth(el) {
  var width = (el && el.offsetWidth) || 0;
  var style = getComputedStyle(el);

  width += parseInt(style.marginLeft) + parseInt(style.marginRight);
  return width;
}

