<template>
  <v-row align="start" class="fill-height mx-4">
    <v-col cols="4">
      <v-row>
        <v-col>
          <circle-guide-details :guide="guide" />
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row>
        <v-col>
          Stats
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <circle-guide-permissions-list :permissions="permissions" :authorization="authorization" :user="user" :fetchMembers="search" @add="handleAddPermission" @edit="handleEditPermission" @delete="handleRemovePermission"/>
            <circle-guide-permissions-dialog :show="show" :permission="permission" :fetchMembers="search" @save="handleSavePermission" @cancel="handleCancelPermission" @delete="handleRemovePermission"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'GuideHomeView',
  props: {
    guide: Object
  },
  data: () => ({
    permissions: [],
    permission: undefined,
    show: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    authorization: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPermissions: 'guide/permission/fetch',
      savePermission: 'guide/permission/save',
      removePermission: 'guide/permission/remove',
      search: 'team/search'
    }),
    handleAddPermission: function(e){
      if(e){
        let that = this
        this.savePermission({'guide_id': this.guide.id, 'permission': e}).then(function(response) {
          that.permissions = that.permissions.concat([response])
        })
      }else{
        this.permission = undefined
        this.show = true
      }
    },
    handleRemovePermission: function(e){
      let that = this;
      this.removePermission({'guide_id': this.guide.id, 'permission': e}).then(function(){
        that.handleCancelPermission()
        that.permissions = that.permissions.filter(p => p.permission_id !== e.permission_id)

      })
    },
    handleEditPermission: function(e){
      let u = {...{}, ...e.user}
      this.permission = {...{...{}, ...e}, ...{'user': u}}
      this.show = true
    },
    handleSavePermission: function(e) {
        let that = this
        this.savePermission({'guide_id': this.guide.id, 'permission': e}).then(function(response) {
          if(e.permission_id){
            that.permissions = that.permissions.map(function(p){
              if(p.permission_id == response.permission_id){
            return response
            }else{
              return p
            }})
          }else{
            that.permissions = that.permissions.concat([response])
          }
          that.handleCancelPermission()
        })
    },
    handleCancelPermission: function(){
      this.permission = undefined
      this.show = false
    }
  },
  watch: {
    guide(val){
    let that = this
    if(val){
      this.fetchPermissions(val.id).then(function(permissions){
        that.permissions = permissions
      })
    }
    }
  }
}
</script>

<style lang="scss">
</style>
