var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"user_search",attrs:{"multiple":_vm.multiple,"search-input":_vm.search,"label":_vm.label,"item-text":"email","item-value":"user_id","items":_vm.items,"loading":_vm.loading,"no-filter":"","chips":"","clearable":"","deletable-chips":"","return-object":"","solo":"","cache-items":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.no_data_text)}})],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-avatar',[_c('img',{attrs:{"src":item.avatar,"alt":item.first_name}})]),_c('span',[_vm._v(_vm._s(_vm._f("name")(item)))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.avatar,"alt":_vm._f("name")(item)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("name")(item))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("primaryEmail")(item)))])],1)]}}]),model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}})}
var staticRenderFns = []

export { render, staticRenderFns }