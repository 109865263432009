<template>
  <v-card>
    <v-img height="250" :src="guide.cover_img" v-if="guide && guide.cover_img"></v-img>
    <v-card-title>{{ guide.name }}</v-card-title>
    <v-card-text>
      {{ guide.description }}
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-title>Details</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="5" class="subtitle-2">Cover Background Color</v-col>
          <v-col :style="bg_color" class="background-color-data">{{ guide && guide.cover_color_hex }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="subtitle-2">Cover Font</v-col>
          <v-col :style="font" class="font-data">{{ guide && guide.title_font }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="subtitle-2">Cover Font Color</v-col>
          <v-col :style="font_color" class="font-color-data">{{ guide && guide.cover_text_color_hex }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="subtitle-2">Mobile Icon</v-col>
          <v-col><img :src="guide.mobile_icon_img" v-if="guide && guide.mobile_icon_img"/></v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

export default{
  name: "GuideDetails",
  props: {
    guide: Object
  },
  computed: {
    bg_color: function(){
        return {
          '--bg_color': this.guide && this.guide.cover_color_hex
        }
    },
    font: function(){
        return {
          '--font': this.guide && this.guide.title_font
        }
    },
    font_color: function(){
      return {
        '--font': this.guide && this.guide.cover_text_color_hex
      }
    }
  },
  data(){
    return {
    }
  }
}
</script>

<style scoped>
.background-color-data{
  color: var(--bg_color) !important;
}
.font-data{ 
  font-family: var(--font) !important;
}
.font-color-data{
  color: var(--font_color) !important;
}
</style>
