<template>
  <circle-base-layout>
    <template v-slot:title>
      Team
    </template>
    <template v-slot:default>
  <v-row class="my-0">
    <v-col cols="12">
      <v-row justify="center" class="my-0">
        <circle-team-list v-bind:members='members' :loading="loading" v-bind:permissions='permissions' @add='newMember' @edit="edit"/>
        <circle-member-dialog v-model='displayDialog' :member='member' :fetchMembers="search" :organization_roles="roles" @close="reset" @save="save" @add-role="add_role"/>
      </v-row>
    </v-col>
  </v-row>
  </template>
  </circle-base-layout>
</template>

<script>
import { mapState, mapActions, mapGetters} from 'vuex'

export default {
  name: 'TeamDashboard',
  data: () => ({
    sending: false,
    member: undefined,
    displayDialog: false,
    loading: true,
  }),
  watch: {
    user: function(val) {
      if(val && val.organization_id){
        this.fetchRoles(val.organization_id)
      }

    }
  },
  computed: {
    ...mapState({
      user: s => s.login.user,
      roles: s => s.organization.role.roles
    }),
    ...mapGetters({
      filtered: 'team/filtered'
    }),
    members: function() {
      return this.filtered.filter( m => m.user_id != this.user.user_id )
    },
    permissions: function() {
      return {
        'add': this.user.roles.includes('admin')
      }
    }},
  methods: {
    ...mapActions({
      fetch: 'team/fetch',
      filter: 'team/filter',
      upsert: 'team/save',
      search: 'team/search',
      getById: 'team/getById',
      setTitle: 'app/setTitle',
      fetchRoles: 'organization/role/fetch',
      addRole: 'organization/role/save'
    }),
    newMember () {
      this.member = undefined
      this.displayDialog = true
    },
    edit(m) {
      var that = this
      this.getById(m.user_id).then(function(u){
        that.member = u
        that.displayDialog = true
      })
    },
    save(m) {
      this.upsert(m)
      this.reset()
    },
    reset() {
      this.member = undefined
      this.displayDialog = false
    },
    add_role(name){
      this.addRole({organization_id: this.user.organization_id, name: name})
    }
  },
  created () {
    this.setTitle("Team")
    let that = this
    this.fetch().then(function(){
      that.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
.md-field {
  max-width: 300px;
}
.md-dialog {
  min-width: 480px;
  max-width: 768px;
}
.member-dialog-content {
  padding: 32px;
}
</style>
