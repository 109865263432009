<template>
  <v-row align="start" class="fill-height my-0 px-5">
    <v-col>
      <circle-integration-list :integrations="availableIntegrations" /> 
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'
export default {
  name: 'IntegrationsSettings',
  components: {
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      features: s => s.feature.features,
      integrations: s => s.organization.integration.integrations,
    }),
    availableIntegrations: function() {
      let that = this
      return this.integrations.filter(s => that.features[s.id])
    }
  },
  methods: {
    ...mapActions({
      list: 'organization/integration/get',
      fetchFeatures: 'feature/fetch'
    }),
  },
  created () {
    this.list()
    this.fetchFeatures("slack")
  }
}
</script>

<style lang="scss" scoped>
</style>
