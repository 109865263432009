<template>
  <v-card width="720px">
    <v-card-title>
      Team Members
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        ></v-text-field>
        <v-btn color="primary" dark class="ml-5" @click="add" v-if='permissions.add'>New Member</v-btn>
    </v-card-title>
    <v-data-table :headers="headers" :items="team_members" :search="search" :loading="loading" loading-text="Loading...Please Wait">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            >
            mdi-pencil
          </v-icon>
            <v-icon
              small
              @click="deleteMember(item)"
              >
              mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
          <v-container>
            <v-row align="center">
              <v-col cols="12">
                <span class="title">No Members</span>
              </v-col>
              <v-col cols="12" v-if="permissions.add">
                <div class="subtitle mb-5">The organization does not have any members. Create a new member.</div>
                <v-btn color="primary" dark class="mb-2" @click="add">Create Member</v-btn>
              </v-col>
              <v-col cols="12" v-else>
                <div class="subtitle">The team does not have any members.</div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:no-results>
          <v-container>
            <v-row align="center">
              <v-col cols="12">
                <span class="title">No Members Found</span>
              </v-col>
              <v-col cols="12" v-if="permissions.add">
                <div class="subtitle mb-5">No members found for this '{{search}}' query. Try a different search term or create a new member.</div>
                <v-btn color="primary" dark class="mb-2" @click="add">Create Member</v-btn>
              </v-col>
              <v-col cols="12" v-else>
                <div class="subtitle">No members found for this '{{search}}' query. Try a different search term.</div>
              </v-col>
            </v-row>
          </v-container>
        </template>
    </v-data-table>
  </v-card>
</template>
<script>

export default {
  name: "TeamList",
  props: {
    permissions: Object,
    loading: Boolean,
    members: Array
  },
  data: () => (
    {
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          filterable: true
        },
        {
          text: 'Employee Start Date',
          value: 'employee_start_dt',
          sortable: true
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: ''
    }
  ),
  methods: {
    add() {
      this.$emit('add')
    },
    edit(member){
      this.$emit('edit', member)
    },
    deleteMember(member){
      this.$emit('delete', member)
    }
  },
  computed: {
    team_members: function() {
      return this.members.map(function(m){
        let name = {'name': m.last_name + ", " + m.first_name}
        let primary_email = {'primary_email': m.email && (m.email.work || m.email.personal)}
        return {...{...m, ...name}, ...primary_email}
      })
    }
  }
}
</script>
