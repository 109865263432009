import Vue from 'vue'
import {guide} from '../../actions'

import section from './section.js'
import permission from './permission.js'



const state = {
  guides: []
}
const mutations = {
  set(s, guides){
    Vue.set(s, 'guides', guides)
  },
  update(s, g){
    Vue.set(s, 'guides', s.guides.map(o => [g].find(o2 => o.id === o2.id) || o))
  },
  add(s, g){
    Vue.set(s, 'guides', s.guides.concat([g]))
  }
}
const actions = {
  fetch({ commit }){
    guide.get().then(function(response){
      commit('set', response.data.data)
    })
  },
  async fetchById(_config, id){
    return guide.get(id).then(function(response){
      return response.data.data
    })
  },
  async save({ dispatch, commit }, g){
    return guide.save(g).then(function(response){
      if(response.data && response.data.data && response.data.data.id){
        if(g.id){
          dispatch("app/notify", `Successfully updated ${g.name}.`, { root: true })
          commit('update', g)
        }else{
          dispatch("app/notify", `Successfully created ${g.name}.`, { root: true })
          commit('add', response.data.data)
        }
        return response
      }else{
        return response
      }
    })
  },
  async uploadImage(_config, image) {
    return guide.uploadImage(image.property, image.img).then(response => response.data)
  },
  async uploadQuillImage(_config, image) {
    return await guide.uploadImage('', image).then(function(response) {
      return response.data
    })
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    section,
    permission
  }
}
