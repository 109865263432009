import Vue from 'vue'
import VueRouter from 'vue-router'

import SettingsDashboard from '@/views/settings/Dashboard.vue'
import OrganizationSettings from '@/views/settings/OrganizationSettings.vue'
import NotificationSettings from '@/views/settings/NotificationSettings.vue'
import BillingSettings from '@/views/settings/BillingSettings.vue'
import IntegrationSettings from '@/views/settings/IntegrationSettings.vue'


Vue.use(VueRouter)

export const routes = [
  {
    path: '/settings',
    component: SettingsDashboard,
    children: [
      {
        path: "organization",
        name: 'organization-settings',
        component: OrganizationSettings
      },
      {
        path: "notifications",
        name: 'notification-settings',
        component: NotificationSettings
      },
      {
        path: "billing",
        name: 'billing-settings',
        component: BillingSettings 
      },
      {
        path: "integrations",
        name: 'integrations-settings',
        component: IntegrationSettings 
      }

    ]
  }
]
