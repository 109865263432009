import Vue from 'vue'
import VueMultianalytics from 'vue-multianalytics'
import VueIntercom from 'vue-intercom'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from '@sentry/browser'
import {Vue as VueIntegration } from '@sentry/integrations'



import vuetify from '@/plugins/vuetify' // path to vuetify export
/*import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)*/

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
Vue.use(VueTelInputVuetify, {
  vuetify
})

import './components' 

process.env.VUE_APP_INTERCOM_APPID && Vue.use(VueIntercom, {appId: process.env.VUE_APP_INTERCOM_APPID})

process.env.NODE_ENV === 'production' && Sentry.init({
    environment: "prod",
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({Vue, attachProps: true})],
    release: process.env.COMMIT_REF
  })

let mixpanelConfig = {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  debug: process.env.NODE_ENV != 'production'
}
process.env.VUE_APP_MIXPANEL_TOKEN && Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig
  },
  routing: {
    vueRouter: router
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  watch: {
    '$route': function() {
      window.Intercom('update')
    }
  }
}).$mount('#app')
