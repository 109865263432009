import Vue from 'vue'
import {organization} from '../../actions'
const state = {
  roles: []
}
const mutations = {
  set(s, roles){
    Vue.set(s, 'roles', roles)
  },
  add(s, role){
    Vue.set(s, 'roles', s.roles.concat([role]))
  }
}
const actions = {
  fetch({commit}, organization_id){
    return organization.role.fetch(organization_id).then(function(response){
      commit('set', response.data.data)
      return response.data.data
    })
  },
  save({commit}, {organization_id, name}){
    return organization.role.save(organization_id, name).then(function(response){
      commit('add', response.data.data)
      return response.data.data
    })
  }
}
const getters = {
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
