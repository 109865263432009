<template>
  <v-menu
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="290px"
        offset-y>
    <template v-slot:activator="{on}">
      <span class="caption">{{ label }}</span>
      <v-sheet height="64" width="64" :style="selectedStyle" class="swatch" v-on="on"></v-sheet>
    </template>
    <v-sheet>
      <v-container fluid>
        <v-row>
          <v-col cols="3" v-for="(color, i) in colors" :key="i">
    <v-sheet height="72" width="72" :style="colorStyle(color)" class="ma-5 swatch" @click="$emit('input', color)" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorInput',
  props: ['value', 'label'],
  data: () => ({
    colors: [      
      {
        "primary": "#9B3A48",
        "secondary": "#AA661E",
        "theme": "Victoria"
      },
      {
        "primary": "#912e5f",
        "secondary": "#298e02",
        "theme": "MaroonGreen"
      },
      {
        "primary": "#B83F26",
        "secondary": "#222222",
        "theme": "Red"
      },
      {
        "primary": "#F44336",
        "secondary": "#607D8B",
        "theme": "RedBlueGray"
      },
      {
        "primary": "#FF8C00",
        "secondary": "#222222",
        "theme": "Orange"
      },
      {
        "primary": "#ffd500",
        "secondary": "#222222",
        "theme": "Yellow"
      },
      {
        "primary": "#38B449",
        "secondary": "#222222",
        "theme": "BrightGreen"
      },
      {
        "primary": "#8c9b70",
        "secondary": "#14481B",
        "theme": "DarkGreen"
      },
      {
        "primary": "#2fb4af",
        "secondary": "#222222",
        "theme": "Aqua"
      },
      {
        "primary": "#46c8e9",
        "secondary": "#F0BC44",
        "theme": "PrendaA"
      },
      {
        "primary": "#F0BC44",
        "secondary": "#46c8e9",
        "theme": "PrendB"
      },
      {
        "primary": '#3f51b5',
        "secondary": '#f50057',
        "theme": 'Default',
      },
      {
        "primary": "#2166E6",
        "secondary": "#0d82df",
        "theme": "Blue"
      },
      {
        "primary": "#191970",
        "secondary": "#0d82df",
        "theme": "DarkBlue"
      },
      {
        "primary": "#2663A3",
        "secondary": "#0d82df",
        "theme": "Alcott"
      },
      {
        "primary": "#564C56",
        "secondary": "#584b57",
        "theme": "Mauve"
      },
      {
        "primary": "#222222",
        "secondary": "#0d82df",
        "theme": "Black"
      },
      {
        "primary": "#A9A9A9",
        "secondary": "#0d82df",
        "theme": "LightGray"
      },
      {
        "primary": "#5e4838",
        "secondary": "#d39521",
        "theme": "Brown"
      },
      {
        "primary": "#396A8E", 
        "secondary": "#58585A", 
        "theme": "SunParamount"
      },
      {
        "primary": "#2C3F3B", 
        "secondary": "#F0BF45",
        "theme": "SunMarSierra"
      },
      {
        "primary": "#203E6A", 
        "secondary": "#E2703D", 
        "theme": "SunMarCommunity"
      },
      {
        "primary": "#193D27", 
        "secondary": "#58585A", 
        "theme": "SunMarAnaheim"
      },
      {
        "primary": "#3B5832", 
        "secondary": "#6F8E3F", 
        "theme": "SunMarOaks"
      },
      {
        "primary": "#22478A", 
        "secondary": "#EDA044", 
        "theme": "SunMar"
      }
      ]
  }),
  computed: {
    selectedStyle: function(){
      return {
        "--primary_color": this.value.primary,
        "--secondary_color": this.value.secondary
      }
    }
  },
  methods: {
    colorStyle(color){
      return {
        "--primary_color": color.primary,
        "--secondary_color": color.secondary
      }
    }
  }
}
</script>

<style lang="scss">
.swatch{
background-image: linear-gradient(to bottom right, var(--primary_color), var(--secondary_color));
}
</style>
