import http from '../http'

export function get(id){
  if(id){
    return http.get(`/v1/journeys/messages/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/messages`, { withCredentials: true })
  }
}

export function get_link_by_hash(hash){
    return http.get(`/v1/journeys/go/${hash}`)
}

export function mark_read(id) {
  return http.post(`/v1/journeys/messages/${id}/read`)
}
