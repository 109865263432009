import http from '../../../http'

export function get(series_id, checklist_id, id){
  if(id){
    return http.get(`/v1/journeys/series/${series_id}/templates/checklists/${checklist_id}/tasks/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/series/${series_id}/templates/checklists/${checklist_id}/tasks`, { withCredentials: true })
  }
}

export function save(series_id, checklist_id, task) {
  if(task.task_id){
    return http.put(`/v1/journeys/series/${series_id}/templates/checklists/${checklist_id}/tasks/${task.task_id}`, { task }, { withCredentials: true })
  }else{
    return http.post(`/v1/journeys/series/${series_id}/templates/checklists/${checklist_id}/tasks`, { task }, { withCredentials: true })
  }
}
