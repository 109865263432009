<template>
  <v-card>
<div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline"
                  >{{ checklist.title }}<v-btn icon @click="edit()" small><v-icon small>edit</v-icon></v-btn></v-card-title>
                <v-card-subtitle> 
              Start: {{ checklist.start_offset_days | offset_days }}, Due: {{ checklist.due_offset_days | due_offset_days }}
              </v-card-subtitle>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
                v-if="checklist.badge"
              >
                <v-img :src="checklist.badge"></v-img>
              </v-avatar>
            </div>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col 
            v-for="stat in stats"
            :key="stat.title"
            cols="6">
            <v-card class="stats-card" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Stat</div>
                  <v-list-item-title class="headline mb-1">{{stat.value}}</v-list-item-title>
                  <v-list-item-subtitle>{{stat.title}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon><v-icon x-large>{{stat.icon}}</v-icon></v-list-item-icon>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

export default{
  name: "ChecklistDetails",
  props: {
    checklist: Object
  },
  computed: {
    stats: function(){
      return [] 
    }
  },
  data(){
    return {
    }
  },
  methods: {
    edit: function(){
      this.$emit('edit', {...this.checklist})
    }
  },
  filters: {
    offset_days: function(o){
      if(o){
        if(o < 0){
          return `${Math.abs(o)} day(s) before assigned date`
        }else{
          return `${Math.abs(o)} day(s) after assigned date`
        }
      }
      return '0'
    },
    due_offset_days: function(o){
      if(o){
        if(o < 0){
          return `${Math.abs(o)} day(s) before start date`
        }else{
          return `${Math.abs(o)} day(s) after start date`
        }
      }
      return '0'
    }
  }

}
</script>

<style scoped>
</style>
