import Vue from 'vue'
import {organization} from '../../actions'
const state = {
  integrations: []
}
const mutations = {
  setIntegrations(s, integrations) {
    Vue.set(s, "integrations", integrations)
  }
}
const actions = {
  get({commit}){
    return organization.integration.get().then(function(response){
      commit('setIntegrations', response.data.data)
      return response.data.data
    })
  }
}
const getters = {
  integrations: state => state.integrations
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
