<template>
  <circle-base-layout>
    <template v-slot:title>
     Series 
    </template>
    <template v-slot:default>
      <v-row class="my-0">
        <v-col cols="12">
          <v-row class="my-0" justify="center">
            <circle-series-list :series='series' @add="add" @view="view" :permissions="permissions" :loading='loading' />
            <circle-series-dialog v-model='show' :serie='serie' @save='save' @close='close' :saving='saving' />
          </v-row>
        </v-col>
      </v-row>
    </template>
  </circle-base-layout>
</template>
<script>
import {mapState,  mapActions } from 'vuex'
export default {
  name: "SeriesList",
  data: function(){
    return {
      show: false,
      serie: undefined,
      saving: false
    }
  },
  computed: {
    ...mapState({
      user: s => s.login.user,
      series: s => s.journey.serie.series,
      loading: s => s.journey.serie.loading
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/fetch',
      upsert: 'journey/serie/save'
    }),
    add: function(){
      this.show = true
    },
    view: function(s){
      this.$router.push({name: 'admin-series-home', params: { 'series_id': s.series_id}})
    },
    edit: function(s){
      this.serie = { ...s }
      this.show = true
    },
    save: function(s){
      this.saving = true
      var that = this
      this.upsert(s).then(function() {
        that.saving = false
        that.serie = undefined
        that.show = false
      })
    },
    close: function(){
      this.saving = false
      this.serie = undefined
      this.show = false
    }
  },
  created: function(){
    this.fetch()
  }
}
</script>
<style>
</style>
