import { journey } from '../../../../../actions'
import task from './task'

const state = {
}
const mutations = {
}
const actions = {
  async save({ dispatch }, {serie: serie, checklist: checklist}){
    return journey.series.template.checklist.save(serie.series_id, checklist).then(function(response){
      if(response.data && response.data.data && response.data.data.checklist_id){
        if(checklist.checklist_id){
          dispatch("app/notify", `Successfully updated ${checklist.title}.`, { root: true })
        }else{
          dispatch("app/notify", `Successfully created ${checklist.title}.`, { root: true })
        }
        return response
      }else{
        return response
      }
    })
  },
  async fetchById(_s, {series_id, checklist_id}){
    return journey.series.template.checklist.get(series_id, checklist_id).then(function(response){
      return response.data && response.data.data
    })
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    task
  }
}
