<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Series</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="serieForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field label="Title" :rules="[v => !!v || 'Title is required']" required v-model="form.title"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'JourneyDialog',
  props: {
    value: Boolean,
    saving: Boolean,
    serie: Object
  },
  data: function(){
    return {
    form: {
      title: ''
    },
    valid: true,
    show: this.value
  }
  },
  methods: {
    close () {
      this.$emit('close')
      this.reset()
    },
    save() {
      this.$refs.serieForm.validate()

      if (this.valid) {
        let s = this.serie || {}
        this.$emit('save', {...s, ...this.form})
        this.reset()
      }
    },
    reset() {
      this.$refs.serieForm.reset()
      this.form = {
        title: ''
      }
    }
  },
  filters: {
  },
  watch: {
    value: function(val, prev){
      if(val != prev){
        this.show = val
        if(val){
          this.form = {...this.form, ...this.serie }
        }else{
          this.reset()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.serie-dialog-content {
  padding: 0px 20px;
  min-width: 300px;
}
</style>
