<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title>Notification Templates</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click='add' icon v-if="permissions.add">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list three-line>
      <template v-for="(item, index) in notifications">
        <v-list-item :key="item.template_id" @click='view(item)'>
          <v-list-item-avatar
            size="40"
            color="grey lighten-2"
          >{{ item.offset_days }}</v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium" v-html="item.title"></v-list-item-title>
            <v-list-item-subtitle v-html="item.body"></v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-bold">
              To: {{ item.assignments | assignments }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
            v-if="index + 1 < notifications.length"
            :key="index"
          ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'TemplateList',
  props: {
    notifications: Array,
    permissions: Object
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    view: function(template) {
      this.$emit('view', template)
    }
  },
  filters: {
    offset_days: function(o){
      if(o){
        if(o < 0){
          return `${Math.abs(o)} day(s) before start date`
        }else{
          return `${Math.abs(o)} day(s) after start date`
        }
      }
      return '0'
    },
    assignments: function(a){
      if(a && a.length > 0){
        return a.join(', ')
      }
      return 'None'
    }
  }
}
</script>

<style lang="scss">
.templates-list {
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
  }
</style>
