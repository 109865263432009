<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-text-field prepend-inner-icon="email" label="Work Email" :rules="[rules.email]" v-model="work_email"></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-text-field prepend-inner-icon="email" label="Personal Email" :rules="[rules.email]" v-model="personal_email"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EmailInput',
  props: ['value'],
  data: function(){
    return {
      personal_email: this.value && this.value.personal,
      work_email: this.value && this.value.work,
      rules: {
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !v || pattern.test(v) || 'Invalid e-mail.'
        }
      }
    }
  },
  watch: {
    value: function(val) {
      this.personal_email = val && val.personal
      this.work_email = val && val.work
    },
    personal_email: function(val, prev){
      let email = {}
      if(val && val != prev){
        email = { ...email, ...{ personal: val}}
      if(this.work_email){
        email = { ...email, ...{ work: this.work_email}}
      }
      this.$emit('input', email)
      }
    },
    work_email: function(val, prev){
      let email = {}
      if(val && val != prev){
        email = { ...email, ...{ work: val}}
      if(this.personal_email){
        email = { ...email, ...{ personal: this.personal_email}}
      }
      this.$emit('input', email)
      }
    }
  }
}
</script>
