<template>
  <v-form v-model="valid" ref="memberForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="First Name" :rules="[v => !!v || 'First name is required']" required v-model="form.first_name"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="Last Name" :rules="[v => !!v || 'Last name is required']" required v-model="form.last_name"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
                <v-menu
                  v-model="employee_start_dt_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="employee_start_dt"
                      label="Employee Start Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker v-model="employee_start_dt" @input="employee_start_dt_menu = false"></v-date-picker>
                </v-menu>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <vue-tel-input-vuetify v-model="phone" mode="international" label="Phone" :onlyCountries="['US']" :rules="[rules.phone]"></vue-tel-input-vuetify>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <EmailInput v-model="form.email" :rules="[v => !!v.personal || !!v.work || 'An Email must be provided']" required />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-combobox v-model="form.tz" :items="timezones" label="Timezone"></v-combobox>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <EmployeeLinks v-model="form.links" :fetchMembers="fetchMembers" :organization_roles="organization_roles" @add-role="add_role"/>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import EmployeeLinks from '@/components/inputs/EmployeeLinks'
import EmailInput from '@/components/inputs/EmailInput'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment-timezone'

export default {
  name: 'MemberForm',
  components: {
    EmployeeLinks,
    EmailInput 
  },
  props: ['value', 'fetchMembers', 'organization_roles'],
  data: function(){
    return {
    form: {
      first_name: this.value && this.value.first_name,
      last_name: this.value && this.value.last_name,
      email: this.value && this.value.email,
      phone_number: this.value && this.value.phone_number,
      links: this.value && this.value.links,
      employee_start_dt: (this.value && this.value.employee_start_dt) || '',
      tz: (this.value && this.value.tz) || 'America/Los_Angeles'
    },
    timezones: moment.tz.names(),
    phone: this.value && this.value.phone_number,
    employee_start_dt: (this.value && this.value.employee_start_dt) || '',
    employee_start_dt_menu: false,
    valid: true,
    rules: {
      phone: v => {
        let phoneNumber = v && parsePhoneNumberFromString(v)
        return !phoneNumber || phoneNumber.isValid() || 'Invalid phone number.'
      }
    }
  }
  },
  methods: {
    validate() {
      this.$refs.memberForm.validate()
      return this.valid
    },
    reset() {
      this.$refs.memberForm.reset()
      this.form = {
        'email': {},
        'links': [],
        'tz': 'America/Los_Angeles'
      }
      this.employee_start_dt = '' 
      this.phone = ""
    },
    add_role: function(value){
      this.$emit("add-role", value)
    }
  },
  filters: {
    name: function(member){
      return member.first_name + ' ' + member.last_name
    }
  },
  watch: {
    phone: function(val) {
      if(val){
        if(typeof val == 'string' || val instanceof String){
          let parsed = parsePhoneNumberFromString(val)
          this.form.phone_number = (parsed && parsed.number) || ''
        }else{
          this.form.phone_number = (val.number && val.number.e164) || ''
        }
      }else{
        this.form.phone_number = ''
      }
    },
    value: function(val, prev) {
     if(val != prev){
        this.form.first_name = val && val.first_name 
        this.form.last_name = val && val.last_name
        this.form.email = val && val.email
        this.form.phone_number = val && val.phone_number
        this.form.links = (val && val.links) || []
        this.phone = val && val.phone_number 
        this.form.employee_start_dt = (val && val.employee_start_dt) || ''
        this.form.tz = (val && val.tz) || 'America/Los_Angeles'
        this.employee_start_dt = (val && val.employee_start_dt) || ''
      }
    },
    employee_start_dt: function(val, prev) {
      if(val != prev){
        this.form.employee_start_dt = val
      }
    },
    form: {
      deep: true,
      handler(val) {
        if(this.validate()){
          let new_value = {...this.value, ...val}
          this.$emit('input', new_value)
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
