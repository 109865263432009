import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import login from './modules/login'
import app from './modules/app'
import team from './modules/team'
import guide from './modules/guide/index'
import journey from './modules/journey/index'
import organization from './modules/organization/index'
import feature from './modules/feature/index'

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default new Vuex.Store({
    namespaced: true,
    modules: {
      app,
      login,
      team,
      guide,
      journey,
      organization,
      feature
  }
})
