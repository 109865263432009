<template>
  <div>
    <v-list two-line subheader v-if="headers">
      <template v-for="(item, index) in value">
        <v-divider
          v-if="index > 0"
          v-bind:key="id(prefix,index)"
          ></v-divider>
          <v-subheader v-bind:key="item.header">{{item.header}}</v-subheader>
          <template v-for="task in item.tasks">
            <v-list-item
              v-bind:key="task_key(task)"
              >
              <v-list-item-action>
                <v-btn icon color="primary" @click="markIncomplete(task)" v-if="task.completed"><v-icon>mdi-checkbox-marked</v-icon></v-btn>
                <v-btn icon color="primary" @click="markComplete(task)" v-else><v-icon>mdi-checkbox-blank-outline</v-icon></v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-html="task.content"></v-list-item-title>
                <v-list-item-subtitle v-if="task.checklist.user.user_id !== me.user_id">{{ task | name }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="task.completed">{{ task.completed_dt | date }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ task.recommended_complete_by_dt | date }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="primary" @click="action(task)" ><v-icon>mdi-share</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
      </template>
    </v-list>
    <v-list two-line v-else>
      <template v-for="(item, index) in value">
        <v-divider
          v-if="index > 0"
          v-bind:key="id(prefix,index)"
          inset
          ></v-divider>
          <v-list-item
            :key="task_key(item)"
            >
              <v-list-item-action>
                <v-btn icon color="primary" @click="markIncomplete(item)" v-if="item.completed"><v-icon>mdi-checkbox-marked</v-icon></v-btn>
                <v-btn icon color="primary" @click="markComplete(item)" v-else><v-icon>mdi-checkbox-blank-outline</v-icon></v-btn>
              </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="item.content"></v-list-item-title>
              <v-list-item-subtitle v-if="item.checklist.user.user_id !== me.user_id">{{ item | name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.completed">{{ item.completed_dt | date }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{ item.recommended_complete_by_dt | date }}</v-list-item-subtitle>
            </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="primary" @click="action(task)" ><v-icon>mdi-share</v-icon></v-btn>
              </v-list-item-action>
          </v-list-item>
      </template>
    </v-list>
  </div>
      </template>
      <script>
import moment from 'moment'
export default {
  name: "TodoList",
  components: {
  },
  props: {
    value: Array,
    me: Object,
    headers: Boolean,
    prefix: String
  },
  data() {
    return {
    };
  },
  methods: {
    action: function(todo){
      this.$emit('take-action', todo)
    },
    markComplete: function(todo){
      this.$emit('mark-complete', todo)
    },
    markIncomplete: function(todo){
      this.$emit('mark-incomplete', todo)
    },
    id: function(todo, index){
      return `${todo}-${index}`
    },
    task_key: function(task){
      return `${task.checklist_id}-${task.task_id}-${task.assignment_id}`
    }
  },
  filters: {
    date: function(d) {
      if(d){
        return moment(d).format('MMM DD YYYY')
      }
      return ''
    },
    name: function(d) {
      return `${d.checklist.user.first_name} ${d.checklist.user.last_name}`
    }
  }
};
</script>
<style lang="scss">
</style>

