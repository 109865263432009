<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title>Assignments</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click='add' icon v-if="authorization.add">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list two-line>
      <template v-if="can_assign_organization">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Organization</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="assign_organization"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Managers</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="assign_managers" :disabled="assign_organization"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Mentors</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="assign_mentors" :disabled="assign_organization"></v-switch>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-if="can_assign_teams">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Team</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="assign_team"></v-switch>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-divider v-if="user_assignments.length > 0"></v-divider>
      <template v-for="(item, index) in user_assignments">
        <v-divider
          v-if="index + 1 < user_assignments.length"
          :key="index"
          ></v-divider>
          <v-list-item :key="item.permissions_id" @click='edit(item)'>
            <v-list-item-avatar v-if="item.user && item.user.avatar"><v-img :src="item.user.avatar" /></v-list-item-avatar>
              <v-list-item-avatar size="40"
                                  color="primary" v-else>{{ item.user | initials }}</v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>{{ item.user | name }}</v-list-item-title>
                                  <v-list-item-subtitle>
                                    <v-chip v-for="scope in item.scopes" color="primary" outlined class="ma-2" :key='scope'>
                                      {{ scope }}
                                    </v-chip>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
          </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'PermissionList',
  props: {
    permissions: Array,
    authorization: Object,
    user: Object,
    fetchMembers: [Promise, Function]
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    edit: function(assignment) {
      this.$emit('edit', assignment)
    },
  },
  computed: {
    user_assignments: function() {
      return this.permissions.filter(a => a.user && a.object_id !== this.user.user_id)
    },
    can_assign_organization: function(){
      return this.user && this.user.roles.includes('admin')
    },
    can_assign_teams: function(){
      return this.user && this.user.roles.some(el => el === 'manager' || el === 'mentor')
    },
    assign_organization: {
      get: function(){
        let organization_id = this.user && this.user.organization_id
        return this.permissions.some(el => el.object_id === organization_id)
      },
      set: function(newValue){
        if(newValue){
          let organization_id = this.user && this.user.organization_id
          this.$emit('add', {'object_id': organization_id, 'scopes': ['view']})
        }else{
          let organization_id = this.user && this.user.organization_id
          this.$emit('delete', this.permissions.find(el => el.object_id === organization_id))
        }
      }
    },
    assign_team: {
      get: function(){
        let team_id = `team-${this.user.user_id}`
        return this.permissions.some(el => el.object_id === team_id)
      },
      set: function(newValue){
        let team_id = this.user && `team-${this.user.user_id}`
        if(newValue){
          this.$emit('add', {'object_id': team_id, 'scopes': ['view']})
        }else{
          this.$emit('delete', this.permissions.find(el => el.object_id === team_id))
        }
      }
    },
    assign_managers: {
      get: function(){
        return this.permissions.some(el => el.object_id === 'manager')
      },
      set: function(newValue){
        if(newValue){
          this.$emit('add', {'object_id': 'manager', 'scopes': ['view']})
        }else{
          this.$emit('delete', this.permissions.find(el => el.object_id === 'manager'))
        }
      }
    },
    assign_mentors: {
      get: function(){
        return this.permissions.some(el => el.object_id === 'mentor')
      },
      set: function(newValue){
        if(newValue){
          this.$emit('add', {'object_id': 'mentor', 'scopes': ['view']})
        }else{
          this.$emit('delete', this.permissions.find(el => el.object_id === 'mentor'))
        }
      }
    }

  },
  filters: {
    initials: function(a) {
      if(!a) return ''
      return ((a.first_name && a.first_name.charAt(0)) || '') + ((a.last_name && a.last_name.charAt(0)) || '')
    },
    name: function(a) {
      if(!a) return ''
      return ((a.first_name && (a.first_name + ' ')) || '') + ((a.last_name && a.last_name) || '')
    }
  }
}
</script>

<style lang="scss">
.assignments-list {
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgba(#000, .12);
}
</style>
