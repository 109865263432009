<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" sm="12" v-for="(link, index) in links" :key="link.role" class="pa-0">
        <EmployeeLink v-model="links[index]" :organization_roles="organization_roles" :fetchMembers="fetchMembers" />
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pa-0">
        <EmployeeLink v-model="new_link" :organization_roles="organization_roles" :fetchMembers="fetchMembers" @add-role="add_role"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmployeeLink from '@/components/inputs/EmployeeLink'
export default {
  name: 'EmployeeLinks',
  components: {
    EmployeeLink
  },
  props: ['value', 'organization_roles', 'fetchMembers'],
  data: function(){
    return {
      links: this.value || [],
      new_link: {}
    }
  },
  methods: {
    add_role: function(value){
      this.$emit("add-role", value)
    }
  },
  watch: {
    value: function(val) {
      this.links = val
    },
    new_link: function(val, prev){
      if(prev != val && val.role){
        this.links.push(val)
        this.new_link = {
          role: undefined,
          member: undefined
        }
      }
    },
    links: function(val, prev) {
      if(val != prev) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
