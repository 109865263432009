<template>
  <v-autocomplete
    v-model = "result"
    :multiple="multiple"
    :search-input.sync="search"
    :label="label"
    item-text="email"
    item-value="user_id"
    :items = "items"
    :loading="loading"
    ref="user_search"
    no-filter
    chips
    clearable
    deletable-chips
    return-object
    solo
    cache-items
    >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-html="no_data_text">
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="blue-grey"
        class="white--text"
        v-on="on"
        >
        <v-avatar><img :src="item.avatar" :alt="item.first_name"></v-avatar>
        <span>{{ item | name }}</span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar>
        <v-img :src="item.avatar" :alt="item | name"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{item | name }} </v-list-item-title>
        <v-list-item-subtitle> {{ item | primaryEmail }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'MemberAutoComplete',
  props: {
    value: {
      type: [Object, Array]
    },
    fetch :{
      type: [Function, Promise]
    },
    label: String, 
    no_data_text: String, 
    multiple: Boolean
  },
  data: function(){
    let i = []
    if(!this.multiple && this.value){
      i.push(this.value)
    }else if(this.value){
      i = this.value
    }
    return {
    search: null,
    loading: false,
    items: i, 
    timer: null,
    result: this.value
    }
  },
  watch: {
    search (val){
      if( !val || val.length == 0){
        this.items = []
        return
      }
      let that = this
      val && this.debounce(function(s){ that.doSearch.bind(that, s)() }, 250)(val)
    },
    value (val) {
      this.result = val
      this.search = ''
      if(val) {
        if(!this.multiple){
          this.items = [ val ]
        }else{
          this.items = val
        }
      }
    },
    result(val) {
      if (val !== this.value){
        this.$emit('input', val)
      }
    }
  },
  methods: {
    debounce(fn, delay) {
      var context = this
      return function () {
        var args = arguments;
        clearTimeout(context.timer);
        context.timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    },
    doSearch(val) {
      this.loading = true
      let context = this
      this.fetch(val)
        .then(function(members) {
          if(!context.multiple){
            if(context.result)
              context.$refs['user_search'].cachedItems = members.concat([context.result])
            else
              context.$refs['user_search'].cachedItems = members
          }else{
            context.$refs['user_search'].cachedItems = members.concat(context.result)
          }

          context.items = members 
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (context.loading = false))
    }
  },
  filters: {
    name: function(item){
      return item.first_name + ' ' + item.last_name
    },
    primaryEmail: function(item) {
      return item.email && (item.email.work || item.email.personal || '')
    }
  }
}
</script>
