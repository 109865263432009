<template>
  <v-app>
    <v-container class='fill-height' fluid v-if="!loaded">
      <v-row align='center' justify='center' class="my-0">
        <v-col col='12' sm='8' md='4'>
          <v-progress-circular size="100" width="10" indeterminate="true"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-system-bar
      fixed
      color="#fff"
      height="48px"
      window
      light
      app 
      class="circle-system-bar"
      v-if="loaded">
      <span :style="logoStyle" class="logo" v-if="organization && organization.branding && organization.branding.logo_url"></span>
      <v-toolbar-title style="overflow: visible" v-else>{{ organization && organization.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs light right v-if="apps.length > 0">
        <v-tab v-for="item in apps" link :to="item.link" :key="item.name">{{ item.name }}</v-tab>
      </v-tabs>
      <v-menu
        bottom
        offset-y
        >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-2">
          <v-badge
            :content="new_message_count"
            :value="new_message_count"
            overlap
            >
            <v-icon size="30" class="mr-0">mdi-bell</v-icon>
          </v-badge>
          </v-btn>
        </template>
        <Inbox :messages="inbox" :width="400" @view="viewMessage" load_more/>
      </v-menu>
      <v-menu
        bottom
        offset-y
        :close-on-content-click="false"
        >
        <template v-slot:activator="{ on }">
          <v-avatar v-if="user && user.avatar" size="36" v-on="on">
            <img :src="user.avatar" alt="People">
          </v-avatar>
        </template>
        <Profile @change-password="showChangePasswordDialog = true"/>
      </v-menu>
    </v-system-bar>
    <v-app-bar
      color="primary"
      absolute
      dark
      app v-if="loaded">
      <v-toolbar-title class="app-page-title"><slot name="title"></slot></v-toolbar-title>
      <template v-slot:extension>
        <v-tabs align-with-title backgorund-color="primary" v-if="tabs.length > 0">
          <v-tab v-for="item in tabs" :to="item.link" :key="item.name">{{ item.name }}</v-tab>
        </v-tabs>
        <v-spacer v-else></v-spacer>
      </template>
    </v-app-bar>
    <v-main class="app-content" v-if="loaded">
      <v-container class="pa-0 fill-height default-layout" fluid>
        <slot></slot>
      </v-container>
      <ChangePasswordDialog :show="showChangePasswordDialog" :saving="savingChangePassword" @save="doChangePassword" @close="showChangePasswordDialog = false" />
    </v-main>
    <v-footer
      :inset="true"
      color="#fff"
      light
      padless
      app
      absolute
      class="footer"
      v-if="loaded">
      <span class="px-4 footer-logo">
        <p>Powered By</p> <img src='@/assets/powered-by.png'/>
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Profile from "@/components/Profile.vue";
import Inbox from "@/components/series/Inbox.vue";
import ChangePasswordDialog from "@/components/ChangePassword.vue"

export default {
  name: 'BaseLayout',
  components: {
    Profile,
    ChangePasswordDialog,
    Inbox
  },
  props: {
    loaded: {
      type: [Boolean, Function],
      default: true,
      required: true
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data (){
    return {
      showChangePasswordDialog: false,
      savingChangePassword: false
    }
  },
  computed: {
    ...mapState({
      user: s => s.login.user,
      organization: s => s.login.organization,
      inbox: s => s.journey.inbox.messages
    }),
    new_message_count: function(){
      return this.inbox.filter(m => !m.is_read).length
    },
    preview_inbox: function(){
      return this.inbox.slice(0,5)
    },
    apps: function(){
      if(this.user){
        let p = [{
          link: (this.user.roles.includes('admin'))?'/admin/guides':'/guides',
          icon: 'mdi-notebook-multiple',
          name: 'Manuals'
        },
          {
            link: '/inbox',
            icon: '',
            name: 'My Inbox'
          },
          {
            link: '/checklists',
            icon: '',
            name: 'My Todos'
          },
        ]
        if(this.user.roles.includes('admin')){
          return p.concat([{
            link: '/admin/journeys/series',
            icon: 'mdi-forum',
            name: 'Journeys'
          },{
            link: '/admin/team',
            icon: 'mdi-account-multiple',
            name: 'Team'
          }
          ])
        }else{
          return p
        }
      }else{
        return []
      }
    },
    logoStyle(){
      return {
        "--logo": `url(${this.organization && this.organization.branding && this.organization.branding.logo_url})`
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'login/fetch',
      fetchInbox: 'journey/inbox/fetch',
      changePassword: 'login/changePassword'
    }),
    doChangePassword(password){
      this.savingChangePassword = true;
      let that = this
      this.changePassword(password).then(function(){
        that.savingChangePassword = false;
        that.showChangePasswordDialog = false;
      })
    },
    viewMessage: function(s){
      this.$router.push({ name: 'view-inbox-message', params: { message_id: s.message_id }})
    }
  },
  watch:{
    organization(v){
      if(v && v.branding && v.branding.primary_color_hex){
        this.$vuetify.theme.themes.light.primary = v.branding.primary_color_hex
        this.$vuetify.theme.themes.dark.primary = v.branding.primary_color_hex
      }
      if(v && v.branding && v.branding.secondary_color_hex){
        this.$vuetify.theme.themes.light.secondary = v.branding.secondary_color_hex
        this.$vuetify.theme.themes.dark.secondary = v.branding.secondary_color_hex
      }
    }
  },
  created(){
    this.fetch()
    this.fetchInbox()
  }
}
</script>

<style lang="scss">
.logo{
  width: 50%;
  height: 42px;
  background: var(--logo) no-repeat;
  background-size: contain;
}
.footer{
  border-top: #ddd 1px solid;
  color: #999 !important;
}
.footer-logo{
  line-height: 36px;
  img {
    height: 16px;
  }
  p{
    font-size: 10px;
    vertical-align: middle;
    margin-bottom: -20px !important;
  }
}
.circle-system-bar{
  border: #ddd 1px solid !important;
}
.app-content{
  background-color: #eee;
}
.app-page-title{
  font-size: 2rem;
}
.default-layout{
  align-items: start !important;
}
</style>
