<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app-view',
  data (){
    return {}
  },
}
</script>

<style lang="scss">
</style>
