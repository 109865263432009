<template>
  <v-form v-model="valid" ref="sectionForm">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field label="Name" :rules="[v => !!v || 'Section name is required']" required v-model="form.name"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <quill-editor ref="myTextEditor"
                        v-model="content"
                        :options="editorOption">
          </quill-editor>
          <v-file-input ref="quillImage" style="display: none"  name="quill-image" id="quill-image"  accept="image/*" @change="upload(undefined, $event)"/>
        </v-col>
        <v-col cols="12" sm="12">
          <div>Additional Section Settings</div>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field label="Description" v-model="form.description"></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <IconSelect label="Icon" :value="form.icon" @change="v => form.icon = v"></IconSelect>
        </v-col>
        <v-col cols="12" sm="4">
          <CircleFontSelection label="Font" :value="form.font" @change="v => form.font = v"></CircleFontSelection>
        </v-col>
        <v-col cols="12" sm="3">
          <v-file-input label="Image" @change="upload('image', $event)" accept="image/*"></v-file-input>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select label="Image Placement" :items="placements" :value="form.placement" @change="v => form.placement = v"></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="form.bg_color_hex"
            label="Background Color"
            readonly
            outlined
            >
            <template v-slot:append>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                offset-y>
                <template v-slot:activator="{ on }">
                  <div :style="swatchStyle(form.bg_color_hex)" v-on="on" />
                </template>
            <v-color-picker mode="hexa" :value="form.bg_color_hex" @input="(v) => form.bg_color_hex = v" hide-mode-switch>
            </v-color-picker>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="form.font_color_hex"
            label="Font Color"
            readonly
            outlined
            >
            <template v-slot:append>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                offset-y>
                <template v-slot:activator="{ on }">
                  <div :style="swatchStyle(form.font_color_hex)" v-on="on" />
                </template>
            <v-color-picker mode="hexa" :value="form.font_color_hex" @input="(v) => form.font_color_hex = v" hide-mode-switch>
            </v-color-picker>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import IconSelect from '@/components/inputs/IconSelect.vue'
import CircleFontSelection from '@/components/inputs/CircleFontSelection.vue'

import Quill from 'quill'
var FontAttributor = Quill.import('attributors/class/font');
FontAttributor.whitelist = [
  'lato', 'roboto-slab'
];
Quill.register(FontAttributor, true);
var Size = Quill.import('attributors/style/size');
Size.whitelist = ['14px', '16px', '18px', '24px', '36px', '48px'];
Quill.register(Size, true);

export default {
  name: 'SectionForm',
  components: {
    IconSelect,
    CircleFontSelection
  },
  props: ['value', 'uploadImage'],
  data: function(){
    return{
      valid: true,
      form: {
        name: this.value && this.value.name,
        description: this.value && this.value.description,
        icon: this.value && this.value.icon,
        image: this.value && this.value.image,
        placement: this.value && this.value.placement,
        bg_color_hex: (this.value && this.value.bg_color_hex) || '#FFFFFF',
        font_color_hex: (this.value && this.value.font_color_hex) || '#000000',
        font: this.value && this.value.font,
        body: this.value && this.value.body
      },
      placements: ['background', 'left', 'right', 'centered', 'top'],
      content: this.value && this.value.body
    }},
  computed:{
    editorOption(){
      let that = this
      return ({
        modules: {
          toolbar: {
            container:[
              [{ 'size': ['14px', '16px', '18px', '24px', '36px', '48px'] }],
              [{ 'font': ['', 'lato', 'roboto-slab']}],
              ['bold', 'italic', 'underline'],
              [{ 'align': [] }],
              [{ 'color': ["#ff4440","#C5DBC9","#0A3F3F","#DBD221"] }, { 'background': ["#ff4440", "#C5DBC9","#0A3F3F","#DBD221"] }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['link', 'image', 'video']
            ],
            handlers:{
              'image': () => {
                that.quillImageInput.click()
              }
            }}
        }
      })
    },
    editor() {
      return this.$refs.myTextEditor.quill
    },
    quillImageInput() {
      return this.$refs.quillImage.$refs.input
    }
  },
  methods: {
    validate() {
      this.$refs.sectionForm.validate()
      return this.valid
    },
    reset() {
      this.$refs.sectionForm.reset()
      this.form = {
        bg_color_hex: '#FFFFFF',
        font_color_hex: '#000000'
      }
      this.content = undefined
    },
    swatchStyle(color){
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderColor: 'black',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px'
      }
    },
    upload(property, event){
      if(event){
        var that = this
        this.uploadImage({property, 'img': event}).then(function(response){
          if(response && response.image_url) {
            if(property)
              that.form[property] = response.image_url
            else {
              const range = that.editor.getSelection();
              that.editor.insertEmbed(range.index, 'image', response.image_url);
            }
          }})
      }
    }
  },
  watch:{
    value: function(val, prev){
      if(prev != val){
        this.form.name = val && val.name
        this.form.description = val && val.description
        this.form.icon = val && val.icon
        this.form.image = val && val.image
        this.form.placement = val && val.placement
        this.form.bg_color_hex = val && val.bg_color_hex
        this.form.font_color_hex = val && val.font_color_hex
        this.form.font = val && val.font
        this.form.body = val && val.body
        this.content = val && val.body
      }
    },
    content: function(val, prev) {
      if(val !== prev){
        this.form.body = val
      }
    },
    form: {
      deep: true,
      handler(val){
        if(this.validate()){
          let new_value = {...this.value, ...val}
          this.$emit('input', {...new_value, ...{
            "delta": this.editor.getContents(),
            "plain_text": this.editor.getText()
          }})
        }
      }
    }
  }
}
</script>

<style lang="scss">
.section-preview{
  padding: 10px;
}
.section-dialog-content {
  min-width: 480px;
  padding: 32px;
  overflow-y: auto;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before
{
  content: 'Default';
}
.font-arial span.md-list-item-text{
  font-family: Arial;
}
.font-sans-serif span.md-list-item-text{
  font-family: "Sans Serif";
}
.font-serif span.md-list-item-text{
  font-family: "Serif";
}
.font-monospace span.md-list-item-text{
  font-family: "Monospace";
}
.font-shadows-into-light span.md-list-item-text{
  font-family: "Shadows Into Light";
}
.font-caveat span.md-list-item-text{
  font-family: "Caveat";
}
.font-dancing-script span.md-list-item-text{
  font-family: "Dancing Script";
}
.font-pinyon-script span.md-list-item-text{
  font-family: "Pinyon Script";
}
.font-tangerine span.md-list-item-text{ 
  font-family: "Tangerine";
}
.font-notable span.md-list-item-text{
  font-family: "Notable";
}
.font-alfa-slab-one span.md-list-item-text{
  font-family: "Alfa Slab One";
}
.font-roboto-slab span.md-list-item-text{
  font-family: "Roboto Slab";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='roboto-slab']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='roboto-slab']::before
{
  content: 'Roboto Slab';
  font-family: 'Roboto Slab';
}
.ql-font-roboto-slab {
  font-family: "Roboto Slab";
}
.font-erica-one span.md-list-item-text{
  font-family: "Erica One";
}
.font-lato span.md-list-item-text{
  font-family: "Lato";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='lato']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lato']::before
{
  content: 'Lato';
  font-family: 'Lato';
}
.ql-font-lato {
  font-family: "Lato";
}
.font-raleway span.md-list-item-text{
  font-family: "Raleway";
}
.font-nunito span.md-list-item-text{
  font-family: "Nunito";
}
.font-open-sans span.md-list-item-text{
  font-family: "Open Sans";
}
.font-open-sans-condensed span.md-list-item-text{
  font-family: "Open Sans Condensed";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14px';
  font-size: 14px !important;
  line-height: 150% !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
  font-size: 16px !important;
  line-height: 150% !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
  font-size: 18px !important;
  line-height: 150% !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: '24px';
  font-size: 24px !important;
  line-height: 150% !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: '36px';
  font-size: 36px !important;
  line-height: 150% !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: '48px';
  font-size: 48px !important;
  line-height: 150% !important;
}
</style>
