import {guide} from '../../actions'

const state = {}
const mutations = {}
const actions = {
  async fetch(_config, guide_id){
    return guide.section.get(guide_id).then(function(response){
      return response.data.data
    })
  },
  async fetchById(_config, {guide_id, section_id}){
    return guide.section.get(guide_id, section_id).then(function(response){
      return response.data.data
    })
  },
  async save({dispatch}, {guide_id, section}){
    return guide.section.save(guide_id, section).then(function(response){
      if(section.id){
       dispatch("notify/notify", `Successfully updated ${section.name}.`, { root: true })
        }else{
          dispatch("notify/notify", `Successfully created ${section.name}.`, { root: true })
        }
      return response.data.data
    })
  },
  async uploadQuillImage(_config, image) {
    return await guide.uploadImage('', image).then(function(response) {
      return response.data
    })
  },
  async visited(_config, {guide_id, section}) {
    return guide.section.visited(guide_id, section).then(function(response){
      return response.data.data
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
