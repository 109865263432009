<template>
  <v-combobox v-model="role" :search-input.sync="search" :items="organization_roles" item-text="name" item-value="name" :label="label" :multiple="multiple" :chips="multiple" :value-comparator="rolecomparator" :return-object="false" no-data-text single-line>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
  </v-combobox>
</template>

<script>

export default {
  name: 'SelectRole',
  props: ['value', 'organization_roles', 'multiple', 'label', 'hint', 'addItem'],
  data: function(){
    return {
      role: this.value,
      search: undefined
    }
  },
  methods: {
    add: function(name){
      this.$emit("add-role", name)
    },
    rolecomparator: function(v1, v2){
      return v2.toLowerCase() == v1.toLowerCase()
    },
    isEqual: function(v1, v2) {
      if(this.multiple || this.multiple == ""){
        if(v1.length !== v2.length) return false
        for (var i = 0; i < v1.length; i++) {
          if (v1[i].toLowerCase() !== v2[i].toLowerCase()) return false;
        }
        // Otherwise, return true
        return true;
      }else{
        return (v1 && v1.toLowerCase()) == (v2 && v2.toLowerCase())
      }
    }
  },
  watch: {
    value: function(val, prev) {
     if(!this.isEqual(val, prev)){
       this.role = val
     }
    },
    role: function(val, prev) {
      if(!this.isEqual(val, prev)){
        let transformed = undefined
        if(this.multiple || this.multiple == ""){
          transformed = val.map(x => x.toLowerCase()).filter(x => x && x !== "")
          if(transformed.length != val.length){
            this.role = transformed
            return
          }
        }else{
          transformed = val && val.toLowerCase()
        }
        if(this.multiple || this.multiple == ""){
          let difference = transformed.filter(x => !this.organization_roles.map(r => r.name.toLowerCase()).includes(x))
          difference.forEach(x => this.add(x.toLowerCase()))
        }else if(val && val !== "" && !this.organization_roles.map(r => r.name.toLowerCase()).includes(val.toLowerCase())){
          this.add(transformed)
        }
        this.$emit('input', transformed)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
