<template>
  <v-row align="start" class="fill-height my-0 mx-4">
    <v-col cols="12" md="5" lg="4">
      <v-row class="my-0">
        <v-col>
          <circle-notification-template-detail :notification="notification" :features="features" @edit="edit" v-if="notification"/>
          <circle-notification-template-dialog v-model="show" :template="notification" :fetchMembers="search" @save="handleSave" @cancel="cancel" :saving="saving" :features="features" :organization_roles="roles" @add-role="add_role"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="5" lg="4">
      <v-row class="my-0">
        <v-col>
          <circle-notification-template-recents :notification="notification" @send="handleSend" v-if="notification"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'NotificationTemplateView',
  props: {
    serie: Object,
    notification_id: String,
    series_id: String
  },
  data: () => ({
    notification: undefined,
    previous_notification: undefined,
    saving: false,
    show: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user,
      roles: s => s.organization.role.roles,
      features: s => s.feature.features
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/template/notification/fetchById',
      send: 'journey/serie/template/notification/send',
      fetchSerie: 'journey/serie/fetchById',
      saveNotificationTemplate: 'journey/serie/template/notification/save',
      fetchRoles: 'organization/role/fetch',
      addRole: 'organization/role/save',
      search: 'team/search',
      fetchFeatures: 'feature/fetch'
    }),
    handleSend: function(queued){
      var that = this
      this.send({serie: this.serie, notification: this.notification, data: {assignment_id: queued.assignment_id, recipient_id: queued.recipient.user_id, role: queued.role}}).then(function(){
      that.fetch({series_id: (that.serie && that.serie.series_id) || that.series_id, notification_id: that.notification_id}).then(function(g) {
        that.notification = g
      })
      })
    },
    edit: function(){
      this.show = true
    },
    view: function(s){
      console.warn(`View: assignment/${s.assignment_id}`)
    },
    handleSave: function(s) {
      this.saving = true
      var that = this
      this.saveNotificationTemplate({serie: this.serie, notification: s}).then(function(response){
        that.saving = false
        that.show = false
        that.notification = {...(that.notification || {}), ...response.data.data}
        that.previous_notification = {...that.notification}
      })
    },
    cancel: function(){
      this.notification = {...this.previous_notification}
      this.show = false
    },
    add_role: function(role){
      this.addRole({organization_id: this.user.organization_id, name: role})
    }
  },
  watch: {
    serie: function(val) {
      var that = this
      if(val){
        this.fetch({series_id: val.series_id, notification_id: this.notification_id}).then(function(g) {
          that.notification = g
          that.previous_notification = g
        })
      }
    },
    user: function(val) {
      if(val && val.organization_id){
        this.fetchRoles(val.organization_id)
      }

    }
  },
  created(){
    var that = this
    if(this.serie || this.series_id){
      this.fetch({series_id: (this.serie && this.serie.series_id) || this.series_id, notification_id: this.notification_id}).then(function(g) {
        that.notification = g
        that.previous_notification = g
      })
    }
    if(this.user){
        this.fetchRoles(this.user.organization_id)
      this.fetchFeatures('sender')
    }
  }

}
</script>

<style lang="scss">
</style>
