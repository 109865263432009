<template>
  <v-container class="full-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-progress-circular size="100" width="10" indeterminate="true"></v-progress-circular>
          <div class="body-1">Redirecting to sign up page..</div>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    name: "SignUp",
    data() {
        return {
        }
    },
    methods: {
      ...mapActions({
        register: 'login/triggerRegistration'
        }),
        next(){
            this.register(this.$route.query.state)
        }
    },
    mounted () {
        this.next()
    }
}
</script>
