<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Template</span></v-card-title>
      <v-card-text>
        <TemplateForm v-model="templateFormData" :organization_roles="roles" :fetchMembers="fetchMembers" :features="features" ref="templateForm" @add-role="add_role"></TemplateForm>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TemplateForm from '@/components/series/templates/notifications/TemplateForm'
export default {
  name: "TemplateDialog",
  props: ['value', 'template', 'saving', 'organization_roles', 'fetchMembers', 'features'],
  components: {
    TemplateForm
  },
  data: function() {
    return {
      assignments_values: [
        "member",
        "manager",
        "mentor"
      ],
      show: this.value,
      templateFormData: (this.template && {...this.template}) || {}
    }
  },
  computed: {
    roles: function(){
      return this.assignments_values.map(function(r){ return {"name": r}}).concat(this.organization_roles)
    }
  },
  methods: {
    reset() {
      this.$refs.templateForm.reset()
      this.templateFormData = (this.template && {...this.template}) || {}
    },
    save: function() {
      let valid = this.$refs.templateForm.validate()
      if (valid) {
        this.$emit("save", {...this.templateFormData})
        this.reset()
      }
    },
    close: function() {
      this.reset()
      this.$emit("cancel")
    },
    add_role: function(value){
      this.$emit("add-role", value)
    }
  },
  watch: {
    template: {
      immediate: true,
      handler(val, prev){
      if(val != prev){
        this.templateFormData = val || {}
      }
      }
    },
    value: function(val, prev) {
      if(val != prev){
        this.show = val
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
