import http from '../http'

import * as section from './section.js'
import * as permission from './permission.js'

export function get(id) {
  if(id){
    return http.get(`/v1/guides/${id}`, { withCredentials: true })
  }else{
    return http.get("/v1/guides", { withCredentials: true })
  }
}

export function save(guide) {
  if(guide.id){
    return http.put(`/v1/guides/${guide.id}`, {guide: guide}, { withCredentials: true})
  }else{
    return http.post("/v1/guides", {guide: guide}, { withCredentials: true})
  }
}

export function uploadImage(property, file) {
  var formData = new FormData()
  formData.append('upload', file)
  formData.append('property', property)
  return http.post("/v1/guides/upload/image", formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true})
}

export {section, permission}
