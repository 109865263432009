<template>
  <v-card>
    <v-card-title>{{ notification.title }}<v-btn icon @click="edit()" small><v-icon small>edit</v-icon></v-btn></v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="5" class="subtitle-2">Content</v-col>
          <v-col>{{ notification && notification.body }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="subtitle-2">Link</v-col>
          <v-col>{{ notification && notification.link }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="subtitle-2">Recipients</v-col>
          <v-col>{{ recipients }}</v-col>
        </v-row>
        <v-row v-if="features['sender']">
          <v-col cols="5" class="subtitle-2">Sender</v-col>
          <v-col>{{ sender }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ offset_days }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ send_tm_message }}</v-col>
        </v-row>
        <v-row>
          <v-col 
            v-for="stat in stats"
            :key="stat.title"
            cols="6">
            <v-card class="stats-card" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Stat</div>
                  <v-list-item-title class="headline mb-1">{{stat.value}}</v-list-item-title>
                  <v-list-item-subtitle>{{stat.title}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon><v-icon x-large>{{stat.icon}}</v-icon></v-list-item-icon>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

export default{
  name: "NotificationTemplateDetails",
  props: {
    notification: Object,
    features: Object
  },
  computed: {
    stats: function(){
      return (this.notification && [
        {
          title: "Sent Messages",
          value: this.notification.summary.total,
          icon: "mdi-send"
        },
        {
          title: "Open Rate",
          value: `${Math.round((this.notification.summary.engagement.read / this.notification.summary.total) * 100)}%`,
          icon: "drafts"
        },
        {
          title: "Clickthrough Rate",
          value: `${Math.round((this.notification.summary.engagement.hits / this.notification.summary.engagement.read) * 100)}%`,
          icon: "mdi-cursor-default-click"
        }
      ]) || []
    },
    recipients: function(){
      return this.notification && this.notification.assignments.join(', ')
    },
    sender: function() {
      if(this.notification.sender_type){
        if(this.notification.sender_type == "role"){
          return this.notification.sender
        }else{
          return (this.notification.sender && (this.notification.sender.first_name + ' ' + this.notification.sender.last_name)) || ''
        }
      }
      return ''
    },
    offset_days: function(){
      if(this.notification){
        if(this.notification.offset_days < 0){
          return `${Math.abs(this.notification.offset_days)} day(s) before start date`
        }else{
          return `${Math.abs(this.notification.offset_days)} day(s) after start date`
        }
      }
      return '0'
    },
    send_tm_message: function() {
      return (this.notification && this.notification.send_tm && `Will be sent on the notification date at ${this.notification.send_tm} PST.`) || ''
    }
  },
  data(){
    return {
    }
  },
  methods: {
    edit: function(){
      this.$emit('edit', {...this.notification})
    }
  }
}
</script>

<style scoped>
</style>
