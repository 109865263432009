<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <div>
          <v-progress-circular size="100" width="10" indeterminate="true"></v-progress-circular>
          </div>
          <div class="body-1">Loading ...</div>
          <v-bottom-sheet v-model="redirect" inset>
            <v-sheet class="text-center" height="200px">
              <v-btn color="primary" @click="openInApp()">Open In App</v-btn>
            </v-sheet>
          </v-bottom-sheet>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: "Callback",
  data() {
    return {
      state: Cookies.get('state'),
    }
  },
  methods: {
    ...mapActions({
      verify: 'login/verify'
    }),
    next(){
      this.verify({ 'hash': location.hash, 'state': this.state})
    },
    openInApp(){
      window.location = this.redirect
    }
  },
  computed: {
    ...mapState({
      redirect: s => s.login.redirect 
    }),
  },
  watch: {
    redirect: function(val){
      if(val){
        window.location = val 
      }
    }
  },
  mounted () {
    this.next()
  }
}
</script>
