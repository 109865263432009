<template>
  <circle-base-layout>
    <template v-slot:title>
      My Todos
    </template>
    <template v-slot:default>
      <v-row class="pa-5">
        <v-col md="3"></v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-tabs
              v-model="tab"
              background-color="primary"
              fixed-tabs
              dark
            >
              <v-tab href="#active">
                Todo
              </v-tab>
              <v-tab href="#complete">
                Completed
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="active">
                <v-card flat>
                  <TodoList v-model="active_tasks" :me="user" prefix="active" @mark-complete="markComplete" @mark-incomplete="markIncomplete" @take-action="open" headers/>
                </v-card>
              </v-tab-item>
              <v-tab-item value="complete">
                <v-card flat>
                  <TodoList v-model="completed_tasks" :me="user" prefix="completed" @mark-complete="markComplete" @mark-incomplete="markIncomplete" @take-action="open"/>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col md="3"></v-col>
      </v-row>
    </template>
  </circle-base-layout>
</template>
<script>
import TodoList from '@/components/series/TodoList.vue'
import {mapState,  mapActions, mapGetters } from 'vuex'
export default {
  name: "MyTodos",
  components: {
    TodoList
  },
  data: function(){
    return {
      tab: 'active'
    }
  },
  computed: {
    ...mapState({
      user: s => s.login.user,
      loading: s => s.journey.todos.loading
    }),
    ...mapGetters({
      active_tasks: 'journey/todos/active_tasks',
      completed_tasks: 'journey/todos/completed_tasks'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'journey/todos/fetch',
      mark_complete: 'journey/todos/mark_complete',
      mark_incomplete: 'journey/todos/mark_incomplete'
    }),
    markComplete: function(s) {
      this.mark_complete({checklist_id: s.checklist_id, id: s.task_id, assignment_id: s.assignment_id})
    },
    markIncomplete: function(s) {
      this.mark_incomplete({checklist_id: s.checklist_id, id: s.task_id, assignment_id: s.assignment_id})
    },
    open: function(s) {
      if(s.action){
        window.open(this.decorate(s.action))
      }
    },
    decorate(link){
      if(link.startsWith("http")){
        return link
      }else{
        return `https://${link}`
      }
    }
  },
  created: function(){
    this.fetch()
  }
}
</script>
<style>
</style>

