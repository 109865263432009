import http from '../http'

export function get(guide_id, id){
  if(id){
    return http.get(`/v1/guides/${guide_id}/sections/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/guides/${guide_id}/sections`, { withCredentials: true })
  }
}

export function save(guide_id, section){
  if(section.id){
    return http.put(`/v1/guides/${guide_id}/sections/${section.id}`, {section: section}, { withCredentials: true})
  }else{
    return http.post(`/v1/guides/${guide_id}/sections`, {section: section}, { withCredentials: true})
  }
}

export function visited(guide_id, section){
    return http.post(`/v1/guides/${guide_id}/sections/${section.id}/visited`, { withCredentials: true})
}
