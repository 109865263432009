import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseAppView from '@/views/BaseAppView.vue'
import TeamDashboard from '@/views/team/Dashboard.vue'

Vue.use(VueRouter)


export const routes = [
  {
    path: '/admin/team',
    component: BaseAppView,
    props: {
      tab: []
    },
    children: [
      { 
        path: "", 
        name: 'team-dashboard', 
        component: TeamDashboard,
        meta: { title: 'CircleHR | Team' },
      }
    ]
  }
]
