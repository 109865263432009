<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Template</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="assignmentForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-card outlined v-if="assignment">
                  <v-list-item >
                    <v-list-item-avatar
                      ><v-avatar><img :src="assignment.avatar"></v-avatar></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1">{{ assignment | name }} </v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </v-card>
                <MemberAutoComplete v-model="form.members" :fetch="fetchMembers" label="Members" no_data_text="Search for a <strong>Member</strong>" multiple v-else></MemberAutoComplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-combobox
                  v-model="form.delivery_method"
                  :items="delivery_methods"
                  label="Delivery Method"
                  :rules="[v => !!v || 'Type of delivery is required']"></v-combobox>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-menu
                  v-model="assignment_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.assignment_date"
                      label="Assignment Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.assignment_date" @input="assignment_date_menu = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import MemberAutoComplete from '@/components/inputs/MemberAutoComplete.vue'
export default {
  name: "AssignmentDialog",
  components: {
    MemberAutoComplete
  },
  props: ['value', 'assignment', 'fetchMembers'],
  data: function() {
    return {
      form: {
        members: [],
        assignment_date: '',
        delivery_method: {text: "Email", value: 'email'}
      },
      delivery_methods: [{text: 'SMS', value: 'sms'}, {text: "Email", value: 'email'}, {text: "Slack", value: 'slack'}],
      assignment_date_menu: false,
      saving: false,
      valid: true,
      show: this.value
    }
  },
  methods: {
    clear() {
      this.$refs.assignmentForm && this.$refs.assignmentForm.reset()
      this.form.members = []
      this.form.assignment_date = ''
      this.form.delivery_method = {text: "Email", value: 'email'}
    },
    save: function() {
      this.$refs.assignmentForm.validate()

      if (this.valid) {
        if(this.assignment){
          let m = {
            assignment_date: this.form.assignment_date,
            delivery_method: this.form.delivery_method.value
          }
          this.$emit("save",{...this.assignment, ...m })
        }else{
          this.form.members.forEach( member => {
            let m = {
              member_id: member.user_id,
              first_name: member.first_name,
              last_name: member.last_name,
              avatar: member.avatar,
              assignment_date: this.form.assignment_date,
              delivery_method: this.form.delivery_method.value
            }
            this.$emit("save",{...{}, ...m })
          }
          )
        }
        this.clear()
      }
    },
    close: function() {
      this.clear()
      this.$emit("cancel")
    },
  },
  filters: {
    name: function(assignment){
      return assignment.first_name + ' ' + assignment.last_name
    }
  },
  watch: {
    value: function(val, prev){
      if(val != prev){
        this.show = val
        if(val){
          if(this.assignment){
            this.form = {
              assignment_date: this.assignment.assignment_date,
              delivery_method: this.delivery_methods.find( d => d.value === this.assignment.delivery_method)
            }
          }else{
            this.clear()
          }
        }else{
          this.clear()
        }
      }
    }
  }
}
</script>
<style lang="scss">
.assignment-dialog {
  min-width: 400px;
}
.md-menu-content{
  z-index: 999 !important;
}
.md-checkbox{
  display: flex;
}
</style>
