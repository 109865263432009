<template>
  <v-container class="background fill-height" fluid>
    <v-row align="center" justify="center"> <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12" max-width="400px">
          <v-spacer class="login_spacer"/>
          <v-img src="@/assets/logo.png" height="30" contain/>
          <v-card-text>
            <div class="title" v-if="!passwordless_mode">
              <div class="body-1">Provide your email or phone number, and you'll be sent a login code to access your company site.</div>
            </div>
            <div class="title" v-if="passwordless_mode == 'sms'">
              <v-icon x-large>sms</v-icon>
              <div class="body-1">Check your phone for an SMS message with a 5 digit login code. It may take up to 1-2 minutes to receive.</div>
            </div>
            <div class="title" v-if="passwordless_mode == 'email'">
              <v-icon x-large>email</v-icon>
              <div class="body-1">Check your e-mail for a message with a 5 digit login code. It may take up to 1-2 minutes to receive.</div>
            </div>
            <v-alert type="error" v-if="no_account">
             No Account was found for the email. 
            </v-alert>
            <v-alert dense text type="error" v-if="error">{{error}}</v-alert>
            <v-form v-model="valid" ref="login_form" class="form" v-if="!passwordless_mode" @submit="next">
              <v-text-field v-model="login.login" label="Email or Phone" :rules="emailRules" @keydown.enter="next" required />
            </v-form>
            <v-form v-model="valid" ref="login_form" class="form" @submit="submit" v-else>
              <v-text-field v-model="code" label="Login Code" :rules="codeRules"  @keydown.enter="submit"  required />
              <a @click="reset">Didn't get a code?</a>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="next" v-if="!passwordless_mode">Continue</v-btn>
            <v-btn color="primary" @click="submit" v-else>Continue</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: "Login",
  computed: {
    ...mapState({
      'no_account': s => s.login.no_account,
      'error': s => s.login.error,
      'link_sent': s => s.login.link_sent,
      'passwordless_mode': s => s.login.passwordless_mode
    })  },
  data() {
    return {
      valid: true,
      code: "",
      login: {
        login: ""
      },
      emailRules: [
        v => !!v || 'E-mail or phone is required'
      ],
      codeRules: [
        v => !!v || 'Login code is required'
      ],
    }
  },
  methods: {
    ...mapActions({
      determineLogin: 'login/determineLogin',
      verify: 'login/verify',
      reset_code: 'login/reset_code'
    }),
    host(){
      return window.location.protocol + "//" + window.location.hostname
    },
    next(e){
      e.preventDefault()
      this.$refs.login_form.validate()

      if (this.valid) {
           this.determineLogin({ 'login': this.login.login, 'state': this.$route.query.state || this.host() })
      }
    },
    submit(e){
      e.preventDefault()
      this.$refs.login_form.validate()

      if (this.valid) {
        this.verify(this.code)
      }
    },
    reset(e){
      e.preventDefault()
      this.reset_code()
    }
  },
  created: function(){
    this.$intercom && this.$intercom.boot()
  }
}
</script>

<style lang="scss" scoped>
.login_spacer{
  padding-bottom: 20px;
}
.title {
  text-align: center;
  margin-bottom: 30px;
}
.form {
  margin-bottom: 60px;
}
.background {
    background: #ffffff;
}
</style>
