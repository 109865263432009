<template>
  <v-card>
  <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline">{{ value && value.subject }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      {{ value && value.body }}
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="primary accent-4"
        :href="value.link | decorate"
        v-if="value && value.link"
        target="_blank"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default{
  name: "MessageDetail",
  props: {
    value: Object
  },
  computed: {
  },
  data(){
    return {
    }
  },
  filters: {
    decorate(link){
      if(link.startsWith("http")){
        return link
      }else{
        return `https://${link}`
      }
    }
  }
}
</script>

<style scoped>
</style>
