<template>
  <circle-base-layout :tabs="tabs"> <template v-slot:title>
{{ guide && guide.name }}<v-btn icon @click="showEditDialog = true" v-if="permissions.add" small><v-icon small>edit</v-icon></v-btn>
          <circle-guide-dialog :show='showEditDialog' :guide='guide' @save='save' @close='close' :uploadImage='uploadImage' :saving='saving'/>
    </template>
    <template v-slot:default>
      <router-view :guide="guide" :sections="sections" :selected="route_id" :routePrefix="routePrefix"></router-view>
    </template>
  </circle-base-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'GuideBaseLayout',
  props: {
    guide_id: String,
    tabs: Array,
    routePrefix: String
  },
  data: () => ({
    guide: null,
    showEditDialog: false,
    saving: false,
    sections: [],
    route_id: null
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    ...mapGetters({
      permissions: 'login/permissions'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'guide/fetchById',
      fetchSections: 'guide/section/fetch',
      uploadImage: 'guide/uploadImage',
      saveGuide: 'guide/save',
      visited: 'guide/section/visited'
    }),
    save: function(g) {
      this.saving = true
      var that = this
      this.saveGuide(g).then(function(gd){
        that.saving = false
        that.showEditDialog = false
        that.guide = gd.data.data
      })
    },
    close: function() {
      this.showEditDialog = false
    }
  },
  watch: {
    $route(to){
        if(to.params.pathMatch && to.params.pathMatch.replace("/sections/", "") !== ""){
          this.route_id = to.params.pathMatch.replace("/sections/", "")
          if(!this.permissions.add){
            this.visited({guide_id: this.guide_id, section: this.sections.find(s => s.id === this.route_id)})
          }
        }else{
          this.route_id = undefined
        }
    }
  },
  created () {
    var that = this
    //this.preview = this.$route.path.includes('preview')
    this.fetch(this.guide_id).then(function(g) {
      that.guide = g
      that.fetchSections(that.guide_id).then(function(sections){
        that.sections = sections
        if(that.$route.params.pathMatch && that.$route.params.pathMatch.replace("/sections/", "") !== ""){
          that.route_id = that.$route.params.pathMatch.replace("/sections/", "")
          if(!that.permissions.add){
            that.visited({guide_id: that.guide_id, section: that.sections.find(s => s.id === that.route_id)})
          }
        }else{
          that.route_id = undefined
        }
      })
    })
  }
}
</script>

<style lang="scss">
</style>
