<template>
  <v-row align="start" class="fill-height my-0 px-5">
    <v-col>
      <circle-organization-form v-model="editable" :uploadImage="uploadImage" ref="orgForm"/>
        <v-btn
          color="error"
          class="mr-4"
          @click="cancel">
          Cancel
        </v-btn>
        <v-btn
          color="success"
          @click="save">
          Save
        </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'OrganizationSettings',
  data: () => ({
    editableOrg: undefined
  }),
  computed: {
    ...mapState({
      user: s => s.login.user,
      organization: s => s.login.organization
    }),
    editable: {
      get: function() {
        return {...this.organization}
      },
      set: function(val) {
        this.editableOrg = val
      }
    },
    permissions: function() {
      return {
        'add': this.user.roles.includes('admin')
      }
    }},
  methods: {
    ...mapActions({
      saveOrganization: 'login/saveOrganization',
      uploadImage: 'guide/uploadImage'
    }),
    save() {
      if(this.editableOrg && this.$refs.orgForm.validate()){
        this.saveOrganization(this.editableOrg)
      }
    },
    cancel() {
      this.editableOrg = undefined
      this.$refs.orgForm.reset()
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
</style>
