import Vue from 'vue';
import Cookies from 'js-cookie'
import * as passwordless from '@/store/actions/passwordless'
import {login, organization} from '@/store/actions'
import * as util from '@/utils'
import router from '@/router'
// STATE

const state = {
  user: undefined,
  organization: undefined,
  connection:"",
  no_account: false,
  link_sent: false,
  error: undefined,
  passwordless_mode: undefined,
  passowrdless_username: undefined,
  redirect: undefined,
}

// GETTERS

const getters = {
  connection: state => state.connection,
  no_account: state => state.no_account,
  error: state => state.error,
  link_sent: state => state.link_sent,
  user: state => state.user,
  organization: state => state.organization,
  permissions: state => ({
    'admin': state.user && state.user.roles.includes('admin'),
    'add': state.user && state.user.roles.includes('admin')
  }),
  redirect: state => state.redirect,
  passwordless_mode: state => state.passwordless_mode,
  passwordless_username: state => state.passwordless_username
}

// ACTIONS

const actions = {
  determineLogin ({
    dispatch,
    commit
  }, user) {
    commit("setNoAccount", false) 
    login.getConnection(user.login, user.state || `${window.location.protocol}//${window.location.hostname}`).then(function(response){
      if(response.data.data){
        if(response.data.data.login == 'passwordless'){
          commit('setRedirect', user.state || `${window.location.protocol}//${window.location.hostname}`) 
          commit('setPasswordlessMode', response.data.data.mode)
          commit('setPasswordlessUsername', response.data.data.username)
        }else{
          dispatch('sendToLogin', response.data.data.auth_url)
        }
      }else {
        commit("setNoAccount", true) 
      }
    })
      .catch(error => {
         commit('setError', error.response.data.data.error_description || "An unknown error occurred.")
      })
  },
  verify({
    state,
    dispatch,
    commit
  }, otp) {
    passwordless.verify(otp, state.passwordless_username, state.passwordless_mode).then(function(){
      dispatch("redirect", state.redirect)
    })
      .catch(error => {
         commit('setError', error.response.data.data.error_description || "An unknown error occurred.")
      })
  },
  sendToLogin(_s, auth_url) {
    window.location = auth_url
  },
  reset_code({ commit, state }){
    passwordless.reset_code(state.passwordless_username)
    commit("setError", undefined)
    commit('setRedirect', undefined) 
    commit('setPasswordlessMode', undefined)
    commit('setPasswordlessUsername', undefined)
    router.push({path: '/login'})
  },
  redirect({ commit }, view) {
    if(util.isMobile() && (view.startsWith("circle-welcome://") || view.startsWith("circle://"))){
      let token = Cookies.get('token')
      var url = new URL(view)
      url.searchParams.set('id_token', token)
        commit("setRedirect", url.toString())
    }else{
      window.location = view
    }
  },
  triggerLogout() {
    login.triggerLogout()
  },
  logOut({commit}) {
    commit("setUser", undefined)
    this._vm.$intercom.shutdown()
    login.logout()
  },
  async changePassword({dispatch}, password){
    return login.change_password(password).then(function(){
      dispatch("app/notify", "Successfully changed password", { root: true })
    }).catch(function(){
      dispatch("app/notify", "Failed to change password", { root: true })
    })
  },
  triggerRegistration(_, register) {
    login.signup(register.state)
  },
  fetch({ commit }){
    let token = Cookies.get('token')
    if (token) {
      let that = this
      login.getLoggedInUser().then(function(response){
        if(response.status === 200) {
          commit('setUser', response.data.data)
          that._vm.$intercom && that._vm.$intercom.boot({
            user_id: response.data.data.user_id,
            name: `${response.data.data.first_name} ${response.data.data.last_name}`,
            email: response.data.data.email.work || response.data.data.email.personal,
            Roles: response.data.data.roles,
          })
        }
      })
      organization.get().then(function(response){
        if(response.status === 200){
          commit('setOrganization', response.data.data)
          that._vm.$intercom.update({
            company: {
              id: response.data.data.organization_id,
              name: response.data.data.name
          }})
        }
      })
    }else{
      router.push({path: '/login'})
    }
  },
  saveOrganization({commit}, org) {
    organization.save(org).then(function(response){
        if(response.status === 200){
          commit('setOrganization', response.data.data)
        }
      return response
    })
  },
  update({commit}, params) {
    return login.update(params).then(function(response){
      if(response.status == 200){
        commit('setUser', response.data.data)
      }
      return response
    })
  }
}

// MUTATIONS

/* eslint no-param-reassign:
["error", { "props": false}] */

const mutations = {
  setConnection(s, resp) {
    Vue.set(s, 'connection', resp.login)
  },
  setNoAccount(s, resp) {
    Vue.set(s, 'no_account', resp)
  },
  setLinkSent(s, sent) {
    Vue.set(s, 'link_sent', sent)
  },
  setUser(s, user) {
    Vue.set(s, 'user', user)
  },
  setOrganization(s, organization) {
    Vue.set(s, 'organization', organization)
  },
  setRedirect(s, redirect){
    Vue.set(s, "redirect", redirect)
  },
  setPasswordlessMode(s, mode){
    Vue.set(s, "passwordless_mode", mode)
  },
  setPasswordlessUsername(s, username){
    Vue.set(s, "passwordless_username", username)
  },
  setError(s, error){
    Vue.set(s, "error", error)
  },
  setBilling(s, billing){
    Vue.set(s, 'organization', {...s.organization, ...{ 'billing': billing }})
  }
}

// EXPORT

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
