<template>
  <v-row align="start" class="fill-height my-0 mx-4">
    <v-col cols="12" md="6" lg="5">
      <v-row class="my-0">
        <v-col> 
          <circle-notification-template-list :notifications="sorted_templates" :permissions="permissions" @add="add" @edit="edit" @view="view"/>
          <circle-notification-template-dialog v-model="show" :template="notification" :fetchMembers="search" @save="handleSave" @cancel="cancel" :saving="saving" :features="features" :organization_roles="roles" @add-role="add_role"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'NotificationTemplatesView',
  props: {
    serie: Object
  },
  data: () => ({
    notification: undefined,
    saving: false,
    show: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user,
      roles: s => s.organization.role.roles,
      features: s => s.feature.features
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    },
    sorted_templates: function() {
      return (this.serie && this.serie.notifications && [...this.serie.notifications].sort((a, b) => a.offset_days - b.offset_days)) || []
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/fetchById',
      saveNotificationTemplate: 'journey/serie/template/notification/save',
      fetchRoles: 'organization/role/fetch',
      addRole: 'organization/role/save',
      search: 'team/search',
      fetchFeatures: 'feature/fetch'
    }),
    add: function(){
      this.notification = undefined
      this.show = true
    },
    edit: function(s){
      this.notification = { ...s }
      this.show = true
    },
    view: function(s){
      this.$router.push({name: 'admin-notification-template-view', params: { 'notification_id': s.template_id}})
    },
    handleSave: function(s) {
      this.saving = true
      var that = this
      this.saveNotificationTemplate({serie: this.serie, notification: s}).then(function(response){
        that.saving = false
        that.show = false
        if(s.template_id){
          that.serie.notifications = that.serie.notifications.map(o => [response.data.data].find(o2 => o.template_id === o2.template_id) || o)
        }else{
          that.serie.notifications = (that.serie.notifications || []).concat([response.data.data])
        }
        that.notification = undefined
      })
    },
    cancel: function(){
      this.show = false
    },
    add_role: function(role){
      this.addRole(this.user.organization_id, role)
    }
  },
  watch: {
    user: function(val) {
      if(val && val.organization_id){
        this.fetchRoles(val.organization_id)
      }

    }
  },
  created(){
    if(this.user){
        this.fetchRoles(this.user.organization_id)
      this.fetchFeatures('sender')
    }
  }
}
</script>

<style lang="scss">
</style>
