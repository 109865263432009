import http from '../../http'
import * as task from './task'

export function get(id){
  if(id){
    return http.get(`/v1/journeys/checklists/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/journeys/checklists`, { withCredentials: true })
  }
}

export { task }
