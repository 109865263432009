import Vue from 'vue'
import {journey} from '../../actions'
import moment from 'moment'

const state = {
  checklists: [],
  loading: false,
  task: undefined
}

const mutations = {
  set(s, checklists){
    Vue.set(s, 'checklists', checklists)
  },
  update(s, g){
    Vue.set(s, 'checklists', s.checklists.map(o => [g].find(o2 => o.checklist_id=== o2.checklist_id && o.user.user_id === o2.user.user_id) || o))
  },
  load(s){
    Vue.set(s, 'loading', true)
  },
  loaded(s){
    Vue.set(s, 'loading', false)
  },
  set_task(s, t) {
    Vue.set(s, 'task', t)
  },
  update_task(s, t) {
    Vue.set(s, 'checklists', s.checklists.map(o => {
      if(o.checklist_id === t.checklist_id){
        return {...o, ...{tasks: o.tasks.map(t2 => [t].find(tk => tk.task_id === t2.task_id && tk.assignment_id === t2.assignment_id ) || t2)}}
      }else{
        return o
      }
    }))
  },
}
const actions = {
  fetch({commit}){
    commit('load')
    return journey.checklist.get().then(function(response){
      commit('set', response.data.data)
      commit('loaded')
      return response.data.data
    })
  },
  async fetchTaskById({commit}, id){
    return journey.checklist.task.get(id).then(function(response){
      commit('set_task', response.data.data)
      return response.data.data
    })
  },
  async mark_complete({commit}, {checklist_id, id, assignment_id}){
    return journey.checklist.task.mark_complete(checklist_id, id, assignment_id).then(function(response){
      commit('set_task', response.data.data)
      commit('update_task', response.data.data)
      return response.data.data
    })
  },
  async mark_incomplete({commit}, {checklist_id, id, assignment_id}){
    return journey.checklist.task.mark_incomplete(checklist_id, id, assignment_id).then(function(response){
      commit('set_task', response.data.data)
      commit('update_task', response.data.data)
      return response.data.data
    })
  }
}
const getters = {
  tasks: (state) => {
    let taskList = state.checklists.map( c => {
      return c.tasks.map(task => {
        return { ...task, ...{checklist: c }}
      })
    }).reduce( (acc, v) => acc.concat(v), [])
    return taskList
  },
  completed_tasks: (_state, getters) => getters.tasks && getters.tasks.filter(t => t.completed).sort((a, b) => {
    if(!a.recommended_complete_by_dt){
      return 1
    }else if(!b.recommended_complete_by_dt){
      return -1
    }else {
      return moment(a.recommended_complete_by_dt).unix() - moment(b.recommended_complete_by_dt).unix()
    }
  }),
  active_tasks: (_state, getters) => getters.tasks && getters.tasks.filter(t => !t.completed).sort((a, b) => {
    if(!a.recommended_complete_by_dt){
      return 1
    }else if(!b.recommended_complete_by_dt){
      return -1
    }else {
      return moment(a.recommended_complete_by_dt).unix() - moment(b.recommended_complete_by_dt).unix()
    }
  }).reduce((acc, v) => {
    if(!v.recommended_complete_by_dt) {
      let t = acc.find( t => t.header === 'Not due yet' )
      if(t){
        acc = acc.map(m => {
          if(m.header === 'Not due yet'){
            return { ...m,  ...{ tasks: m.tasks.concat([v]) } }
          }else{
            return m
          }
        })
      }else{
        acc = acc.concat([{header: 'Not due yet', tasks: [v] }])
      }
      return acc
    }else{
      let d = moment(v.recommended_complete_by_dt)
      let today = moment.now()
      if(d.isSame(today, 'day')){
        let t = acc.find( t => t.header === 'Due today' )
        if(t){
          acc = acc.map(m => {
            if(m.header === 'Due today'){
              return { ...m,  ...{ tasks: m.tasks.concat([v]) } }
            }else{
              return m
            }
          })
        }else{
          acc = acc.concat([{header: 'Due today', tasks: [v] }])
        }
        return acc
      }else if(d.isSame(today, 'week') && d.isAfter(today)){
        let t = acc.find( t => t.header === 'Due this week' )
        if(t){
          acc = acc.map(m => {
            if(m.header === 'Due this week'){
              return { ...m, ...{ tasks: m.tasks.concat([v]) } }
            }else{
              return m
            }
          })
        }else{
          acc = acc.concat([{header: 'Due this week', tasks: [v] }])
        }
        return acc
      }else if(d.isSame(today, "month") && d.isAfter(today)){
        let t = acc.find( t => t.header === 'Due this month' )
        if(t){
          acc = acc.map(m => {
            if(m.header === 'Due this month'){
              return { ...m, ...{ tasks: m.tasks.concat([v]) } }
            }else{
              return m
            }
          })
        }else{
          acc = acc.concat([{header: 'Due this month', tasks: [v] }])
        }
        return acc
      }else if(d.isBefore(today)){
        let t = acc.find( t => t.header=== 'Overdue' )
        if(t){
          acc = acc.map(m => {
            if(m.header === 'Overdue'){
              return { ...m, ...{ tasks: m.tasks.concat([v]) } }
            }else{
              return m
            }
          })
        }else{
          acc = acc.concat([{header: 'Overdue', tasks: [v] }])
        }
        return acc
      }else {
        let t = acc.find( t => t.header=== 'Not due yet' )
        if(t){
          acc = acc.map(m => {
            if(m.header=== 'Not due yet'){
              return { ...m, ...{ tasks: m.tasks.concat([v]) } }
            }else{
              return m
            }
          })
        }else{
          acc = acc.concat([{header: 'Not due yet', tasks: [v] }])
        }
        return acc
      }
    }
  }, []).sort((a, b) => {
    ['Overdue', 'Due today', 'Due this week', 'Due this month', 'Not due yet'].indexOf(a.header) - ['Overdue', 'Due today', 'Due this week', 'Due this month', 'Not due yet'].indexOf(b.header)
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

