<template>
  <v-row align="stretch" style="height: inherit;">
    <v-col class="pt-0 pb-0">
      <v-navigation-drawer v-model="displayTOC" ref="drawer" absolute>
        <v-list dense>

          <v-list-item :key="guide.id" :to="`${routePrefix}`" exact><v-list-item-content><v-list-item-title>Cover</v-list-item-title></v-list-item-content></v-list-item>
          <v-list-item v-for="item in sections" :key="item.id" :to="`${routePrefix}/sections/${item.id}`" exact>
            <v-list-item-icon v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content><v-list-item-title>{{ item.name }}</v-list-item-title></v-list-item-content></v-list-item></v-list>
      </v-navigation-drawer>
      <v-content class="pt-0 guide-section-content" v-resize="onResize" v-bind:style="contentStyle">
        <v-container class="pa-0 fill-height" fluid>
          <v-row align="stretch" style="height: inherit;">
            <v-col class="pa-0">
              <v-app-bar height="44">
                <v-icon size="28" @click="displayTOC = !displayTOC">mdi-table-of-contents</v-icon>
              </v-app-bar>
              <span class="traversal-link previous-link d-none d-sm-flex" v-if="previousEnabled">
                <v-btn @click="previous" icon large><v-icon large>mdi-chevron-left</v-icon></v-btn>
              </span>
              <span class="traversal-link next-link d-none d-sm-flex" v-if="nextEnabled">
                <v-btn @click="next" icon large><v-icon large>mdi-chevron-right</v-icon></v-btn>
              </span>
              <v-carousel
                hide-delimiter-background
                hide-delimiters
                :show-arrows="false"
                height="100%"
                v-model="slide"
                @change="doChangeRoute"
                >
                <v-carousel-item :key="guide.id">
                  <circle-title-page :guide="guide"/>
                </v-carousel-item>
                <v-carousel-item v-for="item in sections" :key="item.id">
                  <circle-section-view :guide="guide" :section="item" />
                </v-carousel-item>
              </v-carousel>
              <v-bottom-navigation class="d-flex d-sm-none" absolute grow>
                <v-btn @click="previous" :disabled="!previousEnabled" icon large><v-icon large>mdi-chevron-left</v-icon></v-btn>
                <v-btn @click="next" :disabled="!nextEnabled" icon large><v-icon large>mdi-chevron-right</v-icon></v-btn>
              </v-bottom-navigation>
            </v-col>
          </v-row>
        </v-container>
      </v-content>
    </v-col>
  </v-row>
</template>

<script>
export default{
  name: "GuideView",
  props: {
    guide: Object,
    sections: Array,
    routePrefix: String,
    selected: String
  },
  data() {
    return {
      displayTOC: false,
      contentStyle: {
        '--pl': '0px'
      },
      slide: 0
    }
  },
  methods: {
    doChangeRoute: function(f){
      if(f == 0){
        this.$router.push(this.routePrefix)
      }else{
        this.$router.push(`${this.routePrefix}/sections/${this.sections[f - 1].id}`)
      }
    },
    onResize: function() {
      let width = window.innerWidth
      if(this.displayTOC && width >= 1264){
        this.contentStyle['--pl'] = `${this.$refs.drawer && this.$refs.drawer.width}px`
      }else{
        this.contentStyle['--pl'] = "0px"
      }
    },
    next: function(){
      this.slide = this.slide + 1
      this.doChangeRoute(this.slide)
    },
    previous: function(){
      this.slide = this.slide - 1
      this.doChangeRoute(this.slide)
    }
  },
  computed: {
    nextEnabled: function(){
      return this.slide < ((this.sections && this.sections.length) || 0)
    },
    previousEnabled: function(){
      return this.slide > 0
    }
  },
  watch:{
    selected(val){
      this.slide = (val && (this.sections.findIndex(e => e.id == val) + 1)) || 0
    }
  },
  mounted(){
    this.onResize()
  }
}
</script>

<style scoped>
.toc-btn {
  top: 0;
  right: 0;
  position: absolute;
  margin: 16px 16px 0 0 ;
}
.menu-btn {
  top: 0 !important;
  left: 0 !important;
  position: absolute;
}
.hide-border{border-width: 0px !important;}
.guide-section-content{
  padding-left: var(--pl) !important;
  padding-bottom: 0px !important;
  height: 100% !important;
}
.traversal-link{
  position: absolute;
  z-index: 10
}
.traversal-link button.v-btn{
    position: fixed;
    top: 50%;
  }
.previous-link{
  left: 0;
}
.next-link{
  right: calc(36px + 1rem)
}
</style>
<style>
.section{
  padding-left: 72px !important;
  padding-right: 72px !important;
}
aside{
  z-index: 999 !important;
}
</style>
