import http from '../http'

export function get(guide_id, id){
  if(id){
    return http.get(`/v1/guides/${guide_id}/permissions/${id}`, { withCredentials: true })
  }else{
    return http.get(`/v1/guides/${guide_id}/permissions`, { withCredentials: true })
  }
}

export function save(guide_id, permission){
  if(permission.permission_id){
    return http.put(`/v1/guides/${guide_id}/permissions/${permission.permission_id}`, {permission: permission}, { withCredentials: true})
  }else{
    return http.post(`/v1/guides/${guide_id}/permissions`, {permission: permission}, { withCredentials: true})
  }
}

export function remove(guide_id, permission){
    return http.delete(`/v1/guides/${guide_id}/permissions/${permission.permission_id}`, { withCredentials: true})
}
