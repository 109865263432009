<template>
  <v-container class="ma-0 px-0">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card elevation="1" class="pa-5 mx-auto" max-width="480">
          <div class="overline mb-4">Payment Method</div>
          <div v-if="value">
            <v-list-item two-line>
              <v-list-item-avatar tile>
                <v-img :src="cardIcon" contain></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{value.brand}} ****{{value.last_4_digits}}</v-list-item-title>
                <v-list-item-subtitle>Expires {{value.exp_month}}/{{value.exp_year}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div ref="card" v-else>
            <!-- v-row>
              <v-col cols="12" ref="cardNumber">
              </v-col>
              <v-col cols="12" sm="4" ref="cardExpiry">
              </v-col>
              <v-col cols="12" sm="4" ref="cardCvc">
              </v-col>
              <v-col cols="12" sm="4" ref="cardZip">
              </v-col>
              </v-row -->
          </div>
          <v-card-actions>
            <v-row
              align="center"
              justify="end"
              class="mt-4"
              >
              <v-btn color="success" @click="save" v-if="!value">Save Payment Method</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
import * as card_icons from './payment_cards.js'
let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUB_KEY),
  elements = stripe.elements(),
  card = undefined

export default {
  name: 'PaymentMethodForm',
  components: {
  },
  props: ['value'],
  data: function(){
    return {
      valid: true,
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    }
  },
  computed:{
    cardIcon: function(){
      return this.value && card_icons[this.value.brand]
    }
},
  methods:{
    save: function(){
      stripe.createPaymentMethod({
        type: 'card',
        card: card}).then((result) => {
          if (result.error) {
            console.error(result.error)
          }else{
            this.$emit('save', {payment_method_id: result.paymentMethod.id })
          }
        })
    }
},
  mounted: function() {
    card = elements.create('card', {style: this.style})
    if(!this.value){
      card.mount(this.$refs.card)
    }
  }
}
</script>

<style lang="scss">
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
