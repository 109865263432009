import { journey } from '../../../../actions'

const state = {
}
const mutations = {
}
const actions = {
  async save({ dispatch }, {serie: serie, notification: notification}){
    return journey.series.template.notification.save(serie.series_id, notification).then(function(response){
      if(response.data && response.data.data && response.data.data.template_id){
        if(notification.template_id){
          dispatch("app/notify", `Successfully updated ${notification.title}.`, { root: true })
        }else{
          dispatch("app/notify", `Successfully created ${notification.title}.`, { root: true })
        }
        return response
      }else{
        return response
      }
    })
  },
  async send({ dispatch }, {serie: serie, notification: notification, data}){
    return journey.series.template.notification.send(serie.series_id, notification.template_id, data).then(function(response){
      if(response.data && response.data.data && response.data.data.message_id){
          dispatch("app/notify", `Successfully sent ${notification.title} to ${response.data.data.first_name} ${response.data.data.last_name}.`, { root: true })
          return response
      }else{
        return response
      }
    })
  },
  async fetchById(_s, {series_id, notification_id}){
    return journey.series.template.notification.get(series_id, notification_id).then(function(response){
      return response.data && response.data.data
    })
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
