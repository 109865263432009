import inbox from './inbox'
import todos from './todos'
import serie from './serie/index'
const state = {
}
const mutations = {
}
const actions = {
}
const getters = {
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    inbox,
    serie,
    todos
  }
}
