<template>
  <v-card>
    <v-card-title>Messages</v-card-title>
    <v-card-text>
      <v-list subheader>
        <v-subheader>Recently Sent</v-subheader>
        <v-list-item
          v-for="item in notification.summary.recent.sent"
          :key="item.recipient.user_id"
          >
          <v-list-item-avatar color="primary" size='42' tile>
            <span class="white--text">{{ item.sent_dt | month_day }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.recipient | name }}</v-list-item-title>
        </v-list-item-content>
          <v-list-item-avatar>
            <v-img :src="item.recipient.avatar"></v-img> 
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

    <v-list subheader>
      <v-subheader>Pending</v-subheader>
        <v-list-item
          v-for="item in notification.summary.recent.queued"
          :key="item.recipient.user_id"
          >
          <v-list-item-avatar color="primary" size="42" tile>
            <span class="white--text">{{ item.sent_dt | month_day }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.recipient | name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.sender | name }}</v-list-item-subtitle>
        </v-list-item-content>
          <v-list-item-avatar>
            <v-img :src="item.recipient.avatar"></v-img> 
          </v-list-item-avatar>
          <v-list-item-action>
            <v-btn @click="send(item)" icon><v-icon>mdi-send</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
    </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
export default{
  name: "NotificationTemplateRecents",
  props: {
    notification: Object
  },
  methods: {
    send: function(queued){
      this.$emit('send', queued)
    }
  },
  computed: {
  },
  filters: {
    day: function(v) {
      return moment(v).format('D')
    },
    month_day: function(v) {
      return moment(v).format('M/D')
    },
    name: function(v) {
      return `${v.first_name} ${v.last_name}`
    }
  },
  data(){
    return {
    }
  }
}
</script>

<style scoped>
</style>
