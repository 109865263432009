<template>
  <circle-base-layout>
    <template v-slot:title>
      Settings
    </template>
    <template v-slot:default>
  <v-row class="fill-height my-0" no-gutters>
    <v-col cols="2">
        <v-navigation-drawer permanent>
          <v-list>
            <v-list-item :to="{name: 'organization-settings'}"><v-list-item-content>Organization</v-list-item-content></v-list-item>
            <v-list-item :to="{name: 'notification-settings'}"><v-list-item-content>Notifications</v-list-item-content></v-list-item>
            <v-list-item :to="{name: 'billing-settings'}"><v-list-item-content>Billing</v-list-item-content></v-list-item>
            <v-list-item :to="{name: 'integrations-settings'}"><v-list-item-content>Integrations</v-list-item-content></v-list-item>
          </v-list>
        </v-navigation-drawer>
    </v-col>
    <v-col>
        <router-view></router-view>
    </v-col>
  </v-row>
  </template>
  </circle-base-layout>
</template>

<script>
import { mapState, mapActions} from 'vuex'


export default {
  name: 'SettingsDashboard',
  components: {
  },
  data: () => ({
  }),
  watch: {
  },
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        'add': this.user.roles.includes('admin')
      }
    }},
  methods: {
    ...mapActions({
      setTitle: 'app/setTitle'
    }),
  },
  created () {
    this.setTitle("Settings")
  }
}
</script>

<style lang="scss" scoped>
</style>
