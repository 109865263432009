<template>
  <v-card>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="user.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ this.user | name }}</v-list-item-title>
          <v-list-item-subtitle>{{ this.user | email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-btn color="primary" :to="{name: 'organization-settings'}">Manage Settings</v-btn>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn @click="doShowChangePassword" text color="secondary" small v-if="canResetPassword">Change Password</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="signOut" text color="secondary" small>Sign out</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "Profile",
  components: {
  },
  data: function() {
    return {
    };
  },
  computed: {
    ...mapState({
      user: s => s.login.user,
      organization: s => s.login.organization
    }),
    ...mapGetters({
      permissions: 'login/permissions'
    }),
    canResetPassword: function(){
      return this.user.metadata && ((this.user.metadata.preferences && this.user.metadata.login === 'password') || (this.user.roles && this.user.roles.includes('admin')))
    }
  },
  methods: {
    ...mapActions({
      signOut: 'login/triggerLogout'
    }),
    doShowChangePassword(){ this.$emit("change-password") }
  },
  filters: {
    name: function(item){
      return item.first_name + ' ' + item.last_name
    },
    email: function(item) {
      return item.email && (item.email.work || item.email.personal || '')
    }
  }
};
</script>
<style lang="scss">
.author-card {
  padding: 8px 16px;
  display: flex;
  align-items: center;

  .md-avatar {
    margin-right: 16px;
  }

  .author-card-info {
    display: flex;
    flex-flow: column;
    flex: 1;
  }

  span {
    font-size: 16px;
  }

  .author-card-links {
    display: flex;

    a + a {
      margin-left: 8px;
    }
  }
}
</style>
