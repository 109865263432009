import notification from './notification'
import checklist from './checklist'

const state = {
}
const mutations = {
}
const actions = {
}
const getters = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    notification,
    checklist
  }
}
