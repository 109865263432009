<template>
  <v-row align="start" class="fill-height my-0 px-5">
    <v-col>
      <PaymentMethodForm v-model="paymentMethod" @save="savePaymentMethod"></PaymentMethodForm>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import  PaymentMethodForm from "@/components/organizations/billing/PaymentMethodForm.vue"

export default {
  name: 'BillingSettings',
  components: {
    PaymentMethodForm
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      organization: s => s.login.organization,
    }),
    paymentMethod: function(){
      return this.organization && this.organization.billing && this.organization.billing.payment_method
    },
    permissions: function() {
      return {
        'add': this.user.roles.includes('admin')
      }
    }},
  methods: {
    ...mapActions({
      save: 'organization/billing/save'
    }),
    savePaymentMethod: function(payment_method) {
      this.save({...{organization_id: this.organization.organization_id}, ...payment_method})
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
</style>
