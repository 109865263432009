<template>
  <v-row align="start" class="fill-height my-0 mx-4">
    <v-col cols="12" md="6" lg="5">
      <v-row class="my-0">
        <v-col> 
          <circle-checklist-template-list :checklists="checklists" :permissions="permissions" @add="add" @view="view"/>
          <circle-checklist-dialog :checklist="checklist" v-model="show" @save="handleSave" @cancel="cancel"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'ChecklistTemplatesView',
  props: {
    serie: Object
  },
  data: () => ({
    checklist: undefined,
    saving: false,
    show: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    },
    checklists: function(){
      return (this.serie && this.serie.checklists && [...this.serie.checklists].sort((a, b) => a.start_offset_days - b.start_offset_days )) || []
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/fetchById',
      save: 'journey/serie/template/checklist/save'
    }),
    add: function(){
      this.checklist = undefined
      this.show = true
    },
    view: function(s){
      this.$router.push({name: 'admin-checklist-view', params: { 'checklist_id': s.checklist_id}})
    },
    handleSave: function(s) {
      this.saving = true
      var that = this
      this.save({serie: this.serie, checklist: s}).then(function(response){
        that.saving = false
        that.show = false
        if(s.template_id){
          that.serie.checklists = that.serie.checklists.map(o => [response.data.data].find(o2 => o.checklist_id === o2.checklist_id) || o)
        }else{
          that.serie.checklists = (that.serie.checklists || []).concat([response.data.data])
        }
        that.checklist = undefined
      })
    },
    cancel: function(){
      this.show = false
    }
  }
}
</script>

<style lang="scss">
</style>
