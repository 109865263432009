import Vue from 'vue'
import VueRouter from 'vue-router'
import * as actions from '@/store/actions'
import * as login from './modules/login'
import * as go from './modules/go'
import * as guide from './modules/guide'
import * as team from './modules/team'
import * as journey from './modules/journey'
import * as settings from './modules/settings'
import authenticate from '@/middleware/auth'

Vue.use(VueRouter)

const routes = login.routes.concat(go.routes).concat(settings.routes).concat(guide.routes).concat(team.routes).concat(journey.routes).concat([
  { path: '/',
    beforeEnter: (_to, _from, next) => {
      actions.login.getLoggedInUser().then(function(response){
        if(response.status === 200){
          if(response.data.data.roles.includes("admin")){
            next({name: 'guides-dashboard'})
          }else{
            next({name: 'guides-home'})
          }
        }else{
          next({path: '/login'})
        }
      }).catch(() => next({path: '/login'}))
    }
  }
])

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeResolve(authenticate)
router.beforeEach((to, _from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title
  next()
})
export default router
