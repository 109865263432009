import Vue from 'vue'
import {feature} from '../../actions'
const state = {
  features: {}
}
const mutations = {
  set(s, {name: feature_name, enabled: enabled}){
    let new_feature = {}
    new_feature[feature_name] = enabled
    Vue.set(s, 'features', {...s.features, ...new_feature})
  }
}
const actions = {
  fetch({commit}, feature_name){
    return feature.get(feature_name).then(function(response){
      commit('set', {name: feature_name, enabled: response.data.data.enabled})
      return response.data.data
    })
  }
}
const getters = {
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
