<template>
  <v-card class="mx-auto" tile>
    <v-subheader>Sections<v-spacer></v-spacer><v-btn icon @click="add"><v-icon>add</v-icon></v-btn></v-subheader>
    <v-list two-line>
      <v-list-item v-for="item in sections" :key="item.id">
        <v-list-item-avatar width="32">
          <v-icon v-if="item.icon">{{item.icon}}</v-icon>
          <v-avatar :color="item.bg_color_hex" size="32" v-else>
            <span>&nbsp;</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="view(item)">
            <v-icon>pageview</v-icon>
          </v-btn>
          <v-btn icon @click="edit(item)" v-if="permissions.add">
            <v-icon>edit</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'SectionList',
  props: {
    sections: Array,
    permissions: Object
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    edit: function(section) {
      this.$emit('edit', section)
    },
    view: function(section) {
      this.$emit('view', section)
    }
  },
  filters: {
    swatch: function(g) {
      return `background-color: ${g.bg_color_hex};`
    },
    font: function(g) {
      if(g.header_font)
        return `font-family: ${g.header_font};`
      else return ''
    }
  }
}
</script>

<style lang="scss">
.color-swatch{
  width: 24px;
  height: 24px;
  border: 1px solid rgba(#000, .12);
  margin-right: 32px;
}
.sections-list {
    display: inline-block;
    vertical-align: top;
    min-width: 320px;
    border: 1px solid rgba(#000, .12);
  }
</style>
