<template>
  <v-container class="full-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-progress-circular size="100" width="10" indeterminate="true"></v-progress-circular>
          <div class="body-1">Signing out ...</div>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "Logout",
  data() {
    return {
    }
  },
  methods: {
    ...mapActions({
      logout: 'login/logOut'
    })
  },
  mounted: function() {
    this.logout()
    this.$ma.reset()
  }
}
</script>

<style lang="scss">
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 160px;
    }
  }
  .background {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 25%, rgba(0,212,255,1) 100%);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
}
</style>
