import {guide} from '../../actions'

const state = {}
const mutations = {}
const actions = {
  async fetch(_config, guide_id){
    return guide.permission.get(guide_id).then(function(response){
      return response.data.data
    })
  },
  async fetchById(_config, {guide_id, permission_id}){
    return guide.permission.get(guide_id, permission_id).then(function(response){
      return response.data.data
    })
  },
  async save({dispatch}, {guide_id, permission}){
    return guide.permission.save(guide_id, permission).then(function(response){
      if(permission.permission_id){
       dispatch("notify/notify", `Successfully updated permission.`, { root: true })
        }else{
          dispatch("notify/notify", `Successfully created permission.`, { root: true })
        }
      return response.data.data
    })
  },
  async remove({dispatch}, {guide_id, permission}){
    return guide.permission.remove(guide_id, permission).then(function(response){
       dispatch("notify/notify", `Successfully deleted permission.`, { root: true })
      return response
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
