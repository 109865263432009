import Vue from 'vue'
import VueRouter from 'vue-router'
import Go from '@/views/Go.vue'

Vue.use(VueRouter)


export const routes = [
  { path: "/go/:hash", name: 'go', component: Go, meta: { title: 'CircleHR | Redirect'}, props: true }
]
