<template>
  <v-select :label="label" :items="icons" :value="value" @change="doChange">
    <template slot="item" slot-scope="data">
      <v-icon>{{data.item}}</v-icon>
    </template>
    <template slot="label" slot-scope="data">
      <v-icon>{{data.item}}</v-icon>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CircleFontSelect',
  props: {
    value: String,
    label: String
  },
  methods: {
    doChange: function(e){
      this.$emit('change', e)
    }
  },
  data: () => ({
    icons: [
"alarm",
"accessible_forward",
"account_box",
"assessment",
"book",
"business",
"email",
"event",
"check_circle",
"help",
"laptop",
"phone_iphone",
"watch",
"eco",
"flag",
"wc",
"flight_takeoff", 
"motorcycle",
"pan_tool",
"pets",
"star",
"print",
"reciept",
"schedule",
"wifi",
"stars",
"store",
"tram",
"wb_cloudy",
"supervisor_account",
"thumb_up",
"work",
"vpn_key",
"drive_eta",
"beach_access",
"sports_volleyball"
    ]
  })
}
</script>

<style scoped>
</style>
