<template>
    <v-row class="my-0 mx-5">
      <v-col cols="12" v-if="integrations.length == 0" class="text-center align-center">
            <div class="text-h5 text-uppercase">New Integrations</div>
            <div class="text-h3 text-uppercase">Coming Soon</div>
            <div class="text-h5 text-uppercase">Stay Tuned!</div>
      </v-col>
      <v-col v-for="item in integrations" :key="item.id" lg="2" md="3" cols="12" v-else>
        <v-card>
          <v-img :src="getImageUrl(item.id, 'logo')" class="align-end" :alt="item.id + '-logo'">
            <v-card-title class="justify-center">{{ item.name }}<v-tooltip bottom><template v-slot:activator="{on, attrs}"><v-icon x-small v-bind="attrs" v-on="on">mdi-help-circle</v-icon></template>{{ item.description }}</v-tooltip></v-card-title>
          </v-img>
          <v-card-actions>
            <v-img :src="getImageUrl(item.id, 'btn')" @click="click(item)" v-if="!item.connected"></v-img>
            <div style="width: 100%" v-else>
            <v-alert type="success" text>Connected</v-alert>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    <v-dialog v-model="consent" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Navigating away from this site.
        </v-card-title>
        <v-card-text>
          You are being navigated away from this site to give Circle authentication to the integrated system.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary"
                   text
                   @click="consent = false"
                   >
                   Cancel
            </v-btn>
            <v-btn color="primary"
                   text
                   @click="redirect()"
                   >
                   Continue
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
export default {
  name: 'IntegrationList',
  props: {
    integrations: Array
  },
  data: function(){
    return {
      url: '',
      consent: false
    }
  },
  methods: {
    click: function(item) {
      this.url = item.url
      this.consent = true 
    },
    getImageUrl(key, image) {
      var images = require.context('@/assets/integrations', false, /\.png$/)
     return images('./' + key + "-" + image + ".png")
    },
    redirect: function(){
      window.location = this.url
    }
  }}
</script>

<style lang="scss" scoped>
.guides-list {
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgba(#000, .12);
}
</style>
