<template>
  <circle-loading v-model="display" message="Redirecting" />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GoView',
  props: {
    hash: String
  },
  data: function(){
    return {
      display: true
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/inbox/fetchLinkByHash',
    }),
    decorate(link){
      if(link.startsWith("http")){
        return link
      }else{
        return `https://${link}`
      }
    }
  },
  created () {
    let that = this
    this.fetch(this.hash).then(function(link){
      window.location = that.decorate(link)
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
