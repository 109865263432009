import http from './http'

export function uploadImage(property, file) {
  var formData = new FormData()
  formData.append('upload', file)
  formData.append('property', property)
  return http.post("/v1/upload/image", formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true})
}

export function get() {
  return http.get("/v1/profile/members", { withCredentials: true })
}

export function create(member) {
  return http.post("/v1/profile/members", { member }, { withCredentials: true })
}

export function update(member) {
  return http.put(`/v1/profile/members/${member.user_id}`, { member }, { withCredentials: true })
}

export function search(name) {
  if(name) {
    return http.get("/v1/profile/members", {params: {q: `name:*${name.replace(/[^\S\r\n]/gi, '\\ ')}*`}}, {withCredentials: true})
    
  }else{
    return http.get("/v1/profile/members", {withCredentials: true})
  }
}

export function getById(id) {
    return http.get(`/v1/profile/members/${id}`, {withCredentials: true})
}
