<template>
  <v-card>
    <v-card-title>{{ serie && serie.title }}</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col 
            v-for="stat in stats"
            :key="stat.title"
            cols="6">
            <v-card class="stats-card" tile>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">Stat</div>
                  <v-list-item-title class="headline mb-1">{{stat.value}}</v-list-item-title>
                  <v-list-item-subtitle>{{stat.title}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon><v-icon x-large>{{stat.icon}}</v-icon></v-list-item-icon>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

export default{
  name: "SeriesDetails",
  props: {
    serie: Object
  },
  computed: {
    stats: function(){
      return (this.serie && [
      {title: 'Notifications', value: (this.serie.notifications && this.serie.notifications.length) || 0, icon: 'mdi-email'},
      {title: 'Checklists', value: (this.serie.checklists && this.serie.checklists.length) || 0, icon: 'mdi-check'},
      {title: 'Open Rate', value: (this.serie && this.serie.rate && this.serie.rate.open) || "0%", icon: 'drafts'},
      {title: 'Reply Rate', value: (this.serie && this.serie.rate && this.serie.rate.reply) || "0%", icon: 'mdi-reply'},
        ]) || []
    }
  },
  data(){
    return {
    }
  }
}
</script>

<style scoped>
</style>
