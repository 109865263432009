<template>
  <v-form v-model="valid" ref="templateForm">
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field label="Title" :rules="[v => !!v || 'Title is required']" required v-model="form.title"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field label="Email Template Id" v-model="form.sendgrid_template_id"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field label="Body" :rules="[v => !!v || 'Body is required']" required v-model="form.body"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="Link" prefix="https://" required v-model="form.link"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="Offset Days of Start Date" type="number" :rules="[v => !!v || 'Offset Days is required']" required v-model="form.offset_days"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-menu ref="timeMenu" v-model="menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="form.send_tm" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.send_tm"
            label="Time to send message (PST)"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
            </template>
        <v-time-picker
          v-if="menu"
          v-model="form.send_tm"
          :allowed-minutes="v => v % 30 === 0"
          full-width
          @click:minute="$refs.timeMenu.save(form.send_tm)"
        ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" sm="12" v-if="features['sender']">
          <v-select label="Sender Type" :items="['role', 'user']" v-model="form.sender_type"></v-select>
        </v-col>
        <v-col cols="12" md="6" sm="12" v-if="features['sender']">
          <SelectRole v-model="form.sender" label="Select Sender Role..." hint="Whom should be the sender" :organization_roles="organization_roles"  :addItem="false" v-if="form.sender_type == 'role'"></SelectRole>
          <MemberAutoComplete v-model="form.sender" :fetch="fetchMembers" label="Members" no_data_text="Search for a <strong>Member</strong>" v-if="form.sender_type == 'user'"></MemberAutoComplete>
        </v-col>
        <v-col cols="12" sm="12">
          <SelectRole v-model="form.assignments" label="Select Roles..." hint="Whom should receive this message" :organization_roles="organization_roles" @add-role="add_role" :addItem="true" multiple></SelectRole>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import SelectRole from '@/components/inputs/SelectRole'
import MemberAutoComplete from '@/components/inputs/MemberAutoComplete.vue'
export default {
  name: "TemplateForm",
  components: {
    SelectRole,
    MemberAutoComplete
  },
  props: ['value', 'organization_roles', 'fetchMembers', 'features'],
  data: function() {
    return {
      menu: false,
      form: {
        title: this.value && this.value.title,
        sendgrid_template_id: this.value && this.value.sendgrid_template_id,
        body: this.value && this.value.body,
        link: this.value && this.value.link,
        offset_days: this.value && this.value.offset_days,
        assignments: (this.value && this.value.assignments) || [],
        sender_type: (this.value && this.value.sender_type),
        sender: (this.value && this.value.sender),
        send_tm: (this.value && this.value.send_tm) || '08:00:00',
      },
      rules: {
        url: v => {
          const pattern = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
          return !v || pattern.test(v) || "Invalid url format."
        }
      },
      valid: true
    }
  },
  methods: {
    validate() {
      this.$refs.templateForm.validate()
      return this.valid
    },
    reset() {
      this.$refs.templateForm.resetValidation()
      this.valid = true
    },
    add_role: function(value){
      this.$emit("add-role", value)
    }
  },
  watch: {
    value: {
      handler(val, prev){
        if(val != prev){
            this.form.title = val && val.title
            this.form.sendgrid_template_id = val && val.sendgrid_template_id
            this.form.body = val && val.body
            this.form.link = val && val.link
            this.form.offset_days = val && val.offset_days
            this.form.assignments = (val && val.assignments) || [],
            this.form.sender_type = val && val.sender_type,
            this.form.sender = val && val.sender
            this.form.send_tm = val && val.send_tm
        }
      }
    },
    form: {
      deep: true,
      handler(val){
            let new_value = {...this.value, ...val}
            this.$emit('input', new_value)
        }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
