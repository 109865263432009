<template>
    <v-list>
        <v-list-item v-for="(item, index) in notifications" :key="item.channel">
          <v-list-item-content>{{ item.channel }}</v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="item.enabled" @change="updateEnabled(item, index)"></v-switch>
          </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
  name: "NotificationPreferencesForm",
  components:{
  },
  props: ['value'],
  methods: {
    updateEnabled: function(item, index){
      let prefs = {}
      this.notifications.forEach(function(pref, index1) {
        if(index == index1){
          prefs[pref.channel] = {"enabled": item.enabled, "order": index1}
        }else{
          prefs[pref.channel] = {"enabled": pref.enabled, "order": index1}
        }
      })
      this.$emit('input', {'notifications': prefs})
    }
  },
  computed: {
    notifications: {
      get: function(){
        if(this.value){
          let that = this
          return Object.keys(this.value).map(function(k){
            return {...that.value[k], ...{"channel": k}}
          }).sort(function(a, b){
            return a.order - b.order
          })
        }else{
          return [
            { "channel": "push", "enabled": true},
            { "channel": "sms", "enabled": true},
            { "channel": "email", "enabled": true}
          ]
        }
      },
      set: function(val) {
        let prefs = {}
        val.forEach(function(pref, index) {
          prefs[pref.channel] = {"enabled": pref.enabled, "order": index}
        })
        this.$emit('input', {'notifications': prefs})
      }
    }
  }
}
</script>
