<template>
  <v-row align="start" class="fill-height my-0  mx-4">
    <v-col cols="12" md="5" lg="4">
      <v-row class="my-0">
        <v-col>
          <circle-serie-details :serie="serie" />
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <circle-assignment-list :assignments="assignments" :permissions="permissions" @add="addAssignment" @edit="editAssignment" @view="viewAssignment" @delete="deleteAssignment"/>
      <circle-assignment-dialog :search='search' :assignment="assignment" :fetchMembers="search" v-model="show" @save="handleSaveAssignment" @cancel="cancel"/>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import moment from 'moment'

export default {
  name: 'SerieHomeView',
  props: {
    serie: Object
  },
  data: () => ({
    assignment: undefined,
    saving: false,
    show: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    },
    assignments: function() {
      return (this.serie && this.serie.assignments && [...this.serie.assignments].sort((a, b) => moment(b.assignment_date).diff(moment(a.assignment_date)))) || []
    }
  },
  methods: {
    ...mapActions({
      fetch: 'journey/serie/fetchById',
      search: 'team/search',
      saveAssignment: 'journey/serie/assignment/save',
      removeAssignment: 'journey/serie/assignment/del'
    }),
    addAssignment: function(){
      this.assignment = undefined
      this.show = true
    },
    editAssignment: function(s){
      this.assignment = { ...s }
      this.show = true
    },
    deleteAssignment: function(s){
      var that = this
      this.removeAssignment({serie: this.serie, assignment: s}).then(function(response){
          that.serie.assignments = (that.serie.assignments || []).filter(o => o.assignment_id != response.data.data.assignment_id)
      })
    },
    viewAssignment: function(s){
      console.warn(`View: assignment/${s.assignment_id}`)
    },
    handleSaveAssignment: function(s) {
      this.saving = true
      var that = this
      this.saveAssignment({serie: this.serie, assignment: s}).then(function(response){
        that.saving = false
        that.show = false
        if(s.assignment_id){
          that.serie.assignments = (that.serie.assignments || []).map(o => [response.data.data].find(o2 => o.assignment_id === o2.assignment_id) || o)
        }else{
          that.serie.assignments = (that.serie.assignments || []).concat([response.data.data])
        }
        that.assignment = undefined
      })
    },
    cancel: function(){
      this.show = false
    }
  }
}
</script>

<style lang="scss">
</style>
