import Vue from 'vue'
import {journey} from '../../actions'

const state = {
  messages: [],
  loading: false,
  message: undefined,
  unread_replies: []
}
const mutations = {
  set(s, messages){
    Vue.set(s, 'messages', messages)
  },
  update(s, g){
    Vue.set(s, 'messages', s.messages.map(o => [g].find(o2 => o.message_id === o2.message_id) || o))
  },
  load(s){
    Vue.set(s, 'loading', true)
  },
  loaded(s){
    Vue.set(s, 'loading', false)
  },
  set_message(s, message){
    Vue.set(s, 'message', message)
  },
  new_message(s, reply){
    Vue.set(s, 'unread_replies', s.unread_replies.concat([reply]))
  },
  mark_read(s){
    Vue.set(s, 'unread_replies', [])
  }
}
const actions = {
  fetch({commit}){
    commit('load')
    return journey.inbox.get().then(function(response){
      commit('set', response.data.data)
      commit('loaded')
      return response.data.data
    })
  },
  async fetchLinkByHash(_config, hash){
    return journey.inbox.get_link_by_hash(hash).then(function(response){
      return response.data.data.link
    })
  },
  async fetchById({commit}, id){
    return journey.inbox.get(id).then(function(response){
      commit('set_message', response.data.data)
      return response.data.data
    })
  },
  load_unread({commit, state}){
    commit('set_message', {...state.message, ...{'threads': (state.message.threads || []).concat(state.unread_replies)}})
    commit('mark_read')
  },
  async mark_read({commit}, id){
    return journey.inbox.mark_read(id).then(function(response){
      commit('set_message', response.data.data)
      commit('update', response.data.data)
      return response.data.data
    })
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
