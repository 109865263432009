<template>
  <v-row align="start" class="fill-height my-0 px-5">
    <v-col>
          <NotificationPreferencesForm v-model="notifications"></NotificationPreferencesForm>
        <v-btn
          color="error"
          class="mr-4"
          @click="cancel">
          Cancel
        </v-btn>
        <v-btn
          color="success"
          @click="save">
          Save
        </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import NotificationPreferencesForm from "@/components/profiles/NotificationPreferencesForm.vue"

export default {
  name: 'NotificationSettings',
  components: {
    NotificationPreferencesForm
  },
  data: () => ({
    updatedPreferences: undefined
  }),
  computed: {
    ...mapState({
      user: s => s.login.user,
    }),
    notifications: {
      get: function(){
        return this.user.metadata && this.user.metadata.preferences && this.user.metadata.preferences.notifications
      },
      set: function(val){
        let prev = (this.user && this.user.metadata && this.user.metadata.preferences) || {}
        this.updatedPreferences = {
            'preferences': {...prev, ...val}
          }
      }
    },
    permissions: function() {
      return {
        'add': this.user.roles.includes('admin')
      }
    }},
  methods: {
    ...mapActions({
      update: 'login/update'
    }),
    save() {
      if(this.updatedPreferences){
        this.update(this.updatedPreferences)
      }
    },
    cancel() {
      this.updatedPreferences = undefined
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
</style>
