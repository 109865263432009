import Vue from 'vue'
import {journey} from '../../../actions'

import template from './template/index'
import assignment from './assignment'

const state = {
  series: [],
  loading: false
}
const mutations = {
  set(s, series){
    Vue.set(s, 'series', series)
  },
  update(s, g){
    Vue.set(s, 'series', s.series.map(o => [g].find(o2 => o.series_id === o2.series_id) || o))
  },
  add(s, g){
    Vue.set(s, 'series', s.series.concat([g]))
  },
  load(s){
    Vue.set(s, 'loading', true)
  },
  loaded(s){
    Vue.set(s, 'loading', false)
  }
}
const actions = {
  fetch({commit}){
    commit('load')
    journey.series.get().then(function(response){
      commit('set', response.data.data)
      commit('loaded')
    })
  },
  async fetchById(_config, id){
    return journey.series.get(id).then(function(response){
      return response.data.data
    })
  },
  async save({ dispatch, commit }, g){
    return journey.series.save(g).then(function(response){
      if(response.data && response.data.data && response.data.data.series_id){
        if(g.series_id){
          dispatch("app/notify", `Successfully updated ${g.title}.`, { root: true })
          commit('update', g)
        }else{
          dispatch("app/notify", `Successfully created ${g.title}.`, { root: true })
          commit('add', response.data.data)
        }
        return response
      }else{
        return response
      }
    })
  }
}
const getters = {
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    assignment,
    template
  }
}
