<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12" md="3" sm="12" class="pb-0 pt-0 pl-0">
        <SelectRole v-model="role" :organization_roles="roles" @add-role="add_role" />
      </v-col>
      <v-col cols="12" md="9" sm="12" class="pb-0 pt-0 pr-0">
        <MemberAutoComplete v-model="member" :fetch="fetchMembers" label="Search for a member..." no_data_text="Search for a <strong>Member</strong>"></MemberAutoComplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MemberAutoComplete from '@/components/inputs/MemberAutoComplete'
import SelectRole from '@/components/inputs/SelectRole'
export default {
  name: 'EmployeeLink',
  components: {
    MemberAutoComplete,
    SelectRole
  },
  props: ['value', 'organization_roles', 'fetchMembers', 'addItem'],
  data: function(){
    return {
      role: this.value && this.value.role,
      member: this.value && this.value.member,
    }
  },
  computed: {
    roles: function() {
      return [{"name": "manager"}, {"name": "mentor"}].concat(this.organization_roles)
    }
  },
  methods: {
    add_role: function(value){
      this.$emit("add-role", value)
    }
  },
  watch: {
    value: function(val) {
      this.role = val && val.role
      this.member = val && val.member
    },
    role: function(val, prev){
      let link = {}
      if(val && val != prev){
        link = { ...link, ...{ role: val}}
      if(this.member){
        link = { ...link, ...{ member: this.member}}
      }
        if(link.role && link.member) {
          this.$emit('input', link)
        }
      }
    },
    member: function(val, prev){
      let link = {}
      if(val && val != prev){
        link = { ...link, ...{ member: val}}
      if(this.role){
        link = { ...link, ...{ role: this.role}}
      }
        if(link.role && link.member) {
          this.$emit('input', link)
        }
      }
    }
  }
}
</script>
