import {organization} from '../../actions'
const state = {
}
const mutations = {
}
const actions = {
  save({commit}, {organization_id: organization_id, payment_method_id: payment_method_id}){
    return organization.billing.save(organization_id, {payment_method_id}).then(function(response){
      commit('login/setBilling', response.data.data)
      return response.data.data
    })
  }
}
const getters = {
}

/* eslint no-param-reassign:
    ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
