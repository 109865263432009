import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import Logout from '@/views/login/Logout.vue'
import Callback from '@/views/login/Callback.vue'
import Mobile from '@/views/login/Mobile.vue'
import SignUp from '@/views/login/SignUp.vue'

Vue.use(VueRouter)


export const routes = [
  { path: "/login", name: 'login', component: Login, meta: { title: 'CircleHR | Login'} },
  { path: "/callback", name: 'callback', component: Callback },
  { path: "/mobile", name: 'mobile', component: Mobile },
  { path: "/logout", name: 'logout', component: Logout, meta: { title: 'CircleHR | Logout'}},
  { path: "/sign-up", name: 'signup', component: SignUp, meta: { title: 'CircleHR | Sign Up'} }
]

