import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseAppView from '@/views/BaseAppView.vue'
import GuideDashboard from '@/views/guides/admin/Dashboard.vue'
import GuideBaseLayout from '@/views/guides/Guide.vue'
import AdminGuideView from '@/views/guides/admin/Guide.vue'
import GuideView from '@/views/guides/View.vue'
import GuideHome from '@/views/guides/Home.vue'


Vue.use(VueRouter)

let type = 'Manuals'

export const routes = [
  {
    path: '/guides',
    component: BaseAppView,
    props: {
      tabs: []
    },
    meta: { title: 'CircleHR | Manuals'},
    children: [
      { path: "", 
        name: 'guides-home',
        meta: { title: `CircleHR | ${type} | Home`},
        component: GuideDashboard
      },
      {
        path: ':guide_id',
        component: GuideBaseLayout,
        meta: { title: `CircleHR | ${type} | Detail`},
        props: (route) => ({
          ...route.params,
          ...{
            tabs: [],
            routePrefix:`/guides/${route.params.guide_id}/view`
          }
        }),
        children: [
          {
            path: 'view*',
            name: 'guide-view',
            component: GuideView
          }
        ]
      }
    ]
  },
  {
    path: '/admin/guides',
    component: BaseAppView,
    props: {
      tabs: []
    },
    children: [
      { path: "", 
        name: 'guides-dashboard',
        component: GuideDashboard,
        meta: { title: `CircleHR | ${type} | Admin | Dashboard`},
      },
      {
        path: ':guide_id',
        component: GuideBaseLayout,
        props: (route) => ({
          ...route.params,
          ...{
            tabs: [
              {
                link: {
                  name: 'admin-guide-home',
                  params: {
                    guide_id: route.params.guide_id
                  }
                },
                name: 'Home'
              },
              {
                link: {
                  name: 'admin-guide-view',
                  params: {
                    guide_id: route.params.guide_id
                  }
                },
                name: 'Sections'
              },
              {
                link: `/admin/guides/${route.params.guide_id}/preview`,
                name: 'Preview'
              }
            ],
            routePrefix:`/admin/guides/${route.params.guide_id}/preview`
          }
        }),
        children: [
          {
            path: "",
            name: 'admin-guide-home',
            component: GuideHome,
            meta: { title: `CircleHR | ${type} | Admin | Home`},
          },
          {
            path: "sections",
            name: 'admin-guide-view',
            component: AdminGuideView,
            meta: { title: `CircleHR | ${type} | Admin | Sections`},
          },
          {
            path: 'preview*',
            name: 'admin-guide-preview',
            component: GuideView,
            meta: { title: `CircleHR | ${type} | Admin | Preview`},
          }
        ]
      }
    ]
  }]
