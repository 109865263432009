<template>
  <v-row class="mb-6">
    <v-col cols="4">
      <v-row>
        <v-col>
          <circle-section-list v-bind:sections='guide_sections' :permissions='permissions' @add='add' @edit='edit' @view='view'/>
          <circle-section-dialog v-model='displayDialog' :section='section' @save='handleSave' @close='close' :uploadImage='uploadImage' :saving='saving'/>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <circle-section-view :guide='guide' :section='section' v-if='section'/>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'AdminGuideView',
  props: {
    guide: Object,
    sections: Array
  },
  data: () => ({
    displayDialog: false,
    guide_sections: [],
    section: undefined,
    saving: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      save: 'guide/section/save',
      uploadImage: 'guide/uploadImage'
    }),
    add: function() {
      this.section = undefined
      this.displayDialog = true
    },
    edit: function(s){
      this.section = { ...s }
      this.displayDialog = true
    },
    view: function(s) {
      this.section = { ...s }
    },
    handleSave: function(s) {
      this.saving = true
      let that = this
      if(!s.id){
        s["order"] = this.sections.length
      }
      this.save({guide_id: this.guide.id, section: s}).then(function(response){
        that.saving = false
        that.displayDialog = false
        if(s.id){
          that.guide_sections = that.guide_sections.map(o => [response].find(o2 => o.id === o2.id) || o)
        }else{
          that.guide_sections = that.guide_sections.concat([response])
        }
        that.section = undefined
      })
    },
    close: function() {
      this.displayDialog = false
      this.section = undefined
    }
  },
  watch: {
    sections(val){
      this.guide_sections = [].concat(val)
    }
  },
  created () {
    this.guide_sections = [].concat(this.sections)
  }
}
</script>

<style lang="scss">
.guide-view-content{
  padding-bottom: 25px;
}
</style>
