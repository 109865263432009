<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Assignment</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="assignmentForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-card outlined v-if="permission && permission.user">
                  <v-list-item >
                    <v-list-item-avatar
                      ><v-avatar><img :src="permission.user.avatar"></v-avatar></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1">{{ permission | name }} </v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </v-card>
                <MemberAutoComplete v-model="form.members" :fetch="fetchMembers" label="Members" no_data_text="Search for a <strong>Member</strong>" multiple v-else></MemberAutoComplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-combobox
                  v-model="form.scopes"
                  :items="scopes"
                  label="Role"
                  multiple
                  chips
                  >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="data.item"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="red darken-1" text @click="remove" v-if="permission">Delete</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import MemberAutoComplete from '@/components/inputs/MemberAutoComplete.vue'
export default {
  name: "PermissionDialog",
  components: {
    MemberAutoComplete
  },
  props: ['value', 'permission', 'fetchMembers'],
  data: function() {
    return {
      form: {
        members: [],
        scopes: ['view'] 
      },
      scopes: ['owner', 'admin', 'view'],
      saving: false,
      valid: true,
      show: this.value
    }
  },
  methods: {
    clear() {
      this.$refs.assignmentForm && this.$refs.assignmentForm.reset()
      this.form.members = []
      this.form.scopes= ['view'] 
    },
    save: function() {
      this.$refs.assignmentForm.validate()

      if (this.valid) {
        if(this.permission){
          let m = {
            scopes: this.form.scopes
          }
          this.$emit("save",{...this.permission, ...m })
        }else{
          this.form.members.forEach( member => {
            let m = {
              object_id: member.user_id,
              scopes: this.form.scopes
            }
            this.$emit("save",{...{}, ...m })
          }
          )
        }
        this.clear()
      }
    },
    remove: function() {
      this.clear()
      this.$emit("delete", this.permission)
    },
    close: function() {
      this.clear()
      this.$emit("cancel")
    },
  },
  filters: {
    name: function(assignment){
      return assignment.user.first_name + ' ' + assignment.user.last_name
    }
  },
  watch: {
    value: function(val, prev){
      if(val != prev){
        this.show = val
        if(val){
          if(this.permission){
            this.form = {
              scopes: this.permission.scopes
            }
          }else{
            this.clear()
          }
        }else{
          this.clear()
        }
      }
    }
  }
}
</script>
<style lang="scss">
.assignment-dialog {
  min-width: 400px;
}
.md-menu-content{
  z-index: 999 !important;
}
.md-checkbox{
  display: flex;
}
</style>
