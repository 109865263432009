<template>
  <v-container class="section py-sm-5 px-sm-12 pb-12" v-bind:style="sectionStyle">
    <v-row align="stretch">
    <v-col class="section-content">
      <img :src="section.image" v-if="showImage" v-bind:style="imageStyle"/>
      <p class="section-text" v-html="section.body"></p>
      <div style='clear: both'></div>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default{
  name: "SectionView",
  props:{
    guide: Object,
    section: Object
  },
  computed: {
    sectionStyle () {
      return { 
        'background': this.background(),
        'color': this.fontColor(), 
        'font-family': this.font()
      }
    },
    imageStyle() {
      if(this.section && (this.section.placement === 'left' || this.section.placement === 'right')){
        return { 'float': this.section.placement, 'margin': '10px' }
      }else{
        return {}
      }
    },
    showImage() {
      return this.section && this.section.image && this.section.placement != 'background'
    }
  },
  methods: {
    background() {
      let image = (( this.section && this.section.placement && this.section.image && this.section.placement === 'background' )? `url(${this.section.image}) center/cover` : '')
      let color = (this.section && this.section.bg_color_hex)? this.section.bg_color_hex : ''
      return `${color} ${image}`
    },
    fontColor(){
      return (this.section && this.section.font_color_hex)? this.section.font_color_hex : ''
    },
    font() {
      return (this.section && this.section.font)? this.section.font : (this.guide && this.guide.body_font)? this.guide.body_font : ''
    }
  }
}
</script>
<style>
.section{
  position: relative;
  line-height: 225%;
  min-height: 100%;
}

.section-content{
  height: 100%;
}

.section-text img {
  max-width: 100%;
}

.section-text iframe {
  width: 100%;
  max-width: 800px;
  height: 56.25vw;
  max-height: calc(800px / 16 * 9);
}

.ql-align-center{
  text-align: center;
}
.ql-align-right{
  text-align: right;
}
.ql-align-justify{
  text-align: justify;
}
</style>
