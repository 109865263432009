import { journey } from '../../../../../actions'

const state = {
}
const mutations = {
}
const actions = {
  async save({ dispatch }, {serie: serie, checklist: checklist, task: task}){
    return journey.series.template.checklist.task.save(serie.series_id, checklist.checklist_id, task).then(function(response){
      if(response.data && response.data.data && response.data.data.task_id){
        if(task.task_id){
          dispatch("app/notify", `Successfully updated ${task.content}.`, { root: true })
        }else{
          dispatch("app/notify", `Successfully created ${task.content}.`, { root: true })
        }
        return response
      }else{
        return response
      }
    })
  },
  async fetchById(_s, {series_id, checklist_id, task_id}){
    return journey.series.template.checklist.task.get(series_id, checklist_id, task_id).then(function(response){
      return response.data && response.data.data
    })
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
