<template>
  <circle-base-layout>
    <template v-slot:title>
      My Inbox
    </template>
    <template v-slot:default>
      <v-row class="pa-5">
        <v-col cols="12" :sm="selected? 6 : 12">
          <Inbox :messages="inbox" @view="view"/>
        </v-col>
        <v-col cols="12" sm="6" v-if="selected">
          <MessageDetail
            v-if="selected"
            v-model="selected"
            />
        </v-col>
      </v-row>
    </template>
  </circle-base-layout>
</template>
<script>
import {mapState,  mapActions } from 'vuex'
import Inbox from "@/components/series/Inbox.vue";
import MessageDetail from "@/components/series/Message.vue";
export default {
  name: "MyInbox",
  components: {
    Inbox,
    MessageDetail
  },
  props: {
    message_id: String
  },
  data: function(){
    return {
    }
  },
  computed: {
    ...mapState({
      user: s => s.login.user,
      inbox: s => s.journey.inbox.messages,
      loading: s => s.journey.inbox.loading,
      selected: s => s.journey.inbox.message,
    })
  },
  methods: {
    ...mapActions({
      fetch: 'journey/inbox/fetch',
      fetchById: 'journey/inbox/fetchById',
      mark_read: 'journey/inbox/mark_read'
    }),
    view: function(s){
      this.$router.replace({ name: 'view-inbox-message', params: { message_id: s.message_id }})
    }
  },
  watch: {
    message_id: function(val){
      if(val){
        this.mark_read(val)
      }
    }
  },
  created: function(){
    this.fetch()
    if(this.message_id){
      this.mark_read(this.message_id)
    }
  }
}
</script>
<style>
</style>
