<template>
  <v-dialog v-model="$_show" persistent>
  <v-card :loading="saving">
    <v-card-title>
      Change Password
    </v-card-title>
    <v-card-text>
        <v-form v-model="valid" ref="passwordForm">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="password"
                  type="password"
                  label="Password"
                  :rules="[v => !!v]"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  :rules="[v => !!v || v === password || 'Passwords must match']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangePassword",
  props: {
    show: Boolean,
    saving: Boolean,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      valid: true
    };
  },
  computed: {
        $_show: {
            get: function () {
                return this.show;
            },
            set: function (val) {
              this.$emit('input', val)
            }
        }
  },
  methods: {
    close () {
      this.$refs.passwordForm.reset()
      this.$emit('close')
      this.reset()
    },
    save() {
      this.$refs.passwordForm.validate()

      if (this.valid) {
        this.$emit('save', this.password)
        this.$refs.passwordForm.reset()
        this.reset()
      }
    },
    reset() {
      this.password = ""
      this.confirmPassword = ""
    }
  }
};
</script>
<style lang="scss">
</style>
