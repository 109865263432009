import auth0js from 'auth0-js'
import http from './http'

const auth0 = new auth0js.WebAuth({
  domain: `${process.env.VUE_APP_AUTH0_DOMAIN}`,
  clientID: `${process.env.VUE_APP_AUTH0_CLIENT_ID}`,
  redirectUri: `${process.env.VUE_APP_WEB_REDIRECT_URI}`,
  responseType: 'code',
  scope: 'openid profile email',
})

export function change_password(password){
  return http.post("/v1/profile/members/change-password", { "password": {"password": password } }, { withCredentials: true})
}

export function signup(state) {
  auth0.authorize({'mode': 'signUp', state})
}

export function triggerLogout(){
  window.location = `${process.env.VUE_APP_ROOT_API}/v1/profile/authentication/logout?state=${window.location.protocol}//${window.location.hostname}/logout`
}

export function logout() {
  auth0.logout({
    returnTo: window.location.protocol + '//' + window.location.hostname
  })
}

export function getConnection(login, redirect) {
  return http.post("/v1/profile/authentication/connection", { login, redirect})
}

export function getLoggedInUser() {
  return http.get("/v1/profile/me", { withCredentials: true })
}

export function update(params){
  return http.patch("/v1/profile/me", {'data': params}, { withCredentials: true})
}
