import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseAppView from '@/views/BaseAppView.vue'
import SeriesDashboard from '@/views/journeys/series/admin/Dashboard.vue'
import SeriesView from '@/views/journeys/series/admin/Series.vue'
import SeriesHomeView from '@/views/journeys/series/admin/Home.vue'
import SeriesNotificationTemplateDashboard from '@/views/journeys/series/admin/templates/notifications/Dashboard.vue'
import SeriesNotificationTemplatesView from '@/views/journeys/series/admin/templates/notifications/Templates.vue'
import SeriesNotificationTemplateView from '@/views/journeys/series/admin/templates/notifications/TemplateView.vue'

import SeriesChecklistTemplateDashboard from '@/views/journeys/series/admin/templates/checklists/Dashboard.vue'
import SeriesChecklistTemplatesView from '@/views/journeys/series/admin/templates/checklists/Checklists.vue'
import SeriesChecklistView from '@/views/journeys/series/admin/templates/checklists/ChecklistView.vue'
import SeriesChecklistTaskView from '@/views/journeys/series/admin/templates/checklists/tasks/TaskView.vue'

import MyInbox from '@/views/journeys/MyInbox.vue'
import MyTodos from '@/views/journeys/MyTodos.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/inbox',
    component: BaseAppView,
    props: {
      tabs: []
    },
    children: [
      {
        path: "",
        name: "my-inbox",
        component: MyInbox,
        meta: { title: 'CircleHR | Journies | Inbox'},
      },
      {
        path: ":message_id",
        name: "view-inbox-message",
        component: MyInbox,
        meta: { title: 'CircleHR | Journies | Inbox | Message'},
        props: true
      }
    ]
  },
  {
    path: '/checklists',
    component: BaseAppView,
    props: {
      tabs: []
    },
    children: [
      {
        path: "",
        name: "my-todos",
        component: MyTodos,
        meta: { title: 'CircleHR | Journies | Todos'},
      }
    ]
  },
  {
    path: '/admin/journeys/series',
    component: BaseAppView,
    props: {
      tabs: []
    },
    children: [
      {
        path: '',
        name: 'base-dashboard-view',
        component: SeriesDashboard,
        meta: { title: 'CircleHR | Journies'},
      },
      {
        path: ':series_id',
        name: 'admin-series-view',
        props: (route) => ({
          ...route.params,
          ...{
            tabs: [
              {
                link: {
                  name: 'admin-series-home',
                  params: {
                    series_id: route.params.series_id
                  }
                },
                name: 'Home'
              },
              {
                link: {
                  name: 'default-notification-path',
                  params: {
                    journeys_id: route.params.journeys_id
                  }
                },
                name: 'Notifications'
              },
              {
                link: {
                  name: 'default-checklist-path',
                  params: {
                    journeys_id: route.params.journeys_id
                  }
                },
                name: 'Checklists'
              }
            ]
          }
        }),
        component: SeriesView,
        meta: { title: 'CircleHR | Journies | Home'},
        children: [
          {
            path: '',
            name: 'default-series-view',
            redirect: { name: 'admin-series-home' }
          },
          {
            path: 'home',
            name: "admin-series-home",
            component: SeriesHomeView,
            meta: { title: 'CircleHR | Journies | Series | Home'},
          },
          {
            path: "notifications",
            name: "admin-notification-templates-dashboard",
            component: SeriesNotificationTemplateDashboard,
            meta: { title: 'CircleHR | Journies | Series | Notifications'},
            children: [
              {
                path: '',
                name: 'default-notification-path',
                redirect: { name: 'admin-notification-templates-view' }
              },
              {
                path: 'templates',
                name:"admin-notification-templates-view",
                component: SeriesNotificationTemplatesView,
              },
              {
                path: 'templates/:notification_id',
                name: 'admin-notification-template-view',
                component: SeriesNotificationTemplateView,
                props: true
              }
            ]
          },
          {
            path: "checklists",
            name: "admin-checklist-templates-dashboard",
            component: SeriesChecklistTemplateDashboard,
            meta: { title: 'CircleHR | Journies | Series | Checklists'},
            children: [
              {
                path: '',
                name: 'default-checklist-path',
                redirect: { name: 'admin-checklist-templates-view' }
              },
              {
                path: 'templates',
                name:"admin-checklist-templates-view",
                component: SeriesChecklistTemplatesView,
              },
              {
                path: 'templates/:checklist_id',
                name: 'admin-checklist-view',
                component: SeriesChecklistView,
                props: true
              },
              {
                path: 'templates/:checklist_id/tasks/:task_id',
                name: 'admin-checklist-task-view',
                component: SeriesChecklistTaskView,
                props: true
              }
            ]
          },
        ],
      }
    ]
  }
]
