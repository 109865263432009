import axios from 'axios'
import Cookies from 'js-cookie'
axios.defaults.withCredentials = true
const client =axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 30000,
  headers: {'Authorization': 'Bearer ' + Cookies.get('token')}
})
client.defaults.withCredentials = true

export default client
