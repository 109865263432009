import http from '../http'

import * as role from './role.js'
import * as billing from './billing.js'
import * as integration from './integration.js'

export function get() {
    return http.get("/v1/organizations", { withCredentials: true })
  }

export function save(organization) {
    return http.put(`/v1/organizations/${organization.organization_id}`, {organization: organization}, { withCredentials: true})
}

export {role, billing, integration}
