<template>
  <div>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title>Assignments</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click='add' icon v-if="permissions.add">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list two-line>
      <template v-for="(item, index) in assignments">
        <v-list-item :key="item.assignment_id" @click='view(item)'>
          <v-list-item-avatar v-if="item.avatar"><v-img :src="item.avatar" /></v-list-item-avatar>
          <v-list-item-avatar size="40"
            color="primary" v-else>{{ item | initials }}</v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item | name }}</v-list-item-title>
            <v-list-item-subtitle>
              Started: {{ item.assignment_date | date }}, Deliver By: {{ item.delivery_method }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop='edit(item)' v-if="permissions.add">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn icon @click.stop='deleteAssignment(item)' v-if="permissions.add">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
            v-if="index + 1 < assignments.length"
            :key="index"
          ></v-divider>
      </template>
    </v-list>
  </v-card>
    <v-dialog v-model="consent" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Remove Member from Series 
        </v-card-title>
        <v-card-text>
          Are you sure you want to remove {{ assignment && assignment.first_name }} {{ assignment && assignment.last_name }} from this series?
        </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary"
                   text
                   @click="consent = false"
                   >
                   No
            </v-btn>
            <v-btn color="primary"
                   text
                   @click="performDelete()"
                   >
                   Yes
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'AssignmentList',
  props: {
    assignments: Array,
    permissions: Object,
    fetchMembers: Promise
  },
  data: function() {
    return {
      assignment: undefined,
      consent: false
    }
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    edit: function(assignment) {
      this.$emit('edit', assignment)
    },
    view: function(assignment) {
      this.$emit('view', assignment)
    },
    deleteAssignment: function(assignment) {
      this.assignment = { ...assignment }
      this.consent = true
    },
    performDelete: function(){
      this.$emit('delete', { ...this.assignment })
      this.consent = false
      this.assignment = undefined
    }
  },
  filters: {
    initials: function(a) {
      return ((a.first_name && a.first_name.charAt(0)) || '') + ((a.last_name && a.last_name.charAt(0)) || '')
    },
    name: function(a) {
      return ((a.first_name && (a.first_name + ' ')) || '') + ((a.last_name && a.last_name) || '')
    },
    date: function(d) {
      if(d){
        return moment(d).format('MMM DD YYYY')
      }
      return 'No Start Date'
    }
  }
}
</script>

<style lang="scss">
.assignments-list {
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
  }
</style>
