<template>
  <circle-base-layout>
    <template v-slot:title>
      Manuals
    </template>
    <template v-slot:default>
    <v-row class="my-0">
      <v-col cols="12">
        <v-row class="my-0" justify="center">
          <circle-guide-list v-bind:guides='guides' :permissions='permissions' @view='view' @add='add'/>
          <circle-guide-dialog v-model='displayDialog' :guide='guide' @save='save' @close='close' :uploadImage='uploadImage' :saving='saving' v-if="permissions.add"/>
        </v-row>
      </v-col>
    </v-row>
    </template>
  </circle-base-layout>
</template>

<script>
import { mapState, mapActions} from 'vuex'

export default {
  name: 'GuideDashboard',
  data: () => ({
    displayDialog: false,
    guide: undefined,
    saving: false
  }),
  computed: {
    ...mapState({
      user: s => s.login.user,
      guides: s => s.guide.guides
    }),
    permissions: function() {
      return {
        add: this.user.roles.includes('admin')
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: 'guide/fetch',
      uploadImage: 'guide/uploadImage',
      saveGuide: 'guide/save'
    }),
    add: function() {
      this.guide = undefined
      this.displayDialog = true
    },
    save: function(g) {
      this.saving = true
      var that = this
      this.saveGuide(g).then(function(){
        that.saving = false
        that.displayDialog = false
        that.guide = undefined
      })
    },
    close: function() {
      this.displayDialog = false
      this.guide = undefined
    },
    view: function(g) {
      if(this.user.roles.includes('admin')){
        this.$router.push({name: 'admin-guide-home', params: { guide_id: g.id }})
      }else{
        this.$router.push({path: `/guides/${g.id}/view`})
      }
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
.md-field {
  max-width: 300px;
}
.md-dialog {
  min-width: 480px;
  max-width: 768px;
}
.member-dialog-content {
  padding: 32px;
}
</style>
