import {login} from '@/store/actions'
import store from '@/store'

export default function auth(to, _, next) {
  if(to && !['login', 'logout', 'callback', 'mobile', 'signup', 'go'].includes(to.name)){
    login.getLoggedInUser().then(function (response) {
      if (response.status === 200) {
        store.commit("login/setUser", response.data.data)
        next()
      } else if (response.status === 401) {
        next({ name: 'login', query: { state: to.fullPath } })
      }
    }).catch(function(){
      let url = `${window.location.protocol}//${window.location.hostname}${to.fullPath}`
      next({ name: 'login', query: { state: url } })
    })
  }else{
    next()
  }
}
