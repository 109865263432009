<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title>Templates</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click='add' icon v-if="permissions.add">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list two-line>
      <template v-for="(item, index) in checklists">
        <v-list-item :key="item.checklist_id" @click='view(item)'>
          <v-list-item-avatar
            v-if="item.badge"
            ><v-img :src="item.badge"></v-img></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="item.title"></v-list-item-title>
            <v-list-item-subtitle>
              Start: {{ item.start_offset_days | offset_days }}, Due: {{ item.due_offset_days | due_offset_days }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
            v-if="index + 1 < checklists.length"
            :key="index"
          ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'ChecklistList',
  props: {
    checklists: Array,
    permissions: Object
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    view: function(template) {
      this.$emit('view', template)
    }
  },
  filters: {
    offset_days: function(o){
      if(o){
        if(o < 0){
          return `${Math.abs(o)} day(s) before assigned date`
        }else{
          return `${Math.abs(o)} day(s) after assigned date`
        }
      }
      return '0'
    },
    due_offset_days: function(o){
      if(o){
        if(o < 0){
          return `${Math.abs(o)} day(s) before start date`
        }else{
          return `${Math.abs(o)} day(s) after start date`
        }
      }
      return '0'
    }
  }
}
</script>

<style lang="scss">
.templates-list {
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
  }
</style>
