import Vue from 'vue'
import BaseLayout from '@/components/layouts/BaseLayout'
import SeriesList from '@/components/series/List.vue'
import NotificationTemplateList from '@/components/series/templates/notifications/List.vue'
import NotificationTemplateDetail from '@/components/series/templates/notifications/Details.vue'
import NotificationTemplateRecents from '@/components/series/templates/notifications/Recents.vue'
import AssignmentList from '@/components/series/assignments/List.vue'
import NotificationTemplateDialog from '@/components/series/templates/notifications/dialog/Template.vue'
import ChecklistTemplateList from '@/components/series/templates/checklists/List.vue'
import ChecklistDialog from '@/components/series/templates/checklists/dialog/Checklist.vue'
import ChecklistDetails from '@/components/series/templates/checklists/Details.vue'
import ChecklistTemplateTaskList from '@/components/series/templates/checklists/tasks/List.vue'
import ChecklistTemplateTaskDetails from '@/components/series/templates/checklists/tasks/Details.vue'
import ChecklistTemplateTaskDialog from '@/components/series/templates/checklists/tasks/dialog/Task.vue'
import AssignmentDialog from '@/components/series/assignments/dialog/Assignment.vue'
import SeriesDialog from '@/components/series/dialog/Series.vue'
import SeriesDetails from '@/components/series/Details.vue'
import TeamList from '@/components/teams/List.vue'
import MemberDialog from '@/components/teams/dialog/Member.vue'

import GuideList from '@/components/guides/List.vue'
import GuideDetails from '@/components/guides/Details.vue'
import GuideDialog from '@/components/guides/dialog/Guide.vue'
import SectionList from '@/components/guides/sections/List.vue'
import SectionDialog from '@/components/guides/sections/dialog/Section.vue'
import SectionView from '@/components/guides/sections/View.vue'
import TitlePage from '@/components/guides/TitlePage.vue'
import GuidePermissionList from '@/components/guides/permissions/List.vue'
import GuidePermissionDialog from '@/components/guides/permissions/dialog/Permission.vue'

import OrganizationForm from '@/components/organizations/OrganizationForm.vue'

import IntegrationList from '@/components/organizations/integration/IntegrationList.vue'

import Loading from '@/components/Loading.vue'



Vue.component('circle-base-layout', BaseLayout)

Vue.component('circle-team-list', TeamList)
Vue.component('circle-member-dialog', MemberDialog)

Vue.component('circle-series-list', SeriesList)
Vue.component('circle-serie-details', SeriesDetails)
Vue.component('circle-notification-template-list', NotificationTemplateList)
Vue.component('circle-notification-template-detail', NotificationTemplateDetail)
Vue.component('circle-notification-template-recents', NotificationTemplateRecents)
Vue.component('circle-checklist-template-list', ChecklistTemplateList)
Vue.component('circle-checklist-template-task-list', ChecklistTemplateTaskList)
Vue.component('circle-checklist-dialog', ChecklistDialog)
Vue.component('circle-checklist-task-dialog', ChecklistTemplateTaskDialog)
Vue.component('circle-checklist-task-details', ChecklistTemplateTaskDetails)
Vue.component('circle-checklist-details', ChecklistDetails)
Vue.component('circle-assignment-list', AssignmentList)
Vue.component('circle-series-dialog', SeriesDialog)
Vue.component('circle-notification-template-dialog', NotificationTemplateDialog)
Vue.component('circle-assignment-dialog', AssignmentDialog)

Vue.component('circle-guide-list', GuideList)
Vue.component('circle-guide-details', GuideDetails)
Vue.component('circle-guide-dialog', GuideDialog)
Vue.component('circle-section-list', SectionList)
Vue.component('circle-section-dialog', SectionDialog)
Vue.component('circle-section-view', SectionView)
Vue.component('circle-title-page', TitlePage)
Vue.component('circle-guide-permissions-list', GuidePermissionList)
Vue.component('circle-guide-permissions-dialog', GuidePermissionDialog)

Vue.component('circle-organization-form', OrganizationForm)

Vue.component('circle-integration-list', IntegrationList)

Vue.component('circle-loading', Loading)
