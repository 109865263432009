import http from '../http'


export function fetch(organization_id) {
    return http.get(`/v1/organizations/${organization_id}/roles`, { withCredentials: true })
  }

export function save(organization_id, name) {
  return http.post(`/v1/organizations/${organization_id}/roles`, {data: {"name": name}}, { withCredentials: true})
}
