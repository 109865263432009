<template>
  <v-select :label="label" :items="fonts" item-value="label" item-text="label" :value="value" @change="doChange">
    <template slot="item" slot-scope="data">
      <span :class="data.item.class">{{data.item.label}}</span>
    </template>
    <template slot="label" slot-scope="data">
      <span :class="data.item.class">{{data.item.label}}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CircleFontSelect',
  props: {
    value: String,
    label: String
  },
  methods: {
    doChange: function(e){
      this.$emit('change', e)
    }
  },
  data: () => ({
    fonts: [
      {'class': 'font-alfa-slab-one', 'label': 'Alfa Slab One'},
      {'class': 'font-roboto-slab', 'label': 'Roboto Slab'},
      {'class': 'font-caveat', 'label': 'Caveat'},
      {'class': 'font-dancing-script', 'label': 'Dancing Script'},
      {'class': 'font-erica-one', 'label': 'Erica One'},
      {'class': 'font-lato', 'label': 'Lato'},
      {'class': 'font-monospace', 'label': 'Monospace'},
      {'class': 'font-notable', 'label': 'Notable'},
      {'class': 'font-nunito', 'label': 'Nunito'},
      {'class': 'font-open-sans', 'label': 'Open Sans'},
      {'class': 'font-open-sans-condensed', 'label': 'Open Sans Condensed'},
      {'class': 'font-pinyon-script', 'label': 'Pinyon Script'},
      {'class': 'font-raleway', 'label': 'Raleway'},
      {'class': 'font-sans-serif', 'label': 'Sans Serif'},
      {'class': 'font-serif', 'label': 'Serif'},
      {'class': 'font-shadows-into-light', 'label': 'Shadows Into Light'},
      {'class': 'font-tangerine', 'label': 'Tangerine'}
    ]
  })
}
</script>

<style scoped>
.font-arial {
  font-family: Arial;
}
.font-sans-serif {
  font-family: "Sans Serif";
}
.font-serif {
  font-family: "Serif";
}
.font-monospace {
  font-family: "Monospace";
}
.font-shadows-into-light {
  font-family: "Shadows Into Light";
}
.font-caveat {
  font-family: "Caveat";
}
.font-dancing-script {
  font-family: "Dancing Script";
}
.font-pinyon-script {
  font-family: "Pinyon Script";
}
.font-tangerine { 
  font-family: "Tangerine";
}
.font-notable {
  font-family: "Notable";
}
.font-alfa-slab-one {
  font-family: "Alfa Slab One";
}
.font-roboto-slab-one {
  font-family: "Roboto Slab";
}
.font-erica-one {
  font-family: "Erica One";
}
.font-lato {
  font-family: "Lato";
}
.font-raleway {
  font-family: "Raleway";
}
.font-nunito {
  font-family: "Nunito";
}
.font-open-sans {
  font-family: "Open Sans";
}
.font-open-sans-condensed {
  font-family: "Open Sans Condensed";
}
</style>
