<template>
  <v-dialog v-model="show" persistent>
    <v-card :loading="saving">
      <v-card-title><span class="headline">Checklist</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="checklistForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field label="Title" :rules="[v => !!v || 'Title is required']" required v-model="form.title"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field label="Offset Days to Activate Checklist" type="number" :rules="[v => !!v || 'Offset Days is required']" required v-model="form.start_offset_days"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field label="Offset Days to due date" type="number" :rules="[v => !!v || 'Offset Days is required']" required v-model="form.due_offset_days"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-file-input label="Badge" @change="(e) => upload('badge', e)" accept="image/*"></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="saving">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ChecklistDialog",
  props: {
    value: Boolean,
    checklist: Object,
    saving: Boolean,
    uploadImage: Function
  },
  methods: {
    upload(property, event){
      if(event){
      var that = this
      this.uploadImage({property, 'img': event}).then(function(response){
        if(response && response.image_url) {
          that.form[response.property] = response.image_url
        }})
      }
    },
    clear () {
      this.$refs.checklistForm.reset()
      this.form.title = ''
      this.form.start_offset_days = ''
      this.form.due_offset_days = ''
      this.form.badge = ''
    },
    save: function() {
      this.$refs.checklistForm.validate()
      if (this.valid) {
        let t = this.checklist|| {}
        this.$emit("save", {...t, ...this.form})
        this.clear()
      }
    },
    close: function() {
      this.clear()
      this.$emit("cancel")
    }
  },
  data: function() {
    return {
      form: {
        title: '',
        start_offset_days: '',
        due_offset_days: '',
        badge: ''
      },
      valid: true,
      show: this.value
    }
  },
  watch: {
    value: function(val, prev){
      if(val != prev){
        this.show = val
        if(val){
          this.form = {...this.form, ...this.checklist }
        }else{
          this.clear()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
