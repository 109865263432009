import {journey} from '../../../actions'


const state = {
}
const mutations = {
}
const actions = {
  async save({ dispatch }, {serie: serie, assignment: assignment}){
    return journey.series.assignment.save(serie.series_id, assignment).then(function(response){
      if(response.data && response.data.data && response.data.data.assignment_id){
        if(assignment.assignment_id){
          dispatch("app/notify", `Successfully updated ${assignment.first_name} ${assignment.last_name}.`, { root: true })
        }else{
          dispatch("app/notify", `Successfully assigned ${assignment.first_name} ${assignment.last_name} to ${serie.title}.`, { root: true })
        }
        return response
      }else{
        return response
      }
    })
  },
  async del({ dispatch }, {serie: serie, assignment: assignment}){
    return journey.series.assignment.del(serie.series_id, assignment.assignment_id).then(function(response){
      if(response.data && response.data.data && response.data.data.assignment_id){
          dispatch("app/notify", `Successfully removed ${assignment.first_name} ${assignment.last_name}.`, { root: true })
        return response
      }
    })
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
