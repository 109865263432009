<template>
  <v-card>
    <v-toolbar color="primary">
      <v-toolbar-title>Tasks</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click='add' icon v-if="permissions.add">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list two-line>
      <template v-for="(item, index) in sorted_tasks">
        <v-list-item :key="item.task_id" @click='view(item)'>
          <v-list-item-avatar
            size="40"
            color="green"
            v-if="item.remind_offset_days >= 0"
          >{{ item.remind_offset_days }}</v-list-item-avatar>
          <v-list-item-avatar
            size="40"
            color="red"
            v-else
          >{{ item.remind_offset_days }}</v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="item.content"></v-list-item-title>
            <v-list-item-subtitle>
              Complete By: {{ item.complete_by }}, Require Signoff: {{ item.require_sign_off }}, Send Reminder: {{ item.send_reminder_flag | yes_no }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
            v-if="index + 1 < tasks.length"
            :key="index"
          ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'ChecklistTaskList',
  props: {
    tasks: Array,
    permissions: Object
  },
  computed: {
    sorted_tasks: function(){
      let sorted = (this.tasks && [...this.tasks]) || []
      sorted.sort((a, b) => a.remind_offset_days - b.remind_offset_days)
      return sorted
    }
  },
  methods: {
    add: function() {
      this.$emit('add')
    },
    view: function(template) {
      this.$emit('view', template)
    }
  },
  filters: {
    yes_no: function(val) {
      if(val){
        return 'Yes'
      }else{
        return 'No'
      }
    }
  }
}
</script>

<style lang="scss">
.templates-list {
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
  }
</style>
